import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { MainviewComponent } from './mainview/mainview.component';
import { HomeComponent } from './mainview/home/home.component';
import { VehiclesComponent } from './mainview/vehicles/vehicles.component';
import { PartsComponent } from './mainview/parts/parts.component';
import { ContactComponent } from './mainview/contact/contact.component';
import { SearchComponent } from './mainview/search/search.component';
import { VehicleComponent } from './mainview/vehicle/vehicle.component';


const appRoutes: Routes = [
     { path: '', redirectTo: '/home', pathMatch: 'full' },
     { path: 'home',
     component: LandingpageComponent
     },
     { path: 'main',
     component: MainviewComponent,
     children: [
          {
               path:'home',
               component: HomeComponent
          },
          {
               path:'parts',
               component: HomeComponent

          },
          {
               path:'parts/:vehcat',
               component: HomeComponent
          },
          {
               path:'parts/:vehcat/:vehmade',
               component: HomeComponent
          },
          {
               path:'parts/:vehcat/:vehmade/:vehmodel',
               component: HomeComponent
          },
          {
               path:'parts/:vehcat/:vehmade/:vehmodel/:partcat',
               component: HomeComponent
          },
          {
               path:'parts/:vehcat/:vehmade/:vehmodel/:partcat/:partsubcat',
               component: HomeComponent
          },
          {
               path:'parts/:vehcat/:vehmade/:vehmodel/:partcat/:partsubcat/:partname',
               component: HomeComponent
          },
          {
               path:'parts/:partcat',
               component: HomeComponent
          },
          {
               path:'parts/:partcat/:partsubcat',
               component: HomeComponent
          },
          {
               path:'parts/:partcat/:partsubcat/:partname',
               component: HomeComponent
          },
          {
               path:'parts/:partname',
               component: HomeComponent
          },
          {
               path:'vehicles',
               component: VehiclesComponent
          },
          {
               path:'vehicles/:vehcat',
               component: VehiclesComponent
          },
          {
               path:'vehicles/:vehcat/:vehmade',
               component: VehiclesComponent
          },
          {
               path:'vehicles/:vehcat/:vehmade/:vehmodel',
               component: VehiclesComponent
          },
          {
               path:'vehicles/:vehcat/:vehmade/:vehmodel/:fromyear',
               component: VehiclesComponent
          },
          {
               path:'vehicles/:vehcat/:vehmade/:vehmodel/:fromyear/:toyear',
               component: VehiclesComponent
          },
          {
               path:'parts',
               component: PartsComponent
          },
          {
               path:'contact',
               component: ContactComponent
          },
          {
               path:'search',
               component: SearchComponent
          },
          {
               path:'vehicle/:vehmade/:vehmodel/:vehno',
               component: VehicleComponent
          }
     ]
},
{
     path: 'login',
     component: LoginComponent
},

];

export const AppRouting: ModuleWithProviders = RouterModule.forRoot(appRoutes);
