import { BrowserModule } from '@angular/platform-browser';
import { ApplicationRef, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { AppRouting } from './app.routing';
import { AppConfig } from './app.config';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AuthGuard } from './_guards/auth.guard';

import { SharedModule } from './shared.module';
import { MaterialModule } from './material.module';

import { SuperAdminModule } from './super-admin/super-admin.module';
import { AdminModule } from './admin/admin.module';

import 'hammerjs';

import { AuthenticationService } from './_services/authentication.service';
import { SUAuthenticationService } from './_services/suauthentication.service';
import { CurrentUserService } from './_services/currentuser.service';
import { CompanyService } from './_services/company.service';
import { NomenclatorService } from'./_services/nomenclator.service';
import { DataService } from './_services/data.service';

import { AppComponent } from './app.component';
import { LoginComponent } from './login.component';
import { MainviewComponent } from './mainview/mainview.component';
import { HomeComponent } from './mainview/home/home.component';
import { VehiclesComponent } from './mainview/vehicles/vehicles.component';
import { PartsComponent } from './mainview/parts/parts.component';
import { ContactComponent } from './mainview/contact/contact.component';
import { SearchComponent } from './mainview/search/search.component';
import { AddVehCatDialogueComponent } from './dialogues/add-veh-cat-dialogue/add-veh-cat-dialogue.component';
import { AddVehMadeDialoueComponent } from './dialogues/add-veh-made-dialoue/add-veh-made-dialoue.component';
import { AddVehModelDialoueComponent } from './dialogues/add-veh-model-dialoue/add-veh-model-dialoue.component';
import { AddPartCatDialogueComponent } from './dialogues/add-part-cat-dialogue/add-part-cat-dialogue.component';
import { AddPartSubcatDialogueComponent } from './dialogues/add-part-subcat-dialogue/add-part-subcat-dialogue.component';
import { VehicleComponent } from './mainview/vehicle/vehicle.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { SelectYearDialogueComponent } from './dialogues/select-year-dialogue/select-year-dialogue.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainviewComponent,
    HomeComponent,
    VehiclesComponent,
    PartsComponent,
    ContactComponent,
    SearchComponent,
    AddVehCatDialogueComponent,
    AddVehMadeDialoueComponent,
    AddVehModelDialoueComponent,
    AddPartCatDialogueComponent,
    AddPartSubcatDialogueComponent,
    SelectYearDialogueComponent,
    VehicleComponent,
    LandingpageComponent,
    SelectYearDialogueComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    SharedModule,
    AppRouting,
    AgmCoreModule.forRoot({
      apiKey:'AIzaSyDkXa8T-3KW1066yqvNXVTBnjpLX-SSWoE',
      libraries: ["geometry","places"]
    }),
    SuperAdminModule,
    AdminModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
       AppConfig,
       DataService,
       AuthenticationService,
       SUAuthenticationService,
       CurrentUserService,
       CompanyService,
       NomenclatorService
 ],
 entryComponents: [
      AddVehCatDialogueComponent,
      AddVehMadeDialoueComponent,
      AddVehModelDialoueComponent,
      AddPartCatDialogueComponent,
      AddPartSubcatDialogueComponent,
      SelectYearDialogueComponent
 ],
  bootstrap: [AppComponent]
})
export class AppModule { }
