import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SuperUserService } from 'app/_services/superuser.service';

@Component({
  selector: 'app-admins-list',
  templateUrl: './admins-list.component.html',
  styleUrls: ['./admins-list.component.css']
})
export class AdminsListComponent implements OnInit {
  superusers:any=[];
  crt:number;

  constructor(private router: Router,
              private userService: SuperUserService,) { }

  ngOnInit() {
    this.crt=1;
    this.userService.getAll()
    .subscribe(
      data => {
               this.superusers=data;
               this.superusers.forEach(function(user){
                 user.crt=this.crt;
                 this.crt++
               }.bind(this))
              },
      );
  }

  gohome(){
    this.router.navigate(['/super-admin']);
  }

}
