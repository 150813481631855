import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { AppConfig } from '../app.config';
import { SuperUser } from '../_models/superuser';


@Injectable()
export class CurrentUserService {
  currentusr: SuperUser;
  currentuser: Subject<SuperUser>;
  currentuser$: Observable<SuperUser>;
  constructor(private http: HttpClient, private config: AppConfig) {
    this.currentuser = new Subject();
    this.currentuser$ = this.currentuser.asObservable();
  }

  publishCurrentUser(user){
    this.currentusr = user;
    this.currentuser.next(user);
  }

  getCurrentUser(){
    return this.currentusr;
  };

}
