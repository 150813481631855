import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from 'app/material.module';

import { DateAdapter } from '@angular/material';

import { HeaderService } from 'app/_services/header.service'
import { LeftMenuService } from 'app/_services/leftmenu.service';
import { AppUserService } from 'app/_services/appuser.service';

import { HeaderComponent } from 'app/_directives/header/header.component';
import { LeftmenuComponent } from 'app/_directives/leftmenu/leftmenu.component';



@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
  ],
  declarations: [
    HeaderComponent,
    LeftmenuComponent
  ],
  exports: [
    HeaderComponent,
    LeftmenuComponent,
  ],
  providers: [
    HeaderService,
    LeftMenuService,
    AppUserService
  ],
  entryComponents: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class SharedModule { }
