import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import 'rxjs/add/observable/combineLatest';

import { AppConfig } from '../../app.config';

import { AppElementsService } from '../../_services/appelements.service';
import { VehiclesService } from '../../_services/vehicles.service';
import { PartsService } from '../../_services/parts.service';
import { PictureService } from '../../_services/picture.service';
import { NomenclatorService } from '../../_services/nomenclator.service';

import { PictureCollection, PictureGallery, PictureData} from '../../_models/picturecollection';
import { AppElement } from '../../_models/appelement';
import { Vehicle } from '../../_models/vehicle';
import { Part } from '../../_models/part';
import { NomenclatorItem } from '../../_models/nomenclator';



@Component({
     selector: 'app-vehicles',
     templateUrl: './vehicles.component.html',
     styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit {


     routeSub: any;
     params: any;
     vehicles: Vehicle[];
     parts: Part[];
     vehiclequery: any;
     hasmore: boolean;



     constructor(private router: Router,
          private activatedRoute:ActivatedRoute,
          private elementsService: AppElementsService,
          private vehiclesService: VehiclesService,
          private partsService: PartsService,
          private nomenclatorService: NomenclatorService,
          private config: AppConfig,
          private pictureService: PictureService,
          private sanitization: DomSanitizer) {


          }

          ngOnInit() {
               this.routeSub=this.activatedRoute.params.subscribe((params)=>{
                    this.params = params;
                    this.initializeComponent();
               })
          }

          initializeComponent(){
               this.defineVehicleQuery();
               console.log(this.vehiclequery);
          }

          defineVehicleQuery(){
               this.vehiclequery = {};
               this.vehiclequery.query = {};
               this.vehiclequery.sort={updated_at: -1};
               this.vehiclequery.max = {fabricat: this.params.toyear};
               this.vehiclequery.min = {fabricat: this.params.fromyear};
               this.vehiclequery.pagination={skip: 0, limit: 5};
               this.vehiclequery.query['categorie']={fieldname: 'categorie', value:this.params.vehcat};
               this.vehiclequery.query['marca']={fieldname: 'marca', value:this.params.vehmade};
               this.vehiclequery.query['model']={fieldname: 'model', value:this.params.vehmodel};
               // this.vehiclequery.query['valid']={fieldname: 'valid', value: true};

               this.vehiclesService.getByQuery(this.vehiclequery)
               .subscribe((data)=>{
                    this.vehicles = data.vehicles;
                    this.vehicles.map((vehicle)=>{
                         vehicle.pictures.map((picture)=>{
                              picture.fullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
                              if(picture.mainpicture){
                                   vehicle.mainpicturefullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
                                   vehicle.hasmainpicture = true;
                                   vehicle.fullpathloaded = true;
                              }
                         })

                         if(!vehicle.descriere){
                              var vehicledefinition = vehicle.marca + ' ' + vehicle.model;
                              var vehicletype = '';

                              if(vehicle.tip){
                                   vehicledefinition = vehicledefinition + ' ' + vehicle.tip;
                              }
                              if(vehicle.fabricatie){
                                   vehicledefinition = vehicledefinition + ' fabricat in anul ' + vehicle.fabricatie;
                              }
                              if(vehicle.km){
                                   vehicledefinition = vehicledefinition + ', rulaj ' + vehicle.km + ' km'
                              }

                              if(vehicle.categorie == "Autocare") vehicletype="autocar ";
                              if(vehicle.categorie == "Camioane") vehicletype="camion ";
                              if(vehicle.categorie == "Autoutilitare") vehicletype="autoutilitara ";
                              if(vehicle.categorie == "Microbuze") vehicletype="microbuz ";
                              if(vehicle.categorie == "Autoturisme") vehicletype="autoturism ";


                              vehicle.descriere = "Dezmembram " + vehicletype + vehicledefinition + '. Mentionam ca toate piesele listate pentru acest autovehicul au fost verificate in prealabil de specialistii nostri. In cazul in care piesa cautata nu apare in lista nu ezitati sa ne contactati pentru verificarea disponibilitatii acesteia.';
                         }
                    })
               })
          }

          goToVehicle(vehno){
               this.vehicles.map((vehicle)=>{
                    if(vehicle.vehno == vehno){
                         let route = "main/vehicle/"+vehicle.marca+'/'+vehicle.model+'/'+vehicle.vehno;
                         this.router.navigate([route]);
                    }
               })
          }

     }
