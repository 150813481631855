import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuModifierService } from 'app/_services/menu-modifier.service';

import { LeftMenu , Category , MenuItem, SubMenuItem} from 'app/_models/leftmenu';

@Component({
  selector: 'app-edit-menu',
  templateUrl: './edit-menu.component.html',
  styleUrls: ['./menubuilder.component.css']
})
export class EditMenuComponent implements OnInit {
  model: any={};
  menuname: string;
  workmenu: LeftMenu;

  constructor(private MenuService: MenuModifierService,
              private router: Router) {
                this.MenuService.leftmenu$.subscribe((menuData) => {
                  this.workmenu = menuData;
                });
              }

  ngOnInit() {
    this.workmenu = this.MenuService.getWorkMenuVariable();
  }

  updatemenu(){
    this.MenuService.updateWorkMenu(this.workmenu)
    .subscribe(
        data => {
              this.MenuService.getAllMenuNames()
                  .subscribe(
                    data => {
                             console.log(data);
                             this.MenuService.publishMenuNames(data);
                             this.router.navigate(['/super-admin/menu-modify']);
                            },
                    );

        },
        error => {
        });
  }

  cancelaction(){
    this.router.navigate(['/super-admin/menu-modify']);
  }

}
