import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';

import { AppUserService } from 'app/_services/appuser.service';

import { AppUser } from 'app/_models/appuser';
import { PasswordValidation } from 'app/_validators/password-validation';
import { CheckUniqueUserNameValidation } from 'app/_validators/check-unique-user-name';
import { CheckUniqueEmailValidation } from 'app/_validators/check-unique-email';


@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})

export class CreateUserComponent implements OnInit {

     appuser: AppUser;
     routeSub: any;
     role: string;

     @ViewChild("search")
       public searchElementRef: ElementRef;

     registerForm = new FormGroup({
       username: new FormControl(),
       password: new FormControl(),
       verpassword: new FormControl(),
       firstname: new FormControl(),
       lastname: new FormControl(),
       telefon: new FormControl(),

     });

     constructor(private router: Router,
                 private activeRoute: ActivatedRoute,
                 private userService: AppUserService,
                 private formBuilder: FormBuilder) {
                   this.appuser={
                     username:'',
                     password:'',
                     firstname:'',
                     lastname:'',
                     telefon:'',
                     } as AppUser;

                     this.role="dezbusadmin"

                   }

                   ngOnInit() {
                   this.registerForm = this.formBuilder.group({
                     username: ['',[Validators.required, Validators.minLength(5)]],
                     email: ['',[Validators.required, Validators.minLength(4), Validators.email]],
                     password: ['',[Validators.required, Validators.minLength(6)]],
                     confirmPassword: ['', Validators.required],
                     firstname: ['',[Validators.required, Validators.minLength(3)]],
                     lastname: ['',[Validators.required, Validators.minLength(3)]],
                     telefon: ['',[Validators.required, Validators.minLength(10),Validators.maxLength(10)]],
                   },{
                     validator: [PasswordValidation.MatchPassword,
                                 CheckUniqueUserNameValidation.MatchName(this.userService),
                                 CheckUniqueEmailValidation.MatchEmail(this.userService)
                                ]

                   });
                   }

                   onSubmit({ value, valid }: { value: any, valid: boolean }) {

                   this.appuser.username = value.username;
                   this.appuser.password = value.password;
                   this.appuser.firstname = value.firstname;
                   this.appuser.lastname = value.lastname;
                   this.appuser.telefon = value.telefon;
                   this.appuser.email = value.email;
                   this.appuser.role=this.role;

                   this.userService.settempuser(this.appuser);
                   this.userService.create(this.appuser).
                   subscribe(
                        data=>{
                             console.log(data);
                             this.gohome();
                        });

                 }

                 gohome(){
                   this.router.navigate(['/super-admin/dezbus-users/users-list']);
                 }

}
