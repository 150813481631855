import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuModifierService } from 'app/_services/menu-modifier.service';
import { LeftMenu , Category , MenuItem, SubMenuItem} from 'app/_models/leftmenu';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./menubuilder.component.css']
})


export class AddCategoryComponent implements OnInit {
  model: any={};
  workmenu: LeftMenu;
  category: number;
  menuitem: number;
  submenuitem: number;

  constructor( private MenuService: MenuModifierService,
               private router: Router ) {
                this.model=<Category>{};
                this.MenuService.leftmenu$.subscribe((menuData) => {
                  this.workmenu = menuData;
                });
                this.MenuService.category$.subscribe((categoryData) => {
                  this.category = categoryData;
                });
                this.MenuService.menuitem$.subscribe((menuitemData) => {
                  this.menuitem = menuitemData;
                });
                this.MenuService.submenuitem$.subscribe((submenuitemData) => {
                  this.submenuitem = submenuitemData;
                });
               }

  ngOnInit() {
    this.workmenu = this.MenuService.getWorkMenuVariable();
    this.category = this.MenuService.getInitCategory();
  }

  createnewcategory(){
    this.model.active = false;
    this.model.selected = false;
    this.model.menuitems = [];
    if(this.category>=0){
      this.workmenu.categories.splice(this.category+1, 0, this.model);
    }else{
      this.workmenu.categories.unshift(this.model)
    }
    this.MenuService.updateWorkMenu(this.workmenu)
    .subscribe(
        data => {
              this.MenuService.getWorkMenu(this.workmenu.menu)
                  .subscribe(
                    data => {
                             this.MenuService.publishMenu(data);
                             this.model=<Category>{};
                             this.router.navigate(['/super-admin/menu-modify']);
                            },
                    );

        },
        error => {
        });
  }

  cancelaction(){
    this.router.navigate(['/super-admin/menu-modify']);
  }

}
