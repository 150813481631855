import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { AppConfig } from '../app.config';
import { LeftMenu , Category , MenuItem, SubMenuItem, MenuIndexes} from '../_models/leftmenu';

@Injectable()
export class LeftMenuService {
  workmenu: LeftMenu;
  leftmenu: Subject<LeftMenu>;
  leftmenu$: Observable<LeftMenu>;
  selectedstate: boolean;
  selected: Subject<boolean>;
  selected$: Observable<boolean>;
  selectedindexes: Subject<MenuIndexes>;
  selectedindexes$: Observable<MenuIndexes>;

  indexes: any={};


  constructor(private http: HttpClient, private config: AppConfig) {

    this.leftmenu = new Subject();
    this.leftmenu$ = this.leftmenu.asObservable();
    this.selected = new Subject();
    this.selected$ = this.selected.asObservable();
    this.selectedindexes = new Subject();
    this.selectedindexes$ = this.selectedindexes.asObservable();

  }


  getLeftMenu(menuname: string){
    return this.http.get<LeftMenu>(this.config.apiUrl + '/appcomponents/getleftmenu/' + menuname, this.jwt());
  }

  getWorkMenu(){
    return this.workmenu;
  }

  publishLeftMenu(menu){
    this.workmenu = menu;
    this.leftmenu.next(menu);
  }

  publishState(state){
    this.selectedstate = state;
    this.selected.next(state);
  }

  publishSelected(category, menuitem, submenuitem){
    this.indexes={}
    this.indexes.category=category;
    this.indexes.menuitem=menuitem;
    this.indexes.submenuitem=submenuitem;
    this.selectedindexes.next(this.indexes);
  }

  assignIndexes(categoryname, menuitemstate, submenuitemquery){
    var catindex:number;
    var mitemindex:number;
    var smitemindex:number;

    this.workmenu.categories.map(function(category, cindex){
      if(category.name==categoryname){
        catindex=cindex;
        category.menuitems.map(function(menuitem, mindex){
          if(menuitem.state==menuitemstate){
            mitemindex=mindex;
            if(submenuitemquery!=="notAssigned"){
              menuitem.submenuitems.map(function(submenuitem, smindex){
                if(submenuitem.query==submenuitemquery){
                  smitemindex=smindex;
                  this.publishSelected(catindex, mitemindex, smitemindex)
                  console.log(catindex, mitemindex, smitemindex)
                };
              }.bind(this));
            }else{
              this.publishSelected(catindex, mitemindex, smitemindex)

            }
          };
        }.bind(this));
      };
    }.bind(this));
  }



  private jwt(){
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .append('Access-Control-Allow-Origin', '*')
    .append('Authorization', 'Bearer '+ currentUser.token)
    .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS');

            return { headers: headers };
    }
  }
}
