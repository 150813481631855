import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { AppConfig } from '../app.config';
import { AppElement } from '../_models/appelement';


@Injectable()
export class AppElementsService {


     constructor(private http: HttpClient, private config: AppConfig) {

     }

     getAll(){
          return this.http.get<AppElement[]>(this.config.apiUrl + '/appelements', this.jwt());
     }

     getById(_id: string){
          return this.http.get<AppElement>(this.config.apiUrl + '/appelements/' + _id, this.jwt());
     }

     create(data: AppElement){
          return this.http.post(this.config.apiUrl + '/appelements', data, this.jwt1());
     }

     update(data: AppElement){
          return this.http.put<any>(this.config.apiUrl + '/appelements/' + data._id, data, this.jwt());
     }

     delete(_id: string){
          return this.http.delete(this.config.apiUrl + '/appelements/' + _id, this.jwt());
     }


     private jwt(){
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          if (currentUser && currentUser.token){
               let headers = new HttpHeaders().set('Content-Type', 'application/json')
               .append('Access-Control-Allow-Origin', '*')
               .append('Authorization', 'Bearer '+ currentUser.token)
               .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS');

               return { headers: headers };
          }
     }

     private jwt1(){
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          if (currentUser && currentUser.token){
               let headers = new HttpHeaders().set('Content-Type', 'application/json')
               .append('Access-Control-Allow-Origin', '*')
               .append('Authorization', 'Bearer '+ currentUser.token)
               .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS'), responseType:'text';

               let options={headers: headers}

               return options;
          }
     }
}
