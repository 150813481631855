import { AbstractControl } from '@angular/forms';
import { AppUserService } from 'app/_services/appuser.service';

export class CheckUniqueUserNameValidation {

 static MatchName(userService: AppUserService) {

        return (AC:AbstractControl) =>{
        let username = AC.get('username').value;
       userService.checkuniquename(username)
       .subscribe(
           data => {
               if(data.exists) {
                   AC.get('username').setErrors( {MatchName: true} )
               } else {
                   return null
               }
           }
       )
   }
}

}
