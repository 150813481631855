import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { WorkDataService } from 'app/_services/workdata.service';
//
import { Workdata, Proforma, Factura, Chitanta } from 'app/_models/workdata';

@Component({
  selector: 'app-pricing-plan',
  templateUrl: './pricing-plan.component.html',
  styleUrls: ['../formstyles.css']
})
export class PricingPlanComponent implements OnInit {


      workdata: Workdata;
      loadedworkdata: any={};
      created:boolean;

      workdataForm = new FormGroup({
        abo1: new FormControl(),
        abo6: new FormControl(),
        abo12: new FormControl(),


      });

      constructor(private formBuilder: FormBuilder,
                  private ngZone: NgZone,
                  private router: Router,
                  private workDataService: WorkDataService,
                ) {

                    this.workdata={

                      abo1:null,
                      abo6:null,
                      abo12:null,
                      tva:null,
                      proforme:[{
                        serie: '',
                        beginno: null,
                        endno: null
                      }as Proforma],
                      facturi:[{
                        serie: '',
                        beginno: null,
                        endno: null
                      }as Factura],
                      chitante: [{
                        serie: '',
                        beginno: null,
                        endno: null
                      }as Chitanta]
                    } as Workdata;
                   }

      ngOnInit() {
        this.workDataService.getAll()
        .subscribe(
          data => {
            if(data.length==0){
              console.log(data);
              this.created=false;
              this.loadedworkdata=this.workdata;
              console.log(this.loadedworkdata);
            }else{
              this.loadedworkdata = data[0];
              this.created=true
            }

              this.workdataForm = this.formBuilder.group({
                    abo1: [this.loadedworkdata.abo1,Validators.required],
                    abo6: [this.loadedworkdata.abo6, Validators.required],
                    abo12: [this.loadedworkdata.abo12,Validators.required],
                  });
          });
      }

      onSubmit({ value, valid }: { value: any, valid: boolean }) {

        this.workdata = this.loadedworkdata;
        this.workdata.abo1 = value.abo1;
        this.workdata.abo6 = value.abo6;
        this.workdata.abo12 = value.abo12;


        if(this.created){
          this.workDataService.update(this.workdata)
              .subscribe(
                data => {
                  this.router.navigate(['/super-admin']);
                        },
                error => {
                         });
        } else {
          this.workDataService.create(this.workdata)
              .subscribe(
                data => {
                  this.router.navigate(['/super-admin']);
                        },
                error => {
                         });
        }


      }

      gohome(){
        this.router.navigate(['/super-admin']);
      }

}
