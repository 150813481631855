import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { WorkDataService } from 'app/_services/workdata.service';
//
import { Workdata, Proforma, Factura, Chitanta } from 'app/_models/workdata';

@Component({
  selector: 'app-proforme',
  templateUrl: './proforme.component.html',
  styleUrls: ['../formstyles.css']
})

export class ProformeComponent implements OnInit {


      workdata: Workdata;
      loadedworkdata: any={};
      created:boolean;
      proforme: Proforma;

      workdataForm = new FormGroup({
        serie: new FormControl(),
        beginno: new FormControl(),
        endno: new FormControl(),


      });

      constructor(private formBuilder: FormBuilder,
                  private ngZone: NgZone,
                  private router: Router,
                  private workDataService: WorkDataService,
                ) {

                    this.workdata={

                      abo1:null,
                      abo6:null,
                      abo12:null,
                      tva:null,
                      proforme:[{
                        serie: '',
                        beginno: null,
                        endno: null
                      }as Proforma],
                      facturi:[{
                        serie: '',
                        beginno: null,
                        endno: null
                      }as Factura],
                      chitante: [{
                        serie: '',
                        beginno: null,
                        endno: null
                      }as Chitanta]
                    } as Workdata;
                   }

      ngOnInit() {
        this.workDataService.getAll()
        .subscribe(
          data => {
            if(data.length==0){
              this.created=false;
              this.loadedworkdata=this.workdata;
              console.log(this.loadedworkdata);
            }else{
              console.log(data);
              this.loadedworkdata = data[0];
              this.created=true
            }

              this.workdataForm = this.formBuilder.group({
                    serie: [this.loadedworkdata.serie,Validators.required],
                    beginno: [this.loadedworkdata.beginno, Validators.required],
                    endno: [this.loadedworkdata.endno,Validators.required],
                  });
          });
      }

      onSubmit({ value, valid }: { value: any, valid: boolean }) {

        this.workdata = this.loadedworkdata;
        this.proforme = {
          serie: value.serie.toUpperCase(),
          beginno: value.beginno,
          endno:value.endno
        }
        if(this.workdata.proforme[0].beginno==null){
          this.workdata.proforme[0]=this.proforme;
        }else{
          this.workdata.proforme.push(this.proforme);
        }



        if(this.created){
          this.workDataService.update(this.workdata)
              .subscribe(
                data => {
                  this.router.navigate(['/super-admin']);
                        },
                error => {
                         });
        } else {
          this.workDataService.create(this.workdata)
              .subscribe(
                data => {
                  this.router.navigate(['/super-admin']);
                        },
                error => {
                         });
        }


      }

      gohome(){
        this.router.navigate(['/super-admin']);
      }

}
