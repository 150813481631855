import { AbstractControl } from '@angular/forms';
import { AppUserService } from '../_services/appuser.service';

export class CheckUniqueEmailValidation {

 static MatchEmail(userService: AppUserService) {

        return (AC:AbstractControl) =>{
        let email = AC.get('email').value;
       userService.checkuniquemail(email)
       .subscribe(
           data => {
               if(data.exists) {
                   AC.get('email').setErrors( {MatchEmail: true} )
               } else {
                   return null
               }
           }
       )
   }
}

}
