import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SUAuthenticationService } from '../../_services/suauthentication.service';
import { CurrentUserService } from '../../_services/currentuser.service';

import { User } from '../../_models/user';

import { HeaderService } from '../../_services/header.service';

@Component({
     selector: 'app-header',
     templateUrl: './header.component.html',
     styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
     currentUser: User;
     headerview: string;
     logoutvar :boolean;
     navbarlinks: any={};

     constructor(private AuthService: SUAuthenticationService,
          private UserService: CurrentUserService,
          private HeaderService: HeaderService,
          private router: Router) {

               this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
               if(!this.currentUser){
                    this.HeaderService.publishUserType('notype')
               }else{
                    this.HeaderService.publishUserType(this.currentUser.role);
                    this.HeaderService.publishWorkType(this.currentUser.role);
               }

               this.HeaderService.usertype$.subscribe((usertype) => {
                    this.loadheader(usertype);
               });
               this.UserService.currentuser$.subscribe((user) => {
                    this.currentUser = user;
               });
          }


          ngOnInit() {
               this.currentUser = this.UserService.getCurrentUser();
               if(!this.currentUser){
                    this.HeaderService.publishUserType('notype')
               }else{
                    this.HeaderService.publishUserType(this.currentUser.role);
                    this.HeaderService.publishWorkType(this.currentUser.role);
               }
          }

          loadheader(usertype){
               if(usertype=="notype"){
                    this.navbarlinks=[{text:'Inregistreaza-te',
                    link: '/register'},
                    {text:'Intra',
                    link: '/login'}];
                    this.logoutvar=false;
               } else if (usertype=="superadmin"){
                    this.logoutvar=true;
                    this.navbarlinks=[{text:'Administrator aplicatie',
                    linkrole:'superadmin',
                    link:'/super-admin'}];
               } else if (usertype=="dezbusadmin"){
                    this.logoutvar=true;
                    this.navbarlinks=[{text:'Utilizator aplicatie',
                    linkrole:'admin',
                    link:'/admin'}];
               } else if (usertype=="vendor"){
                    this.logoutvar=true;
                    this.navbarlinks=[{text:'Vendor StreetFood',
                    linkrole:'vendor',
                    link:'/vendor'}];
               } else {
                    this.logoutvar=true;
               }
          }

          linkclicked(link, linkrole){
               this.HeaderService.publishWorkType(linkrole);
               this.router.navigate([link]);
          }

          gohome(){
               this.router.navigate(['/home'])
          }

          logout(){
               this.AuthService.logout();
               this.HeaderService.publishUserType('notype')
               this.logoutvar=false;
               this.router.navigate(['/login']);

          }

     }
