import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuModifierService } from 'app/_services/menu-modifier.service';

import { LeftMenu , Category , MenuItem, SubMenuItem} from 'app/_models/leftmenu';

@Component({
  selector: 'app-menubuilder',
  templateUrl: './menubuilder.component.html',
  styleUrls: ['./menubuilder.component.css']
})
export class MenuBuilderComponent implements OnInit {
  workmenu: LeftMenu;
  constructor( private MenuService: MenuModifierService,
              private router: Router ) {
                this.MenuService.leftmenu$.subscribe((menuData) => {
                  this.workmenu = menuData;
                });
               }

  ngOnInit() {
  }

  menuupdate(){
    this.MenuService.updateWorkMenu(this.workmenu)
    .subscribe(
        data => {
              this.MenuService.getWorkMenu(this.workmenu.menu)
                  .subscribe(
                    data => {
                             this.MenuService.publishMenu(data);
                            },
                    );

        },
        error => {
        });
  }

  addcategory(category){
    this.MenuService.publishCategory(category);
    this.router.navigate(['/super-admin/menu-modify/add-category']);
  }

  editcategory(category){
    this.MenuService.publishCategory(category);
    this.router.navigate(['/super-admin/menu-modify/edit-category']);
  }


  deletecategory(category){
    this.workmenu.categories.splice(category, 1);
    this.menuupdate();
  }

  categoryup(category){
    var aux=<Category>{};
    aux=this.workmenu.categories[category];
    this.workmenu.categories.splice(category, 1);
    this.workmenu.categories.splice(category-1, 0, aux);
    this.menuupdate();
  }

  categorydown(category){
    var aux=<Category>{};
    aux=this.workmenu.categories[category];
    this.workmenu.categories.splice(category, 1);
    this.workmenu.categories.splice(category+1, 0, aux);
    this.menuupdate();
  }

  addmenuitem(category, menuitem){
    this.MenuService.publishMenuItem(category, menuitem);
    this.router.navigate(['/super-admin/menu-modify/add-menuitem']);
  }

  editmenuitem(category, menuitem){
    this.MenuService.publishMenuItem(category, menuitem);
    this.router.navigate(['/super-admin/menu-modify/edit-menuitem']);
  }

  deletemenuitem(category, menuitem){
    this.workmenu.categories[category].menuitems.splice(menuitem, 1);
    this.menuupdate();
  }

  menuitemup(category,menuitem){
    var aux=<MenuItem>{};
    aux=this.workmenu.categories[category].menuitems[menuitem];
    this.workmenu.categories[category].menuitems.splice(menuitem, 1);
    this.workmenu.categories[category].menuitems.splice(menuitem-1, 0, aux);
    this.menuupdate();
  }

  menuitemdown(category, menuitem){
    var aux=<MenuItem>{};
    aux=this.workmenu.categories[category].menuitems[menuitem];
    this.workmenu.categories[category].menuitems.splice(menuitem, 1);
    this.workmenu.categories[category].menuitems.splice(menuitem+1, 0, aux);
    this.menuupdate();
  }

  addsubmenuitem(category, menuitem, submenuitem){
    this.MenuService.publishSubMenuItem(category, menuitem, submenuitem);
    this.router.navigate(['/super-admin/menu-modify/add-submenuitem']);
  }

  editsubmenuitem(category, menuitem, submenuitem){
    this.MenuService.publishSubMenuItem(category, menuitem, submenuitem);
    this.router.navigate(['/super-admin/menu-modify/edit-submenuitem']);
  }

  deletesubmenuitem(category, menuitem, submenuitem){
    this.workmenu.categories[category].menuitems[menuitem].submenuitems.splice(submenuitem, 1);
    this.menuupdate();
  }

  submenuitemup(category,menuitem, submenuitem){
    var aux=<SubMenuItem>{};
    aux=this.workmenu.categories[category].menuitems[menuitem].submenuitems[submenuitem];
    this.workmenu.categories[category].menuitems[menuitem].submenuitems.splice(submenuitem, 1);
    this.workmenu.categories[category].menuitems[menuitem].submenuitems.splice(submenuitem-1, 0, aux);
    this.menuupdate();
  }

  submenuitemdown(category, menuitem, submenuitem){
    var aux=<SubMenuItem>{};
    aux=this.workmenu.categories[category].menuitems[menuitem].submenuitems[submenuitem];
    this.workmenu.categories[category].menuitems[menuitem].submenuitems.splice(submenuitem, 1);
    this.workmenu.categories[category].menuitems[menuitem].submenuitems.splice(submenuitem+1, 0, aux);
    this.menuupdate();
  }

  editlink(category, menuitem, submenuitem){
    this.MenuService.publishSubMenuItem(category, menuitem, submenuitem);
    this.router.navigate(['/super-admin/menu-modify/edit-link']);
  }

}
