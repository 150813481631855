import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';

import { CheckUniqueInArrayValidation } from '../../../_validators/check-unique-in-array';

import { AppElementsService } from '../../../_services/appelements.service';

import { AppElement } from '../../../_models/appelement';

@Component({
     selector: 'app-app-elements',
     templateUrl: './app-elements.component.html',
     styleUrls: ['./app-elements.component.css']
})

export class AppElementsComponent implements OnInit {

     addvehiclecatformvisible: boolean;
     addvehiclesubcatformvisible: boolean;
     addpartcatformvisible: boolean;
     addpartsubcatformvisible: boolean;
     modifyvehiclecatformvisible: boolean;
     modifyvehiclesubcatformvisible: boolean;
     modifypartcatformvisible: boolean;
     modifypartsubcatformvisible: boolean;

     vehiclecatloaded: boolean;
     partcatloaded: boolean;
     elementsloaded: boolean;

     vehiclesubcategoriesvisible: boolean;
     partsubcategoriesvisible: boolean;

     vehiclecatarray: string[];
     vehiclesubcatarray:string[];
     partcatarray: string[];
     partsubcatarray:string[];

     selectedvehiclecat: string;
     selectedpartcat:string;

     vehiclecatindex: number;
     vehiclesubcatindex: number;
     partcatindex: number;
     partsubcatindex: number;

     vehiclesubcatsselected: boolean;
     partsubcatsselected: boolean;

     vehiclecategories: any[];
     partcategories: any[];
     vehiclesubcategories: any[];
     partsubcategories: any[];

     elements: any;

     addVehicleCatForm = new FormGroup({
          categorie: new FormControl()
     });

     addVehicleSubCatForm = new FormGroup({
          subcategorie: new FormControl()
     });

     addPartCatForm = new FormGroup({
          categorie: new FormControl()
     });

     addPartSubCatForm = new FormGroup({
          subcategorie: new FormControl()
     });

     modifyVehicleCatForm = new FormGroup({
          categorie: new FormControl()
     });

     modifyVehicleSubCatForm = new FormGroup({
          subcategorie: new FormControl()
     });

     modifyPartCatForm = new FormGroup({
          categorie: new FormControl()
     });

     modifyPartSubCatForm = new FormGroup({
          subcategorie: new FormControl()
     });

     constructor(
          private router: Router,
          private elementsService: AppElementsService,
          private formBuilder: FormBuilder,
     ) {
          this.addvehiclecatformvisible = false;
          this.addvehiclesubcatformvisible = false;
          this.addpartcatformvisible = false;
          this.addpartsubcatformvisible = false;

          this.modifyvehiclecatformvisible = false;
          this.modifyvehiclesubcatformvisible = false;
          this.modifypartcatformvisible = false;
          this.modifypartsubcatformvisible = false;

          this.vehiclesubcatsselected = false;

          this.getElements();

          this.addVehicleCatForm = this.formBuilder.group({
               categorie: ['', [Validators.required, Validators.minLength(5)]]
          },{
               validator: CheckUniqueInArrayValidation.MatchElements
          })

          this.addVehicleSubCatForm = this.formBuilder.group({
               subcategorie: ['', [Validators.required, Validators.minLength(5)]]
          },{
               validator: CheckUniqueInArrayValidation.MatchElements
          })

          this.addPartCatForm = this.formBuilder.group({
               categorie: ['', [Validators.required, Validators.minLength(5)]]
          },{
               validator: CheckUniqueInArrayValidation.MatchElements
          })

          this.addPartSubCatForm = this.formBuilder.group({
               subcategorie: ['', [Validators.required, Validators.minLength(5)]]
          },{
               validator: CheckUniqueInArrayValidation.MatchElements
          })

          this.modifyVehicleCatForm = this.formBuilder.group({
               categorie: ['', [Validators.required, Validators.minLength(5)]]
          },{
               validator: CheckUniqueInArrayValidation.MatchElements
          })

          this.modifyVehicleSubCatForm = this.formBuilder.group({
               subcategorie: ['', [Validators.required, Validators.minLength(5)]]
          },{
               validator: CheckUniqueInArrayValidation.MatchElements
          })

          this.modifyPartCatForm = this.formBuilder.group({
               categorie: ['', [Validators.required, Validators.minLength(5)]]
          },{
               validator: CheckUniqueInArrayValidation.MatchElements
          })

          this.modifyPartSubCatForm = this.formBuilder.group({
               subcategorie: ['', [Validators.required, Validators.minLength(5)]]
          },{
               validator: CheckUniqueInArrayValidation.MatchElements
          })
     }

     ngOnInit() {
     }

     onSubmitVehicleCatForm({ value, valid }: { value: any, valid: boolean }){

          var newcategory = {
               category: value.categorie,
               subcategories: []
          }
          if(this.vehiclecatloaded){
               this.vehiclecategories.splice(this.vehiclecatindex, 0, newcategory);
               this.elements.vehicle_categories = this.vehiclecategories;
               this.elementsService.update(this.elements)
               .subscribe(
                    (data)=>{
                         this.getElements();
                    }
               )
          }else{
               this.vehiclecategories.push(newcategory);
               this.elements.vehicle_categories = this.vehiclecategories;
               if(this.elementsloaded){
                    this.elementsService.update(this.elements)
                    .subscribe(
                         (data)=>{
                              this.getElements();
                         }
                    )
               }else{
                    this.elementsService.create(this.elements)
                    .subscribe(
                         (data)=>{
                              this.getElements();
                         }
                    )
               }

          }

          this.addVehicleCatForm.reset();

     }

     onSubmitPartCatForm({ value, valid }: { value: any, valid: boolean }){

          var newcategory = {
               category: value.categorie,
               subcategories: []
          }
          if(this.partcatloaded){
               this.partcategories.splice(this.partcatindex, 0, newcategory);
               this.elements.parts_categories = this.partcategories;
               this.elementsService.update(this.elements)
               .subscribe(
                    (data)=>{
                         this.getElements();
                    }
               )

          }else{
               this.partcategories.push(newcategory);
               this.elements.parts_categories = this.partcategories;
               if(this.elementsloaded){
                    this.elementsService.update(this.elements)
                    .subscribe(
                         (data)=>{
                              this.getElements();
                         }
                    )
               }else{
                    this.elementsService.create(this.elements)
                    .subscribe(
                         (data)=>{
                              this.getElements();
                         }
                    )
               }
          }

          this.addPartCatForm.reset();


     }

     onSubmitVehicleSubCatForm({ value, valid }: { value: any, valid: boolean }){

          var vehiclemade = {
               marca: value.subcategorie,
               models: []
          }

          if(this.vehiclesubcategories.length>0){
               this.vehiclesubcategories.splice(this.vehiclesubcatindex,0,vehiclemade);
          }else{
               this.vehiclesubcategories.push(vehiclemade)
          }

          this.vehiclecategories[this.vehiclecatindex].subcategories = this.vehiclesubcategories;
          this.elements.vehicle_categories = this.vehiclecategories;
          this.elementsService.update(this.elements)
          .subscribe(
               (data)=>{
                    this.getElements();
               }
          )

          this.addVehicleSubCatForm.reset();

     }

     onSubmitPartSubCatForm({ value, valid }: { value: any, valid: boolean }){

          if(this.partsubcategories.length>0){
               this.partsubcategories.splice(this.partsubcatindex,0,value.subcategorie);
          }else{
               this.partsubcategories.push(value.subcategorie)
          }
          console.log(this.partcategories);

          this.partcategories[this.partcatindex].subcategories = this.partsubcategories;
          this.elements.parts_categories = this.partcategories;
          this.elementsService.update(this.elements)
          .subscribe(
               (data)=>{
                    this.getElements();
               }
          )

          this.addPartSubCatForm.reset();

     }

     onSubmitModVehicleCatForm({ value, valid }: { value: any, valid: boolean }){

          this.vehiclecategories[this.vehiclecatindex].category = value.categorie;
          this.elements.vehicle_categories = this.vehiclecategories;
          this.elementsService.update(this.elements)
          .subscribe(
               (data)=>{
                    this.getElements();
               }
          )

          this.modifyVehicleCatForm.reset();
          this.modifyvehiclecatformvisible = false;

     }

     onSubmitModPartCatForm({ value, valid }: { value: any, valid: boolean }){

          this.partcategories[this.partcatindex].category = value.categorie;
          this.elements.parts_categories = this.partcategories;
          this.elementsService.update(this.elements)
          .subscribe(
               (data)=>{
                    this.getElements();
               }
          )

          this.modifyPartCatForm.reset();
          this.modifypartcatformvisible = false;


     }


     onSubmitModVehicleSubCatForm({ value, valid }: { value: any, valid: boolean }){

          var vehiclemade = {
               marca: value.subcategorie,
               models: []
          }

          this.vehiclesubcategories.splice(this.vehiclesubcatindex,1,vehiclemade);
          this.vehiclecategories[this.vehiclecatindex].subcategories = this.vehiclesubcategories;
          this.elements.vehicle_categories = this.vehiclecategories;
          this.elementsService.update(this.elements)
          .subscribe(
               (data)=>{
                    this.getElements();
               }
          )

          this.modifyVehicleSubCatForm.reset();
          this.modifyvehiclesubcatformvisible = false;

     }

     onSubmitModPartSubCatForm({ value, valid }: { value: any, valid: boolean }){

          this.partsubcategories.splice(this.partsubcatindex,1,value.subcategorie);
          this.partcategories[this.partcatindex].subcategories = this.partsubcategories;
          this.elements.parts_categories = this.partcategories;
          this.elementsService.update(this.elements)
          .subscribe(
               (data)=>{
                    this.getElements();
               }
          )

          this.modifyPartSubCatForm.reset();
          this.modifypartsubcatformvisible = false;

     }

     deleteVehicleCategory(index){
          this.vehiclecategories.splice(index, 1);
          this.elements.vehicle_categories = this.vehiclecategories;
          this.elementsService.update(this.elements)
          .subscribe(
               (data)=>{
                    this.getElements();
               }
          )
     }

     deleteVehicleSubCategory(index){
          this.vehiclesubcategories.splice(index,1);
          this.vehiclecategories[this.vehiclecatindex].subcategories = this.vehiclesubcategories;
          this.elements.vehicle_categories = this.vehiclecategories;
          this.elementsService.update(this.elements)
          .subscribe(
               (data)=>{
                    this.getElements();
               }
          )
     }

     modifyVehicleCategory(index, value){

          this.vehiclecatindex = index;
          this.modifyvehiclecatformvisible = true;
          this.modifyVehicleCatForm.controls['categorie'].setValue(value);
     }

     modifyVehicleSubCategory(index, value){

          this.vehiclesubcatindex = index;
          this.modifyvehiclesubcatformvisible = true;
          this.modifyVehicleSubCatForm.controls['subcategorie'].setValue(value);
     }

     deletePartCategory(index){
          this.partcategories.splice(index, 1);
          this.elements.parts_categories = this.partcategories;
          this.elementsService.update(this.elements)
          .subscribe(
               (data)=>{
                    this.getElements();
               }
          )
     }

     deletePartSubCategory(index){
          this.partsubcategories.splice(index,1);
          this.partcategories[this.partcatindex].subcategories = this.partsubcategories;
          this.elements.parts_categories = this.partcategories;
          this.elementsService.update(this.elements)
          .subscribe(
               (data)=>{
                    this.getElements();
               }
          )
     }

     modifyPartCategory(index, value){

          this.partcatindex = index;
          this.modifypartcatformvisible = true;
          this.modifyPartCatForm.controls['categorie'].setValue(value);
     }

     modifyPartSubCategory(index, value){

          this.partsubcatindex = index;
          this.modifypartsubcatformvisible = true;
          this.modifyPartSubCatForm.controls['subcategorie'].setValue(value);
     }


     getElements(){
          this.elementsService.getAll()
          .subscribe(
               (appelements)=>{
                    console.log(appelements.length);

                    if(appelements.length>0){
                         this.elementsloaded = true;
                         this.elements=appelements[0]
                         this.vehiclecategories = appelements[0].vehicle_categories;
                         if(this.vehiclecategories!==null && typeof this.vehiclecategories !=="undefined" && this.vehiclecategories.length>0){
                              this.vehiclecategories.map((category)=>{category.selected = false})
                              this.addvehiclecatformvisible = false;
                              this.addvehiclesubcatformvisible = false;
                              this.vehiclecatloaded = true;
                         }else{
                              this.addvehiclecatformvisible=true;
                              this.vehiclecatloaded = false;
                              this.vehiclecategories = []
                              this.vehiclecatindex = 0;
                         }

                         this.partcategories = appelements[0].parts_categories;
                         if(this.partcategories!==null && typeof this.partcategories !=="undefined" && this.partcategories.length>0){
                              this.partcategories.map((category)=>{category.selected = false})
                              this.addpartcatformvisible=false;
                              this.addpartsubcatformvisible = false;
                              this.partcatloaded = true;
                         }else{
                              this.addpartcatformvisible=true;
                              this.partcatloaded = false;
                              this.partcategories = [];
                              this.vehiclecatindex = 0;
                         }

                    }else{
                         this.elementsloaded=false;
                         this.elements={}
                         this.addvehiclecatformvisible=true;
                         this.addpartcatformvisible=true;
                         this.vehiclecatloaded = false;
                         this.partcatloaded = false;

                         this.partcatindex = 0;
                         this.partsubcatindex = 0;
                         this.vehiclecatindex = 0;
                         this.vehiclesubcatindex = 0;

                         this.vehiclecategories = [];
                         this.partcategories = [];
                    }
               },
               (error)=>{
                    console.log(error);
               }
          )
     }

     hoverInVehicleCategories(index){
          if(!this.vehiclesubcatsselected){
               this.vehiclesubcategoriesvisible = true;
               this.selectedvehiclecat = this.vehiclecategories[index].category+'/';
               this.vehiclesubcategories = this.vehiclecategories[index].subcategories;
          }
     }

     hoverOutVehicleCategories(index){
          if(!this.vehiclesubcatsselected){
               this.vehiclesubcategoriesvisible = false;
               this.selectedvehiclecat = '';
               this.vehiclesubcategories = [];
          }
     }

     hoverInPartCategories(index){
          if(!this.partsubcatsselected){
               this.partsubcategoriesvisible = true;
               this.selectedpartcat = this.partcategories[index].category+'/';
               this.partsubcategories = this.partcategories[index].subcategories;
          }
     }

     hoverOutPartCategories(index){
          if(!this.partsubcatsselected){
               this.partsubcategoriesvisible = false;
               this.selectedpartcat = '';
               this.partsubcategories = [];
          }
     }

     viewVehicleSubCategories(index){

          this.vehiclecatindex = index;
          this.addvehiclesubcatformvisible = false;
          let selected = false;
          this.vehiclecategories.map((category, idx)=>{
               if (category.selected){
                    category.selected = false;
                    if(!selected){
                         this.vehiclesubcatsselected = false;
                         this.vehiclesubcategoriesvisible = false;
                         this.selectedvehiclecat='';
                    }
               }else if(index == idx && !category.selected){
                    category.selected = true;
                    this.vehiclesubcatsselected = true;
                    this.vehiclesubcategoriesvisible = true;
                    this.selectedvehiclecat = category.category+'/';
                    this.vehiclesubcategories = category.subcategories;
                    selected = true;
               }
          })
     }

     viewPartSubCategories(index){

          this.partcatindex = index;
          this.addpartsubcatformvisible = false;
          let selected = false;
          this.partcategories.map((category, idx)=>{
               if (category.selected){
                    category.selected = false;
                    if(!selected){
                         this.partsubcatsselected = false;
                         this.partsubcategoriesvisible = false;
                         this.selectedpartcat='';
                    }
               }else if(index == idx && !category.selected){
                    category.selected = true;
                    this.partsubcatsselected = true;
                    this.partsubcategoriesvisible = true;
                    this.selectedpartcat = category.category+'/';
                    this.partsubcategories = category.subcategories;
                    selected = true;
               }
          })
     }

     addVehicleCategory(index){
          this.vehiclecatindex = index+1;
          this.addvehiclecatformvisible=true;
          this.addvehiclesubcatformvisible=false;
     }

     addVehicleSubCategory(index){
          this.vehiclesubcatindex = index+1;
          this.addvehiclesubcatformvisible=true;
          this.addvehiclecatformvisible=false;
     }

     addPartCategory(index){
          this.partcatindex = index+1;
          this.addpartcatformvisible=true;
          this.addpartsubcatformvisible=false;
     }

     addPartSubCategory(index){
          this.partsubcatindex = index+1;
          this.addpartsubcatformvisible=true;
          this.addpartcatformvisible=false;
     }

     }
