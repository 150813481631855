import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { User } from 'app/_models/user';
import { LeftMenuService } from 'app/_services/leftmenu.service';
import { HeaderService } from 'app/_services/header.service';

import { LeftMenu , Category , MenuItem, SubMenuItem} from 'app/_models/leftmenu';

@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.css']
})
export class LeftmenuComponent implements OnInit {
  currentUser: User;
  leftmenu: LeftMenu;
  usertype: string;
  categoryselected: boolean;
  categoryfocused: boolean;
  menuitemfocused: boolean;
  submenuitemfocused: boolean;
  menuroutetoggled: boolean;
  selectedcategory: Category;
  selectedmenuitem: MenuItem;
  selectedsubmenuitem: SubMenuItem;
  selectedcategoryindex: number;
  selectedmenuitemindex: number;
  selectedsubmenuitemindex: number;
  menuroute: string;

  constructor(private MenuService: LeftMenuService,
              private HeaderService: HeaderService,
              private router: Router) {
                  this.categoryselected = false;
                  this.categoryfocused =false;
                  this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                  this.MenuService.leftmenu$.subscribe((menuData) => {
                    this.leftmenu = menuData;
                  });
                  this.MenuService.selectedindexes$.subscribe((indexes) =>{
                    this.selectmenu(indexes.category, indexes.menuitem, indexes.submenuitem);
                  });
                  this.HeaderService.worktype$.subscribe((usertype) => {
                    if(usertype!=="notype"){
                      this.MenuService.getLeftMenu(usertype)
                            .subscribe(
                              menu => {
                                       this.MenuService.publishLeftMenu(menu);
                                      },
                              );
                            }
                        });
  }

  ngOnInit() {
      this.leftmenu=this.MenuService.getWorkMenu();
  }

  togglecategory() {
    this.categoryfocused=!this.categoryfocused;
    if(!this.categoryfocused){
      this.menuitemfocused=false;
      this.submenuitemfocused=false;
    }else{
      this.menuroutetoggled=false;
    }
  }

  togglemenuitem() {
    this.menuitemfocused=!this.menuitemfocused;
    if(!this.menuitemfocused){
      this.submenuitemfocused=false;
    }else{
      this.menuroutetoggled=false;
    }
  }

  togglemenuroute(){
    this.menuroutetoggled=!this.menuroutetoggled;
  }

  togglesubmenuitem() {
    this.submenuitemfocused=!this.submenuitemfocused;
    if(this.submenuitemfocused){
      this.menuroutetoggled=false;
    }
  }

  selectmenu(categoryindex, menuitemindex, submenuitemindex){

    this.selectedcategoryindex=categoryindex;
    this.selectedmenuitemindex=menuitemindex;
    this.selectedsubmenuitemindex=submenuitemindex;


    console.log(categoryindex, menuitemindex, submenuitemindex)
    this.leftmenu.categories.map(function(category, catindex){
      if(categoryindex!=catindex)
      {
        category.selected=false;
      }
      category.menuitems.map(function(menuitem, menindex){
        if(menuitemindex!=menindex){
          menuitem.focused=false;
          menuitem.selected=false;
        }
        if(typeof menuitem.submenuitems && menuitem.submenuitems.length>0){
          menuitem.submenu=true;
        }else{
          menuitem.submenu=false;
        }
        menuitem.submenuitems.map(function(submenuitem){
          submenuitem.selected=false;
        });
      });
    });
    if(typeof categoryindex!=="undefined"&& typeof menuitemindex!=="undefined"&& typeof submenuitemindex!=="undefined"){
      this.leftmenu.categories.map(function(category, catindex){
        category.selected=false;
        if(catindex==categoryindex){
          category.selected=true;
          category.selected=true;
          this.categoryselected=true;
          this.selectedcategory=category;
          this.categoryfocused=false;
          category.menuitems.map(function(menuitem, mitemindex){
            menuitem.selected=false;
            if(mitemindex==menuitemindex){
              menuitem.selected=true;
              category.selected=true;
              this.selectedmenuitem=menuitem;
              this.menuitemfocused=false;
              menuitem.submenuitems.map(function(submenuitem, smitemindex){
                submenuitem.selected=false
                if(smitemindex==submenuitemindex){
                  submenuitem.selected=!submenuitem.selected;
                  this.submenuitemfocused=!this.submenuitemfocused;
                  this.selectedsubmenuitem=submenuitem;
                  if(submenuitem.selected && submenuitem.link!=='' && typeof submenuitem.link!=="undefined"){
                    this.router.navigate([submenuitem.link]);
                    this.menuroute='/' + category.text + '/'+ menuitem.text +'/' + submenuitem.text;
                    this.togglemenuroute();
                  }
                }
              }.bind(this));
            };
          }.bind(this));
        };
      }.bind(this));
    } else if(typeof categoryindex!=="undefined"&& typeof menuitemindex!=="undefined"&& typeof submenuitemindex=="undefined"){
      this.submenuitemfocused = false;
      this.leftmenu.categories.map(function(category, catindex){
        category.selected=false;
        if(catindex==categoryindex){
          category.selected=true;
          this.categoryselected=true;
          this.selectedcategory=category;
          this.categoryfocused=false;
          category.menuitems.map(function(menuitem, mitemindex){
            if(mitemindex==menuitemindex){
              menuitem.selected=!menuitem.selected;
              this.menuitemfocused=false;
              if(typeof menuitem.submenuitems!=="undefined" && menuitem.submenuitems.length>0){
                menuitem.submenuitems[0].selected=true;
                this.selectedmenuitem=menuitem;
                this.submenuitemfocused=true;
                if(menuitem.submenuitems[0].link!=='' && typeof menuitem.submenuitems[0].link!=="undefined"){
                  // this.router.navigate([menuitem.submenuitems[0].link]);
                  // this.menuroute='/' + category.text + '/'+ menuitem.text +'/' + menuitem.submenuitems[0].text;
                }
              }else if(menuitem.link!=='' && typeof menuitem.link!=="undefined"){
                  this.router.navigate([menuitem.link]);
                  this.menuroute='/' + category.text + '/'+ menuitem.text;
                  this.togglemenuroute();
              }
            }else{
              menuitem.selected=false
            };
          }.bind(this));
        };
      }.bind(this));
    } else if(typeof categoryindex!=="undefined"&& typeof menuitemindex=="undefined"&& typeof submenuitemindex=="undefined"){

      this.menuitemfocused = false;
      this.submenuitemfocused = false;

      this.leftmenu.categories.map(function(category, catindex){
        if(categoryindex==catindex){
          if(category.selected){
            category.selected=false;
            if(typeof category.menuitems!=="undefined" && category.menuitems.length>0){
              category.menuitems[0].selected=false
            }
          }else{
            category.selected=true;
            this.categoryselected=true;
            this.selectedcategory=category;
            this.categoryfocused=false;
            this.menuitemfocused=true;
          if(category.selected && typeof category.menuitems!=="undefined" && category.menuitems.length>0){
            category.menuitems[0].focused=true;
            if(typeof category.menuitems[0].link!=="undefined" && category.menuitems[0].link!==''){
              // this.router.navigate([category.menuitems[0].link]);
              // this.menuroute='/' + category.text + '/'+ category.menuitems[0].text;
            }
          } else if(typeof category.link!=="undefined" && category.link!==''){
            this.router.navigate([category.link]);
            this.menuroute='/' + category.text;
            this.togglemenuroute();
          }
        }
        }else{
          category.selected=false
        }
      }.bind(this));
    };
  };

}
