import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { MapsAPILoader } from '@agm/core';
import {} from '@types/googlemaps';

import { DorneeanService } from 'app/_services/dorneean.service';
import { AssignGeodataService } from 'app/_services/assignGeodata.service';
import { LeftMenuService } from 'app/_services/leftmenu.service';
//
import { Company } from 'app/_models/company';


@Component({
  selector: 'app-dorneean',
  templateUrl: './dorneean-data.component.html',
  styleUrls: ['./dorneean-data.component.css']
})

export class DorneeanDataComponent implements OnInit {


      company: Company;
      loadedcompany: any={};
      placecomponents: any={};

      @ViewChild("search")
        public searchElementRef: ElementRef;

      companyForm = new FormGroup({
        denumire: new FormControl(),
        locatie: new FormControl(),
        adresa: new FormControl(),
        reprezentant: new FormControl(),
        email: new FormControl(),
        telefon: new FormControl(),
        fax: new FormControl(),
        cui: new FormControl(),
        regcom: new FormControl(),
        cont: new FormControl(),
        banca: new FormControl(),


      });

      constructor(private formBuilder: FormBuilder,
                  private mapsAPILoader: MapsAPILoader,
                  private router: Router,
                  private ngZone: NgZone,
                  private geoService: AssignGeodataService,
                  private companyService: DorneeanService,
                  private leftMenuService: LeftMenuService,
                ) {
                    this.geoService.place$.subscribe((placeData) => {
                      this.placecomponents = placeData;
                      this.loadedcompany.localitate = this.placecomponents.localitate;
                      this.loadedcompany.judet = this.placecomponents.judet;
                    });
                    this.company={
                      _id:'',
                      denumire:'',
                      localitate:'',
                      judet:'',
                      reprezentant:'',
                      adresa:'',
                      email:'',
                      telefon:'',
                      fax:'',
                      cui:'',
                      regcom:'',
                      cont:'',
                      banca:''
                    } as Company;
                   }

      ngOnInit() {
        this.companyService.getAll()
        .subscribe(
          data => {
              this.loadedcompany = data[0];
              this.company=this.loadedcompany;
              this.loadedcompany.locatie=this.loadedcompany.localitate +', '+ this.loadedcompany.judet;
              this.companyForm = this.formBuilder.group({
                    denumire: [this.loadedcompany.denumire,[Validators.required, Validators.minLength(2)]],
                    locatie: [this.loadedcompany.locatie, Validators.required],
                    adresa: [this.loadedcompany.adresa,[Validators.required, Validators.minLength(5)]],
                    reprezentant: [this.loadedcompany.reprezentant,[Validators.required, Validators.minLength(5)]],
                    email: [this.loadedcompany.email,[Validators.required, Validators.minLength(4), Validators.email]],
                    telefon: [this.loadedcompany.telefon,[Validators.required, Validators.minLength(10),Validators.maxLength(10)]],
                    fax: [this.loadedcompany.fax,[Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
                    cui: [this.loadedcompany.cui,[Validators.required, Validators.minLength(5)]],
                    regcom: [this.loadedcompany.regcom,[Validators.required, Validators.minLength(10)]],
                    cont: [this.loadedcompany.cont,[Validators.required, Validators.minLength(24), Validators.maxLength(24)]],
                    banca: [this.loadedcompany.banca,[Validators.required, Validators.minLength(5)]],
                  });

                  this.mapsAPILoader.load().then(() => {
                    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                      types: ["(cities)"],
                      componentRestrictions: {country: 'ro'}

                    });
                    autocomplete.addListener("place_changed", () => {
                      this.ngZone.run(() => {
                        //get the place result
                        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

                        //verify result
                        if (place.geometry === undefined || place.geometry === null) {
                          return;
                        }
                        this.geoService.getElements(place.address_components);
                      });
                    });
                  });
          });
      }

      onSubmit({ value, valid }: { value: any, valid: boolean }) {

        this.company.denumire = value.denumire;
        this.company.localitate = this.loadedcompany.localitate;
        this.company.judet = this.loadedcompany.judet;
        this.company.adresa = value.adresa;
        this.company.reprezentant = value.reprezentant;
        this.company.email = value.email;
        this.company.telefon = value.telefon;
        this.company.fax = value.fax;
        this.company.cui = value.cui;
        this.company.regcom = value.regcom;
        this.company.cont = value.cont;
        this.company.banca = value.banca;

        console.log(this.company);

        this.companyService.update(this.company)
            .subscribe(
              data => {
                      this.router.navigate(['/super-admin']);
                      },
              error => {
                       });

      }

      gohome(){
        this.router.navigate(['/super-admin']);
      }


  }
