import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { AppConfig } from '../app.config';
import { Part } from '../_models/part';


@Injectable()
export class PartsService {

     constructor(private http: HttpClient, private config: AppConfig) {

     }

     getAll(){
          return this.http.get<Part[]>(this.config.apiUrl + '/parts', this.jwt());
     }

     getById(_id: string){
          return this.http.get<Part>(this.config.apiUrl + '/parts/' + _id, this.jwt());
     }

     create(data: Part){
          return this.http.post<Part>(this.config.apiUrl + '/parts/create', data, this.jwt());
     }

     update(data: Part){
          return this.http.put<Part>(this.config.apiUrl + '/parts/' + data._id, data, this.jwt());
     }

     delete(_id: string){
          return this.http.delete(this.config.apiUrl + '/parts/' + _id, this.jwt());
     }

     getByQuery(query: any){
          return this.http.post<any>(this.config.apiUrl + '/parts/getByQuery',query, this.jwt())
     }

     getLastPartNo(){
          return this.http.get<any>(this.config.apiUrl + '/parts/lastpartno', this.jwt())
     }


     private jwt(){
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          if (currentUser && currentUser.token){
               let headers = new HttpHeaders().set('Content-Type', 'application/json')
               .append('Access-Control-Allow-Origin', '*')
               .append('Authorization', 'Bearer '+ currentUser.token)
               .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS');

               return { headers: headers };
          }
    }
}
