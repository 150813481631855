import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { Place } from '../_models/place';


@Injectable()
export class AssignGeodataService {
  workplace: any={};
  place: Subject<Place>;
  place$: Observable<Place>;

  constructor() {
      this.place = new Subject();
      this.place$ = this.place.asObservable();
  };

  getElements(geodata) {
      this.workplace={};
            geodata.forEach(function(value) {

              if(value.types[0]=='street_number'){
                    this.workplace.googlenr=value.long_name;
                    }
               else if(value.types[0]=='route'){
                     this.workplace.strada=value.long_name;
                     }
               else if(value.types[0]=='locality'){
                     this.workplace.localitate=value.long_name;
                     }
                else if(value.types[0]=='administrative_area_level_2'){
                     this.workplace.aria_administrativa=value.long_name;
                     }
                else if(value.types[0]=='administrative_area_level_1'){
                     this.workplace.judet=value.long_name;
                     }
                else if(value.types[0]=='neighborhood'){
                     this.workplace.cartier=value.long_name;
                     }
                else if(value.types[0]=='postal_code'){
                      this.workplace.cod_postal=value.long_name;
                     }
                else if(value.types[0]=='sublocality_level_1'){
                      this.workplace.sector=value.long_name;
                     }
                else if(value.types[0]=='country'){
                      this.workplace.tara=value.long_name;
                      this.workplace.codtara=value.short_name.toLowerCase();
                     }

              }.bind(this));
              this.publishPlace(this.workplace);

        }

        publishPlace(place){
          this.workplace = place;
          this.place.next(place);
        }

        getPlace(place){
          return this.workplace;
        }


}
