import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppConfig } from '../app.config';
import { AppUser } from '../_models/appuser';
import { Checker } from '../_models/checker';

@Injectable()
export class AppUserService {
  appuser: AppUser;
  data:{exists:boolean};


  constructor(private http: HttpClient, private config: AppConfig) { }

  checkuniquename(username){
    return this.http.post<Checker>(this.config.apiUrl + '/appusers/checkuniquename', {username: username});
  }

  checkuniquemail(email){
    return this.http.post<Checker>(this.config.apiUrl + '/appusers/checkuniquemail', {email: email});
  }

  settempuser(appuser){
    this.appuser=appuser;
  }

  gettempuser(){
    return this.appuser;
  }

  createfromtemp(){
    this.create(this.appuser);
  }

  getAll(){
    return this.http.get<AppUser[]>(this.config.apiUrl + '/appusers', this.jwt());
  }

  getById(_id: string){
    return this.http.get<AppUser>(this.config.apiUrl + '/appusers/' + _id, this.jwt());
  }

  getByOrganizer(_id: string){
    return this.http.get<AppUser[]>(this.config.apiUrl + '/appusers/getByOrganizer/' + _id, this.jwt());
  }

  create(appuser: AppUser){
    return this.http.post(this.config.apiUrl + '/appusers/register', appuser, this.jwt());
  }

  update(appuser: AppUser){
    return this.http.put(this.config.apiUrl + '/appusers/' + appuser._id, appuser, this.jwt());
  }

  delete(_id: string){
    return this.http.delete(this.config.apiUrl + '/appusers/' + _id, this.jwt());
  }

  private jwt(){
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .append('Access-Control-Allow-Origin', '*')
    .append('Authorization', 'Bearer '+ currentUser.token)
    .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS');

            return { headers: headers };
    }
  }
}
