import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { AppConfig } from '../app.config';
import { Company } from '../_models/company';


@Injectable()
export class DorneeanService {
  workcompany: Company;
  company: Subject<Company>;
  company$: Observable<Company>;

  constructor(private http: HttpClient, private config: AppConfig) {
    this.company = new Subject();
    this.company$ = this.company.asObservable();
  }

  publishCompany(company){
    this.workcompany = company;
    this.company.next(company);
  }

  getCompany(){
    return this.workcompany;
  };

  getAll(){
    return this.http.get<Company[]>(this.config.apiUrl + '/dorneeandata', this.jwt());
  }

  getById(_id: string){
    return this.http.get<Company>(this.config.apiUrl + '/dorneeandata/' + _id, this.jwt());
  }

  create(company: Company){
    return this.http.post(this.config.apiUrl + '/dorneeandata', company, this.jwt());
  }

  update(company: Company){
    return this.http.put(this.config.apiUrl + '/dorneeandata/' + company._id, company, this.jwt());
  }

  delete(_id: string){
    return this.http.delete(this.config.apiUrl + '/dorneeandata/' + _id, this.jwt());
  }

  private jwt(){
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .append('Access-Control-Allow-Origin', '*')
    .append('Authorization', 'Bearer '+ currentUser.token)
    .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS');

            return { headers: headers };
    }
  }
}
