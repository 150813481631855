import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { MapsAPILoader, AgmMap } from '@agm/core';
import 'rxjs/add/operator/map';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})


export class ContactComponent implements OnInit {


@ViewChild('agmMap') agmMap : AgmMap;


  constructor() { }

  ngOnInit() {
       this.agmMap.triggerResize();
  }

}
