import { Injectable } from '@angular/core';

@Injectable()
export class DataService {
  data: any;

  constructor(){}

  settempdata(data){
    this.data=data;
  }

  gettempdata(){
    return this.data;
  }

}
