import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { User } from 'app/_models/user';
import { MenuName } from 'app/_models/menuname';
import { MenuModifierService } from 'app/_services/menu-modifier.service';
import { LeftMenu , Category , MenuItem, SubMenuItem} from 'app/_models/leftmenu';

@Component({
  selector: 'app-menu-modify',
  templateUrl: './menu-modify.component.html',
  styleUrls: ['./menu-modify.component.css']
})
export class MenuModifyComponent implements OnInit {
  currentUser: User;
  menunames: any;
  model:any={};
  workmenunames:MenuName[];
  workmenuname: string;

  constructor(private MenuService: MenuModifierService,
              private router: Router) {
                this.workmenunames=[];
                this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                this.MenuService.menunames$.subscribe((menuData) => {
                  this.menunames = menuData;
                  this.assignmenunames(this.menunames);
                });
                this.MenuService.selectedmenuname$.subscribe((name) => {
                  this.workmenuname = name;
                  this.loadworkmenu(name);
                });
               }

  ngOnInit() {
   if(this.currentUser){
      this.workmenuname=this.MenuService.getWorkMenuName();
      console.log(this.workmenuname);
      this.MenuService.getAllMenuNames()
            .subscribe(
              data => {
                       this.assignmenunames(data);
                       this.MenuService.publishMenuNames(data);
                       this.loadworkmenu(data[0]);
                      },
              );
    }else{
      this.router.navigate(['/super-admin-login']);
    }
  }

  assignmenunames(data){
    this.workmenunames=[];
    data.forEach(function(menuname){
      this.workmenunames.push({name: menuname,
                               selected: false});
    }.bind(this));
    console.log(this.workmenunames);
  }

  loadworkmenu(menuname){
    this.MenuService.getWorkMenu(menuname)
          .subscribe(
            menu => {
                     this.MenuService.publishMenu(menu);
                     this.workmenunames.forEach(function(element){
                       if(element.name==menuname){
                         element.selected=true;
                       }else{
                         element.selected=false;
                       }
                     })
                    },
            );
  }

  editmenu(menuname){
    this.MenuService.getWorkMenu(menuname)
            .subscribe(
              menu => {
                       this.MenuService.publishMenu(menu);
                       this.router.navigate(['/super-admin/menu-modify/edit-menu']);
                      },
              );
  }

  deletemenu(menuname){
    this.MenuService.getWorkMenu(menuname)
          .subscribe(
            menu => {
                    this.MenuService.deleteMenu(menu._id)
                        .subscribe(
                          data => {
                                   this.MenuService.getAllMenuNames()
                                        .subscribe(
                                          data => {
                                                   this.assignmenunames(data);
                                                   this.MenuService.publishMenuNames(data);
                                                   this.loadworkmenu(data[0]);
                                                  },
                                          );
                          }
                        )
                    },
            );
  }

}
