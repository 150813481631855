import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SuperUserService } from 'app/_services/superuser.service';
import { SUAuthenticationService } from 'app/_services/suauthentication.service';


@Component({
  moduleId: module.id,
  selector: 'app-super-admin-register',
  templateUrl: './super-admin-register.component.html',
  styleUrls: ['../common-elements/register.component.css']
})
export class SuperAdminRegisterComponent implements OnInit {

  model: any={};
  loading = false;

  constructor(
    private router: Router,
    private userService: SuperUserService,
    private suAuthenticationService: SUAuthenticationService) { }

  register() {
        this.loading = true;
        this.model.role= "superadmin";
        this.userService.create(this.model)
            .subscribe(
                data => {
                    this.suAuthenticationService.login(this.model.username, this.model.password)
                        .subscribe(
                          data => {
                            this.router.navigate(['/super-admin']);
                          },
                          error => {
                            this.loading = false;
                          });

                },
                error => {
                    this.loading = false;
                });
    }

  ngOnInit() {
  }

  gohome(){
    this.router.navigate(['/']);
  }


}
