import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { User } from 'app/_models/user';
import { LeftMenuService } from 'app/_services/leftmenu.service';
import { HeaderService } from 'app/_services/header.service';
import { CurrentUserService } from 'app/_services/currentuser.service';
import { SUAuthenticationService } from 'app/_services/suauthentication.service';


import { LeftMenu , Category , MenuItem, SubMenuItem} from 'app/_models/leftmenu';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

     currentUser: User;
     leftmenu: LeftMenu;
     usertype: string;

     constructor( private MenuService: LeftMenuService,
                  private HeaderService: HeaderService,
                  private UserService: CurrentUserService,
                  private suAuthenticationService: SUAuthenticationService,
                  private router: Router ) {
       this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

     ngOnInit() {
       this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
       if(this.currentUser){
         this.MenuService.getLeftMenu(this.currentUser.role)
               .subscribe(
                 menu => {
                          this.MenuService.publishLeftMenu(menu);
                          this.UserService.publishCurrentUser(this.currentUser);
                          this.HeaderService.publishUserType(this.currentUser.role);
                          if(this.currentUser.role=="dezbusadmin"){
                            this.router.navigate(['/admin']);
                          }else{
                            this.suAuthenticationService.logout();
                            this.router.navigate(['/login']);
                          }
                         },
                 );
        }else{
          this.router.navigate(['/login']);
        }
   }

}
