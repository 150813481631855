import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
     selector: 'app-add-veh-cat-dialogue',
     templateUrl: './add-veh-cat-dialogue.component.html',
     styleUrls: ['./add-veh-cat-dialogue.component.css']
})


export class AddVehCatDialogueComponent {

     categories: string[];
     selectedcategory: string;


     constructor(
          @Inject(MAT_DIALOG_DATA) public data: any,
          public dialogRef: MatDialogRef<AddVehCatDialogueComponent>
     ) {
          console.log(this.data);
          this.categories = data.categories;
          if(data.selectedcategory){
               this.selectedcategory = data.selectedcategory;
          }
     }


     selectCategory(){
          this.dialogRef.close(this.selectedcategory);
     }

     onNoClick(): void {
          this.dialogRef.close();
     }



}
