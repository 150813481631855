import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { AppConfig } from '../app.config';
import { Workdata } from '../_models/workdata';


@Injectable()
export class WorkDataService {
     theworkdata: Workdata;
     workdata: Subject<Workdata>;
     workdata$: Observable<Workdata>;

     constructor(private http: HttpClient, private config: AppConfig) {
          this.workdata = new Subject();
          this.workdata$ = this.workdata.asObservable();
     }

     publishCWorkData(workdata){
          this.theworkdata = workdata;
          this.workdata.next(workdata);
     }

     getWorkData(){
          return this.theworkdata;
     };

     getAll(){
          return this.http.get<Workdata[]>(this.config.apiUrl + '/workdata', this.jwt());
     }

     getById(_id: string){
          return this.http.get<Workdata>(this.config.apiUrl + '/workdata/' + _id, this.jwt());
     }

     create(workdata: Workdata){
          return this.http.post(this.config.apiUrl + '/workdata', workdata, this.jwt());
     }

     update(workdata: Workdata){
          return this.http.put(this.config.apiUrl + '/workdata/' + workdata._id, workdata, this.jwt());
     }

     delete(_id: string){
          return this.http.delete(this.config.apiUrl + '/workdata/' + _id, this.jwt());
     }

     private jwt(){
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          if (currentUser && currentUser.token){
               let headers = new HttpHeaders().set('Content-Type', 'application/json')
               .append('Access-Control-Allow-Origin', '*')
               .append('Authorization', 'Bearer '+ currentUser.token)
               .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS');

               return { headers: headers };
          }
     }
}
