import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Period, Year } from  '../../_models/period';

import * as _moment from 'moment';
import * as locales from 'moment/min/locales';
const moment= _moment;
moment.locale('ro-RO');


@Component({
     selector: 'app-select-year-dialogue',
     templateUrl: './select-year-dialogue.component.html',
     styleUrls: ['./select-year-dialogue.component.css']
})


export class SelectYearDialogueComponent {

     currentyear: string;
     beginyear : number;
     period: Period;
     periods: Period[];
     year: Year;
     years: Year[];
     periodview : boolean;
     yearview: boolean;
     index: number;



     constructor(@Inject(MAT_DIALOG_DATA) public data: any,
     public dialogRef: MatDialogRef<SelectYearDialogueComponent>) {

          console.log(data);
          this.periodview = true;
          this.yearview = false;
          this.currentyear = moment().format('YYYY');
          this.periods = [];

          if(data.instance == 'fromyr'){
               this.instantiateFromYr(data.fromyr, data.toyr);
          }else if(data.instance == 'toyr'){
               this.instantiateToYr(data.fromyr, data.toyr);
          }



     }

     ngOnInit() {
     }

     instantiateFromYr(fromyear, toyear){
          let beginyear = (Math.floor(parseInt(this.currentyear)/10)-5)*10;

          if(toyear){
               var lastdecade = Math.floor(toyear/10)*10;
          }else{
               var lastdecade = Math.floor(parseInt(this.currentyear)/10)*10;
          }

          while(beginyear <= lastdecade){
               let endyear = beginyear + 9
               if(toyear){
                    if(endyear > toyear){
                         endyear = toyear;
                    }
               }else if(endyear > parseInt(this.currentyear))
               endyear = parseInt(this.currentyear)

               let period = { fromyr: beginyear,
                    toyear: endyear,
                    name: beginyear + ' - ' + endyear,
                    selected: false
               }

               if(fromyear && Math.floor(parseInt(fromyear))/10 == beginyear / 10){
                    period.selected = true;
               }

               this.periods.push(period);
               beginyear = beginyear + 10;
          }

          if(fromyear){
               this.preselectYear(fromyear);
          }
     }


     instantiateToYr(fromyear, toyear){

          let initialized = false;
          let beginyear = null;

          if(fromyear){
               beginyear = Math.floor(parseInt(fromyear)/10)*10;
          }else{
               beginyear = (Math.floor(parseInt(this.currentyear)/10)-5)*10;
          }
          let lastdecade = Math.floor(parseInt(this.currentyear)/10)*10;


               while(beginyear <= lastdecade){
                    let period = {} as Period;
                    let endyear = beginyear + 9;
                    if(endyear > parseInt(this.currentyear)) endyear = parseInt(this.currentyear)
                    if(!initialized&&fromyear){
                         period = { fromyr: fromyear,
                              toyear: endyear,
                              name: fromyear + ' - ' + endyear,
                              selected: false
                         }
                         initialized = true;
                    }else{
                         period = { fromyr: beginyear,
                              toyear: endyear,
                              name: beginyear + ' - ' + endyear,
                              selected: false
                         }
                    }

                    if(toyear && Math.floor(parseInt(toyear))/10 == beginyear / 10){
                         period.selected = true;
                    }

                    this.periods.push(period);
                    beginyear = beginyear + 10;
               }

               if(toyear){
                    this.preselectYear(toyear);
               }



     }

     selectPeriod(year){
          console.log(year);
          this.periods.map((period, index)=>{
               if(period.fromyr == year){
                    period.selected = true;
                    this.yearview = true;
                    this.periodview = false;
                    this.period= period;
                    this.index =index;
               }else{
                    period.selected = false;
               }
          })

          this.years = [];
          while (year <= this.period.toyear){
               var yearObj = {name: year, selected: false}
               this.years.push(yearObj);
               year++;
          }
          console.log(this.years);
     }


     selectYear(year){
          this.dialogRef.close(year);
     }

     preselectYear(selectedyear){
          this.yearview = true;
          this.periodview = false;

          this.periods.map((period, index)=>{
               if(Math.floor(selectedyear/10)==Math.floor(period.fromyr/10)){
                    period.selected = true;
                    this.index= index;
                    this.period = period;
                    this.years = [];
                    var year = (Math.floor(selectedyear/10))*10;
                    while(year <= period.toyear){
                         var yearObj = {name: year, selected: false}
                         if(year == selectedyear) yearObj.selected = true;
                         this.years.push(yearObj);
                         year ++
                    }
               }else{
                    period.selected=false;
               }
          })
     }

     periodBack(){
          console.log(this.periods);
          console.log(this.index);
          if(this.index>0) this.index--;
          this.periods.map((period, index)=>{
               if(index == this.index){
                    period.selected = true;
                    this.index= index;
                    this.period = period;
                    this.years = [];
                    var year = period.fromyr;
                    while(year <= period.toyear){
                         var yearObj = {name: year, selected: false}
                         this.years.push(yearObj);
                         year ++
                    }
               }else{
                    period.selected=false;
               }
          })
     }

     periodFwd(){
          if(this.index<this.periods.length-1) this.index++;
          console.log(this.index);
          this.periods.map((period, index)=>{
               if(index == this.index){
                    period.selected = true;
                    this.index= index;
                    this.period = period;
                    this.years = [];
                    var year = period.fromyr;
                    while(year <= period.toyear){
                         var yearObj = {name: year, selected: false}
                         this.years.push(yearObj);
                         year ++
                    }
               }else{
                    period.selected=false;
               }
          })
     }

}
