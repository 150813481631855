import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppUserService } from 'app/_services/appuser.service';


@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {

     users:any=[];
     crt:number;

     constructor(private router: Router,
                 private userService: AppUserService,) { }

     ngOnInit() {
       this.crt=1;
       this.userService.getAll()
       .subscribe(
         data => {
              console.log(data);
                  this.users=data;
                  this.users.map((user)=>{
                    user.crt=this.crt;
                    this.crt++
               })
                 },
         );
     }

     gohome(){
       this.router.navigate(['/super-admin']);
     }

}
