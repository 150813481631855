import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuModifierService } from 'app/_services/menu-modifier.service';

import { LeftMenu , Category , MenuItem, SubMenuItem} from 'app/_models/leftmenu';

@Component({
  selector: 'app-new-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./menubuilder.component.css']
})
export class AddMenuComponent implements OnInit {
  model: any={};
  newmenuname: string;

  constructor(private MenuService: MenuModifierService,
              private router: Router) { }

  ngOnInit() {
  }

  createnewmenu(){
    this.model.menu=this.newmenuname;
    this.model.categories=[];
    this.MenuService.createNewMenu(this.model)
        .subscribe(
            data => {
                  this.MenuService.getAllMenuNames()
                      .subscribe(
                        data => {
                                 console.log(data);
                                 this.MenuService.publishMenuNames(data);
                                 this.router.navigate(['/super-admin/menu-modify']);
                                },
                        );

            },
            error => {
            });
    }

    cancelaction(){
      this.router.navigate(['/super-admin/menu-modify']);
    }
  }
