import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { AppConfig } from '../app.config';
import { SuperUser } from '../_models/superuser';


@Injectable()
export class SuperUserService {

  constructor(private http: HttpClient, private config: AppConfig) {

  }

  getAll(){
    return this.http.get<SuperUser[]>(this.config.apiUrl + '/superusers', this.jwt());
  }

  getById(_id: string){
    return this.http.get<SuperUser>(this.config.apiUrl + '/superusers/' + _id, this.jwt());
  }

  create(user: SuperUser){
    return this.http.post(this.config.apiUrl + '/superusers/register', user, this.jwt());
  }

  update(user: SuperUser){
    return this.http.put(this.config.apiUrl + '/superusers/' + user._id, user, this.jwt());
  }

  delete(_id: string){
    return this.http.delete(this.config.apiUrl + '/superusers/' + _id, this.jwt());
  }


  private jwt(){
       let currentUser = JSON.parse(localStorage.getItem('currentUser'));
       if (currentUser && currentUser.token){
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
            .append('Access-Control-Allow-Origin', '*')
            .append('Authorization', 'Bearer '+ currentUser.token)
            .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS');

            return { headers: headers };
       }
  }
}
