import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { WorkDataService } from 'app/_services/workdata.service';
//
import { Workdata, Proforma, Factura, Chitanta } from 'app/_models/workdata';

@Component({
  selector: 'app-tva-value',
  templateUrl: './tva-value.component.html',
  styleUrls: ['../formstyles.css']
})
export class TvaValueComponent implements OnInit {


      workdata: Workdata;
      loadedworkdata: any={};
      created:boolean;

      workdataForm = new FormGroup({
        tva: new FormControl(),


      });

      constructor(private formBuilder: FormBuilder,
                  private ngZone: NgZone,
                  private router: Router,
                  private workDataService: WorkDataService,
                ) {

                    this.workdata={
                      abo1:null,
                      abo6:null,
                      abo12:null,
                      tva:null,
                      proforme:[{
                        serie: '',
                        beginno: null,
                        endno: null
                      }as Proforma],
                      facturi:[{
                        serie: '',
                        beginno: null,
                        endno: null
                      }as Factura],
                      chitante: [{
                        serie: '',
                        beginno: null,
                        endno: null
                      }as Chitanta]
                    } as Workdata;
                   }

      ngOnInit() {
        this.workDataService.getAll()
        .subscribe(
          data => {
            console.log(data);
            if(data.length==0){
              console.log(data);
              this.created=false;
              this.loadedworkdata=this.workdata;
              console.log(this.loadedworkdata);
            }else{
              this.loadedworkdata = data[0];
              console.log(this.loadedworkdata);
              this.created=true
            }

              this.workdataForm = this.formBuilder.group({
                    tva: [this.loadedworkdata.tva,Validators.required],

                  });
          });
      }

      onSubmit({ value, valid }: { value: any, valid: boolean }) {

        this.workdata = this.loadedworkdata;
        this.workdata.tva = value.tva;


        if(this.created){
          console.log(this.workdata);
          this.workDataService.update(this.workdata)
              .subscribe(
                data => {
                        // this.leftMenuService.assignIndexes("client-data","view-client-data","company-data");
                        },
                error => {
                         });
        } else {
          this.workDataService.create(this.workdata)
              .subscribe(
                data => {
                        // this.leftMenuService.assignIndexes("client-data","view-client-data","company-data");
                        },
                error => {
                         });
        }
      }

      
      gohome(){
        this.router.navigate(['/super-admin']);
      }


}
