import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { AppConfig } from '../app.config';
import { LeftMenu , Category , MenuItem, SubMenuItem, MenuIndexes} from '../_models/leftmenu';


@Injectable()
export class MenuModifierService {

     workmenu: LeftMenu;
     workmenuname: string;
     workmenunames: string[];
     newmenuname: string;
     categoryinit: number;
     menuiteminit: number;
     submenuiteminit: number;

     leftmenu: Subject<LeftMenu>;
     leftmenu$: Observable<LeftMenu>;
     menunames: Subject<string[]>;
     menunames$: Observable<string[]>;
     selectedmenuname: Subject<string>;
     selectedmenuname$: Observable<string>;
     category: Subject<number>;
     category$: Observable<number>;
     menuitem: Subject<number>;
     menuitem$: Observable<number>;
     submenuitem: Subject<number>;
     submenuitem$: Observable<number>;

     hoveredindexes: Subject<MenuIndexes>;
     hoveredindexes$: Observable<MenuIndexes>;

     selectedindexes: Subject<MenuIndexes>;
     selectedindexes$: Observable<MenuIndexes>;

     indexes: any={};



     constructor( private http: HttpClient, private config: AppConfig ) {
          this.leftmenu = new Subject();
          this.menunames = new Subject();
          this.selectedmenuname = new Subject();
          this.category = new Subject();
          this.menuitem = new Subject();
          this.submenuitem = new Subject();

          this.hoveredindexes = new Subject();
          this.selectedindexes = new Subject();

          this.leftmenu$ = this.leftmenu.asObservable();
          this.menunames$ = this.menunames.asObservable();
          this.selectedmenuname$ = this.selectedmenuname.asObservable();
          this.category$ = this.category.asObservable();
          this.menuitem$ = this.menuitem.asObservable();
          this.submenuitem$ = this.submenuitem.asObservable();

          this.hoveredindexes$ = this.hoveredindexes.asObservable();
          this.selectedindexes$ = this.selectedindexes.asObservable();
     }


     getAllMenuNames(){
          return this.http.get<string[]>(this.config.apiUrl + '/appcomponents/getallleftmenus', this.jwt())
     }

     getWorkMenu(menuname: string){
          return this.http.get<LeftMenu>(this.config.apiUrl + '/appcomponents/getleftmenu/' + menuname, this.jwt())
     }

     updateWorkMenu(menu: LeftMenu){
          return this.http.put(this.config.apiUrl + '/appcomponents/' + menu._id, menu, this.jwt());
     }

     deleteMenu(_id: string){
          return this.http.delete(this.config.apiUrl + '/appcomponents/' + _id, this.jwt());
     }

     createNewMenu(newmenu: LeftMenu){
          return this.http.post(this.config.apiUrl + '/appcomponents/createnewmenu', newmenu, this.jwt())
     }

     publishMenuNames(menunames){
          this.workmenunames = menunames;
          this.menunames.next(menunames);
     }

     publishSelectedMenuName(menuname){
          this.workmenuname = menuname;
          this.selectedmenuname.next(menuname);
     }

     publishMenu(menu){
          this.workmenu = menu;
          this.leftmenu.next(menu);
     }

     publishCategory(category){
          this.categoryinit = category;
          this.category.next(category);
     }

     publishMenuItem(category, menuitem){
          this.categoryinit = category;
          this.menuiteminit = menuitem;
          this.category.next(category);
          this.menuitem.next(menuitem);
     }

     publishSubMenuItem(category, menuitem, submenuitem){
          this.categoryinit = category;
          this.menuiteminit = menuitem;
          this.submenuiteminit = submenuitem;
          this.category.next(category);
          this.menuitem.next(menuitem);
          this.submenuitem.next(submenuitem);
     }

     publishHovered(category, menuitem, submenuitem){
          this.indexes={}
          this.indexes.category=category;
          this.indexes.menuitem=menuitem;
          this.indexes.submenuitem=submenuitem;

          this.hoveredindexes.next(this.indexes);

     }

     publishSelected(category, menuitem, submenuitem){
          this.indexes={}
          this.indexes.category=category;
          this.indexes.menuitem=menuitem;
          this.indexes.submenuitem=submenuitem;

          this.selectedindexes.next(this.indexes);
     }

     getWorkMenuName(){
          return this.workmenuname;
     }

     getWorkMenuVariable(){
          return this.workmenu;
     }

     getInitCategory(){
          return this.categoryinit;
     }

     getInitMenuItem(){
          return this.menuiteminit;
     }

     getInitSubMenuItem(){
          return this.submenuiteminit;
     }


     private jwt(){
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          if (currentUser && currentUser.token){
               let headers = new HttpHeaders().set('Content-Type', 'application/json')
               .append('Access-Control-Allow-Origin', '*')
               .append('Authorization', 'Bearer '+ currentUser.token)
               .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS');

               return { headers: headers };
          }
     }
}
