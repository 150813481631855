import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuModifierService } from 'app/_services/menu-modifier.service';

import { LeftMenu , Category , MenuItem, SubMenuItem} from 'app/_models/leftmenu';

@Component({
  selector: 'app-menuscroller',
  templateUrl: './menuscroller.component.html',
  styleUrls: ['./menuscroller.component.css']
})
export class MenuscrollerComponent implements OnInit {

  workmenu: LeftMenu;
  hoveredcategory: number;
  hoveredmenuitem: number;
  hoveredsubmenuitem: number;

  selectedcategory: number;
  selectedmenuitem: number;
  selectedsubmenuitem: number;

  constructor( private MenuService: MenuModifierService,
              private router: Router ) {
                this.MenuService.leftmenu$.subscribe((menuData) => {
                  this.workmenu = menuData;
                });
                this.MenuService.selectedindexes$.subscribe((index) =>{
                  this.selectedcategory = index.category;
                  this.selectedmenuitem = index.menuitem;
                  this.selectedsubmenuitem = index.submenuitem;

                  this.selectmenu(this.selectedcategory, this.selectedmenuitem, this.selectedsubmenuitem);
                });
                this.MenuService.hoveredindexes$.subscribe((index) =>{
                  this.hoveredcategory = index.category;
                  this.hoveredmenuitem = index.menuitem;
                  this.hoveredsubmenuitem = index.submenuitem;

                  this.hovermenu(this.hoveredcategory, this.hoveredmenuitem, this.hoveredsubmenuitem);
                });
               }

  ngOnInit() {
  }

  sethovercomponents(category, menuitem, submenuitem){
    this.MenuService.publishHovered(category, menuitem, submenuitem);
  }

  clearhovercomponents(){
    var category=null;
    var menuitem=null;
    var submenuitem=null;
    this.MenuService.publishHovered(category, menuitem, submenuitem);
  }

  setselectcomponents(category, menuitem, submenuitem){
    this.MenuService.publishSelected(category, menuitem, submenuitem);
  }

  selectmenu(categoryindex, menuitemindex, submenuitemindex){
    this.workmenu.categories.forEach(function(category){
      category.selected=false;
      category.menuitems.forEach(function(menuitem){
        menuitem.selected=false;
        menuitem.submenuitems.forEach(function(submenuitem){
          submenuitem.selected=false;
        });
      });
    });
    if(typeof categoryindex!=="undefined"&& typeof menuitemindex!=="undefined"&& typeof submenuitemindex!=="undefined"){
      this.workmenu.categories.forEach(function(category, catindex){
        category.selected=false;
        if(catindex==categoryindex){
          category.menuitems.forEach(function(menuitem, mitemindex){
            menuitem.selected=false;
            if(mitemindex==menuitemindex){
              menuitem.submenuitems.forEach(function(submenuitem, smitemindex){
                if(smitemindex==submenuitemindex){
                  submenuitem.selected=true;
                }else{
                  submenuitem.selected=false
                };
              });
            };
          });
        };
      });
    } else if(typeof categoryindex!=="undefined"&& typeof menuitemindex!=="undefined"&& typeof submenuitemindex=="undefined"){
      this.workmenu.categories.forEach(function(category, catindex){
        category.selected=false;
        if(catindex==categoryindex){
          category.menuitems.forEach(function(menuitem, mitemindex){
            if(mitemindex==menuitemindex){
              menuitem.selected=true;
            }else{
              menuitem.selected=false
            };
          });
        };
      });
    } else if(typeof categoryindex!=="undefined"&& typeof menuitemindex!=="undefined"&& typeof submenuitemindex=="undefined"){
      this.workmenu.categories.forEach(function(category, catindex){
        if(categoryindex==catindex){
          category.selected=true;
        }else{
          category.selected=false
        }
      });
    };
  };


  hovermenu(categoryindex, menuitemindex, submenuitemindex){
    this.workmenu.categories.forEach(function(category){
      category.hovered=false;
      category.menuitems.forEach(function(menuitem){
        menuitem.hovered=false;
        menuitem.submenuitems.forEach(function(submenuitem){
          submenuitem.hovered=false;
        });
      });
    });
    if(typeof categoryindex!=="undefined"&& typeof menuitemindex!=="undefined"&& typeof submenuitemindex!=="undefined"){
      this.workmenu.categories.forEach(function(category, catindex){
        category.hovered=false;
        if(catindex==categoryindex){
          category.menuitems.forEach(function(menuitem, mitemindex){
            menuitem.hovered=false;
            if(mitemindex==menuitemindex){
              menuitem.submenuitems.forEach(function(submenuitem, smitemindex){
                if(smitemindex==submenuitemindex){
                  submenuitem.hovered=true;
                }else{
                  submenuitem.hovered=false
                };
              });
            };
          });
        };
      });
    } else if(typeof categoryindex!=="undefined"&& typeof menuitemindex!=="undefined"&& typeof submenuitemindex=="undefined"){
      this.workmenu.categories.forEach(function(category, catindex){
        category.hovered=false;
        if(catindex==categoryindex){
          category.menuitems.forEach(function(menuitem, mitemindex){
            if(mitemindex==menuitemindex){
              menuitem.hovered=true;
            }else{
              menuitem.hovered=false
            };
          });
        };
      });
    } else if(typeof categoryindex!=="undefined"&& typeof menuitemindex!=="undefined"&& typeof submenuitemindex=="undefined"){
      this.workmenu.categories.forEach(function(category, catindex){
        if(categoryindex==catindex){
          category.hovered=true;
        }else{
          category.hovered=false
        }
      });
    }
  }

}
