import { AbstractControl } from '@angular/forms';

export class CheckUniqueInArrayValidation {

 static MatchElements(array: string[]) {

        return (AC:AbstractControl) =>{
        let currentvalue = AC.get('value').value;

               if(array.indexOf(currentvalue)) {
                   AC.get('value').setErrors( {MatchElements: true} )
               } else {
                   return null
               }
   }
}

}
