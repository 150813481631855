import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin.component';
import { AppElementsComponent } from './admin-components/app-elements/app-elements.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { AddVehicleComponent } from './vehicles/add-vehicle/add-vehicle.component';
import { PartsComponent } from './parts/parts.component';
import { AddPartComponent } from './parts/add-part/add-part.component';


const AdminRoutes: Routes = [

     {
          path:'admin',
          component: AdminComponent,
          children: [
               {
                    path:'appelements',
                    component: AppElementsComponent
               },
               {
                    path:'vehicles',
                    component: VehiclesComponent,
                    children: [
                         {
                              path:'add-vehicle',
                              component: AddVehicleComponent
                         }
                    ]

               },
               {
                    path:'parts',
                    component: PartsComponent,
                    children: [
                         {
                              path:'add-part',
                              component: AddPartComponent
                         }
                    ]

               }

          ]
     }
]

          export const AdminRouting: ModuleWithProviders = RouterModule.forChild(AdminRoutes);
