import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SuperAdminLoginComponent } from './super-admin-login.component';
import { SuperAdminRegisterComponent } from './super-admin-register.component';
import { SuperAdminComponent } from './super-admin.component';
import { LandingPageComponent } from './super-admin-components/landing-page.component';
import { MenuModifyComponent } from './super-admin-components/menu-modify-component/menu-modify.component';
import { MenuModifyMainPageComponent } from './super-admin-components/menu-modify-components/menu-modify-main-page.component';
import { AddMenuComponent } from './super-admin-components/menu-modify-components/add-menu.component';
import { EditMenuComponent } from './super-admin-components/menu-modify-components/edit-menu.component';
import { AddCategoryComponent } from './super-admin-components/menu-modify-components/add-category.component';
import { EditCategoryComponent } from './super-admin-components/menu-modify-components/edit-category.component';
import { AddMenuitemComponent } from './super-admin-components/menu-modify-components/add-menuitem.component';
import { EditMenuitemComponent } from './super-admin-components/menu-modify-components/edit-menuitem.component';
import { AddSubmenuitemComponent } from './super-admin-components/menu-modify-components/add-submenuitem.component';
import { EditSubmenuitemComponent } from './super-admin-components/menu-modify-components/edit-submenuitem.component';
import { EditLinkComponent } from './super-admin-components/menu-modify-components/edit-link.component';
import { LinkTableComponent } from './super-admin-components/link-table-component/link-table.component';
import { AdminsListComponent } from './super-admin-components/admins-list-component/admins-list.component';
import { NewAdminComponent } from './super-admin-components/new-admin-component/new-admin.component';
import { DorneeanDataComponent } from './dorneean-components/dorneean-data-component/dorneean-data.component';
import { WorkDataComponent } from './work-data-components/work-data.component';
import { PricingPlanComponent } from './work-data-components/pricing-plan/pricing-plan.component';
import { TvaValueComponent } from './work-data-components/tva-value/tva-value.component';
import { ProformeComponent } from './work-data-components/proforme/proforme.component';
import { InvoicesComponent } from './work-data-components/invoices/invoices.component';
import { ReceiptsComponent } from './work-data-components/receipts/receipts.component';
import { DezbusUsersComponent } from './dezbus-users.components/dezbus-users.component';
import { CreateUserComponent } from './dezbus-users.components/create-user/create-user.component';
import { UsersListComponent } from './dezbus-users.components/users-list/users-list.component';

const superAdminRoutes: Routes = [
     {
          path: 'super-admin-login',
          component: SuperAdminLoginComponent
     },
     {
          path: 'super-admin-register',
          component: SuperAdminRegisterComponent
     },
     {
          path:'super-admin',
          component: SuperAdminComponent,
          children: [
               {
                    path:'',
                    component: LandingPageComponent
               },
               {
                    path:'menu-modify',
                    component: MenuModifyComponent,
                    children: [
                         {
                              path:'',
                              component: MenuModifyMainPageComponent
                         },
                         {
                              path:'add-menu',
                              component: AddMenuComponent
                         },
                         {
                              path:'edit-menu',
                              component: EditMenuComponent
                         },
                         {
                              path:'add-category',
                              component: AddCategoryComponent
                         },
                         {
                              path:'edit-category',
                              component: EditCategoryComponent
                         },
                         {
                              path:'add-menuitem',
                              component: AddMenuitemComponent
                         },
                         {
                              path:'edit-menuitem',
                              component: EditMenuitemComponent
                         },
                         {
                              path:'add-submenuitem',
                              component: AddSubmenuitemComponent
                         },
                         {
                              path:'edit-submenuitem',
                              component: EditSubmenuitemComponent
                         },
                         {
                              path:'edit-link',
                              component: EditLinkComponent
                         }
                    ]
               },
               {
                    path:'link-table',
                    component: LinkTableComponent
               },
               {
                    path:'list',
                    component: AdminsListComponent
               },
               {
                    path:'new-admin',
                    component: NewAdminComponent
               },
               {
                    path:'dorneean-data',
                    component: DorneeanDataComponent
               },
               {
               path:'dezbus-users',
               component: DezbusUsersComponent,
               children: [
                    {
                         path:'',
                         component: UsersListComponent
                    },
                    {
                         path:'users-list',
                         component: UsersListComponent
                    },
                    {
                         path:'create-user',
                         component: CreateUserComponent
                    }
               ]
          },
          {
               path:'work-data',
               component: WorkDataComponent,
               children: [
                    {
                         path:'',
                         component: PricingPlanComponent
                    },
                    {
                         path:'pricing',
                         component: PricingPlanComponent
                    },
                    {
                         path:'tva',
                         component: TvaValueComponent
                    },
                    {
                         path:'proformas',
                         component: ProformeComponent
                    },
                    {
                         path:'invoices',
                         component: InvoicesComponent
                    },
                    {
                         path:'receipts',
                         component: ReceiptsComponent
                    }
               ]
          }

     ]
},

]

export const superAdminRoutig: ModuleWithProviders = RouterModule.forChild(superAdminRoutes);
