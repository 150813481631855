import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { ObservableMedia, MediaChange } from '@angular/flex-layout';
import { map, takeUntil, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { AppConfig } from '../app.config';
import { AppElementsService } from '../_services/appelements.service';
import { VehiclesService } from '../_services/vehicles.service';
import { PartsService } from '../_services/parts.service';
import { PictureService } from '../_services/picture.service';
import { NomenclatorService } from '../_services/nomenclator.service';

import { PictureCollection, PictureGallery, PictureData} from '../_models/picturecollection';
import { AppElement } from '../_models/appelement';
import { Vehicle } from '../_models/vehicle';
import { Part } from '../_models/part';
import { NomenclatorItem } from '../_models/nomenclator';

import { AddVehCatDialogueComponent } from '../dialogues/add-veh-cat-dialogue/add-veh-cat-dialogue.component';
import { AddVehMadeDialoueComponent } from '../dialogues/add-veh-made-dialoue/add-veh-made-dialoue.component';
import { AddVehModelDialoueComponent } from '../dialogues/add-veh-model-dialoue/add-veh-model-dialoue.component';
import { SelectYearDialogueComponent } from '../dialogues/select-year-dialogue/select-year-dialogue.component';
import { AddPartCatDialogueComponent } from '../dialogues/add-part-cat-dialogue/add-part-cat-dialogue.component';
import { AddPartSubcatDialogueComponent } from '../dialogues/add-part-subcat-dialogue/add-part-subcat-dialogue.component';


@Component({
     selector: 'app-landingpage',
     templateUrl: './landingpage.component.html',
     styleUrls: ['./landingpage.component.css']
})


export class LandingpageComponent implements OnInit {

     searchname: string;
     vehicles: Vehicle[];
     parts: Part[];
     vehiclequery: any;
     partsquery: any;
     fakeparts: number;
     fakearray: number[];

     appelements: any;
     partscategories: any;
     selectedsubcategory:string;
     selectedcategoryname: string;
     selectedmodel: string;
     selectedfromyr: number;
     selectedtoyr: number;
     vehcats: string[];
     vehiclecats: any;
     vehiclesubcats: any[];
     manufacturers: string[];
     models: string[];
     partscats: {category: string, selected:boolean}[];
     thecode: string;

     mediawatcher: Subscription;
     activeMedia:string;
     mediaports: {
          mobile: boolean;
          tablet: boolean;
          desktop: boolean;
     }


     mainmenuitems: {
          vehiclesselected: boolean,
          partsselected: boolean,
          homeselected: boolean,
          aboutselected: boolean,
          contactselected: boolean,
          searchselected: boolean,
     }

     searchmarks: {
          vehicles: boolean,
          parts: boolean,
          code: boolean
     }

     addPartnerForm = new FormGroup({
          denumire: new FormControl(),
          email: new FormControl(),
          cui: new FormControl(),
          type: new FormControl(),
          localitate: new FormControl(),
          adresa: new FormControl(),
     });

     vehicleParams: {
          vehcat: string;
          vehmade: string;
          vehmodel: string;
          fromyear:string;
          toyear: string;
     }


     routeParams: {
          vehcat: string;
          vehmade: string;
          vehmodel: string;
          vehfromyr: number;
          vehtoyr: number;
          partcat: string;
          partsubcat: string;
          partname: string;
     }

     searchPartForm = new FormGroup({
          categorie: new FormControl(),
          subcategorie: new FormControl(),
          model: new FormControl(),
          fromyr: new FormControl(),
          toyr: new FormControl(),
          partcat: new FormControl(),
          partsubcat: new FormControl(),
          denumire: new FormControl(),
     });


     constructor(
          private router: Router,
          private formBuilder: FormBuilder,
          private activatedRoute:ActivatedRoute,
          private elementsService: AppElementsService,
          private vehiclesService: VehiclesService,
          private partsService: PartsService,
          private nomenclatorService: NomenclatorService,
          private config: AppConfig,
          private pictureService: PictureService,
          private sanitization: DomSanitizer,
          public media: ObservableMedia,
          public dialog: MatDialog
     ) {


          this.mainmenuitems = {
               vehiclesselected: false,
               partsselected: false,
               homeselected: false,
               aboutselected: false,
               contactselected: false,
               searchselected: false,
          }

          this.searchmarks = {
               vehicles: true,
               parts: false,
               code: false
          }

          this.mediaports={
               desktop:false,
               tablet: false,
               mobile: false
          }

          this.vehicleParams = {
               vehcat: '',
               vehmade: '',
               vehmodel: '',
               fromyear: '',
               toyear: '',
          }

          this.routeParams = {
               vehcat: '',
               vehmade: '',
               vehmodel: '',
               vehfromyr: null,
               vehtoyr: null,
               partcat: '',
               partsubcat: '',
               partname: ''
          }

          this.addPartnerForm = this.formBuilder.group({
               denumire: ['', [Validators.required, Validators.minLength(3)]],
               email: ['', [Validators.required, Validators.minLength(3)]],
               cui: ['', [Validators.required, Validators.minLength(1)]],
               type: ['',[Validators.required, Validators.minLength(4)]],
               localitate: ['', [Validators.required, Validators.minLength(1)]],
               adresa: ['', [Validators.required, Validators.minLength(1)]],

          });


          this.assignsearchmatks();
          this.getAppElements();

     }

     ngOnInit() {
          this.initializeVehicleQuery();

          this.mediawatcher = this.media.subscribe((change: MediaChange)=>{
               console.log(change);
               if(change.mqAlias == "lg" || change.mqAlias=="xl"){
                    this.assignviewport('desktop');
               }else if(change.mqAlias == "md" || change.mqAlias=="sm"){
                    this.assignviewport('tablet');
               }else if(change.mqAlias == "xs"){
                    this.assignviewport("mobile")
               }
               if(this.mediaports.mobile){
                    this.router.navigate(["main/home"])
               }
          });
     }

     getAppElements(){
          this.elementsService.getAll()
          .subscribe(
               (appelements)=>{
                    this.vehcats=[];
                    this.partscats=[];
                    this.appelements=appelements[0];
                    this.vehiclecats={};
                    this.vehiclecats = this.appelements.vehicle_categories;
                    this.partscategories = this.appelements.parts_categories;
                    this.vehiclecats.map((category)=>{
                         this.vehcats.push(category.category);
                    })
                    this.partscategories.map((category)=>{
                         this.partscats.push({category: category.category, selected: false});
                    })
               }
          )
     }

     assignviewport(view: string){
          for(var viewport in this.mediaports){
               if(viewport == view){
                    this.mediaports[viewport] = true;
               }else{
                    this.mediaports[viewport] = false;
               }
          }

          console.log(this.mediaports);
     }

     initializeVehicleQuery(){
          this.vehiclequery = {};
          this.vehiclequery.query = {};
          this.vehiclequery.sort={updated_at: -1};
          this.vehiclequery.pagination={skip: 0, limit: 5};
          this.partsquery = {};
          this.partsquery.query = {};
          this.partsquery.sort={updated_at: -1};
          this.partsquery.pagination={skip: 0, limit: 5};

          this.vehiclesService.getByQuery(this.vehiclequery)
          .subscribe((data)=>{
               this.vehicles=data.vehicles;
               this.vehicles.map((vehicle)=>{
                    this.organizeVehicle(vehicle);
               })
               console.log(this.vehicles);
          })

          this.partsService.getByQuery(this.partsquery)
          .subscribe((data)=>{
               this.parts = data.parts;
               if(this.parts.length%5!==0){
                    this.fakearray=[];
                    this.fakeparts = 5 - (this.parts.length%5);
                    for(let i=0; i<this.fakeparts; i++){
                         this.fakearray.push(1);
                    }

               }
               this.parts.map((part)=>{
                    this.organizePart(part);
               })
               console.log(this.parts);
          })
     }

     generateVehDescription(vehicle){
          var vehicledefinition = vehicle.marca + ' ' + vehicle.model;
          var vehicletype = '';

          if(vehicle.tip){
               vehicledefinition = vehicledefinition + ' ' + vehicle.tip;
          }
          if(vehicle.fabricatie){
               vehicledefinition = vehicledefinition + ' fabricat in anul ' + vehicle.fabricatie;
          }
          if(vehicle.km){
               vehicledefinition = vehicledefinition + ', rulaj ' + vehicle.km + ' km'
          }

          if(vehicle.categorie == "Autocare") vehicletype="autocar ";
          if(vehicle.categorie == "Camioane") vehicletype="camion ";
          if(vehicle.categorie == "Autoutilitare") vehicletype="autoutilitara ";
          if(vehicle.categorie == "Microbuze") vehicletype="microbuz ";
          if(vehicle.categorie == "Autoturisme") vehicletype="autoturism ";


          vehicle.descriere = "Dezmembram " + vehicletype + vehicledefinition + '. Mentionam ca toate piesele listate pentru acest autovehicul au fost verificate in prealabil de specialistii nostri. In cazul in care piesa cautata nu apare in lista nu ezitati sa ne contactati pentru verificarea disponibilitatii acesteia.';

     }


     organizeVehicle(vehicle){


          vehicle.pictures.map((picture)=>{
               picture.fullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
               if(picture.mainpicture){
                    picture.selected = true;
                    vehicle.mainpicturefullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
                    vehicle.hasmainpicture = true;
                    vehicle.fullpathloaded = true;
               }
          })

          if(!vehicle.descriere){
               this.generateVehDescription(vehicle);
          }
     }

     organizePart(part){


          part.pictures.map((picture)=>{
               picture.fullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
               if(picture.mainpicture){
                    picture.selected = true;
                    part.mainpicturefullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
                    part.hasmainpicture = true;
                    part.fullpathloaded = true;
               }
          })

          if(!part.descriere){
               this.generatePartDescription(part);
          }
     }

     generatePartDescription(part){
          if(part.vehcat == "Autocare") part.viewcat="autocar ";
          if(part.vehcat == "Camioane") part.viewcat="camion ";
          if(part.vehcat == "Autoutilitare") part.viewcat="autoutilitara ";
          if(part.vehcat == "Microbuze") part.viewcat="microbuz ";
          if(part.vehcat == "Autoturisme") part.viewcat="autoturism ";

          part.descriere = "Vindem din dezmembrari " + part.denumire.toLowerCase() + " pentru " + part.viewcat + ' ' + part.marca + ' ' + part.model + ' ' + part.tip + '. Mentionam ca toate piesele comercializate au fost verificate in prealabil de specialistii nostri.'
     }

     assignsearchmatks(){
          for( let mark in this.searchmarks){
               if(this.searchmarks[mark] && mark == "vehicles") this.searchname = "Cauta vehicule"
               else if(this.searchmarks[mark] && mark == "parts") this.searchname = " Cauta piese"
               else if(this.searchmarks[mark] && mark == "code") this.searchname = "Cauta dupa cod"
          }
     }


     selectMainMenuItem(itemselected){

     }

     selectsearchtype(type){

     }


     openSelectVehCatDialog(): void {
         let addVehCatDialogRef = this.dialog.open(AddVehCatDialogueComponent, {
           width: '15vw',
           height: '30vh',
           position:{top: '30vh', right:"25vh"},
           data: {
                categories: this.vehcats
           }
         });


         addVehCatDialogRef.afterClosed().subscribe(result => {
              this.vehicleParams.vehcat = result;
              this.manufacturers = [];
              this.vehiclecats.map((category)=>{
                   if(category.category == this.vehicleParams.vehcat){
                        this.vehiclesubcats = category.subcategories;
                        console.log(this.vehiclesubcats);
                        this.vehiclesubcats.map((subcategory)=>{
                             this.manufacturers.push(subcategory.marca);
                        })
                   };
              })

         })
     }




     openSelectVehMadeDialog(): void {

          let data= {
               subcategories: this.manufacturers,
               subcategory: ''
          }
          if(this.selectedsubcategory){
               data.subcategory = this.vehicleParams.vehmade;
          }
         let addVehMadeDialogRef = this.dialog.open(AddVehMadeDialoueComponent, {
              width: '15vw',
             height: '30vh',
             position:{top: '33vh', right:"25vh"},
           data: data

         });


         addVehMadeDialogRef.afterClosed().subscribe(result => {
              this.vehicleParams.vehmade = result;
              this.models = [];
              this.vehiclesubcats.map((subcategory)=>{
                   if(this.vehicleParams.vehmade == subcategory.marca)
                   this.models=subcategory.models
              })
              console.log(this.selectedsubcategory);
         })
     }

     openSelectVehModelDialog(): void {
         let data={
              models: this.models,
              model: ''
         }
         if(this.vehicleParams.vehmodel){
              data.model = this.vehicleParams.vehmodel;
         }
         let addVehModelDialogRef = this.dialog.open(AddVehModelDialoueComponent, {
             width: '15vw',
             height: '30vh',
             position:{top: '36vh', right:"25vh"},
           data: data
         });


         addVehModelDialogRef.afterClosed().subscribe(result => {
              this.vehicleParams.vehmodel = result;
         })
     }

     openSelectFromYrDialog(): void {
         let selectFromYrDialogRef = this.dialog.open(SelectYearDialogueComponent, {
           width: '15vw',
           height: '35vh',
           panelClass: 'no-padding-dialog',
           position:{top: '40vh', right:"17vw"},
           data: {
                fromyr: this.selectedfromyr,
                toyr: this.selectedtoyr,
                instance: 'fromyr'
           }
         });


         selectFromYrDialogRef.afterClosed().subscribe(result => {
              this.selectedfromyr = result;
         })
     }

     openSelectToYrDialog(): void {
          console.log('test')
         let selectToYrDialogRef = this.dialog.open(SelectYearDialogueComponent, {
           width: '15vw',
           height: '35vh',
           panelClass: 'no-padding-dialog',
           position:{top: '40vh', right:"10vw"},
           data: {
                fromyr: this.selectedfromyr,
                toyr: this.selectedtoyr,
                instance: 'toyr'
           }
         });


         selectToYrDialogRef.afterClosed().subscribe(result => {
              this.selectedtoyr = result;
         })
     }

     searchItems(){
          if(this.searchmarks.vehicles){

          }
     }

}
