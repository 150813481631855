import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { User } from 'app/_models/user';
import { MenuName } from 'app/_models/menuname';
import { MenuModifierService } from 'app/_services/menu-modifier.service';
import { LeftMenu , Category , MenuItem, SubMenuItem} from 'app/_models/leftmenu';

@Component({
  selector: 'app-link-table',
  templateUrl: './link-table.component.html',
  styleUrls: ['./link-table.component.css']
})


export class LinkTableComponent implements OnInit {
  currentUser: User;
  menunames: any;
  model:any={};
  flattenmenu: any=[];
  flattenelement: any={};
  workmenu: LeftMenu;
  workmenunames:MenuName[];
  workmenuname: string;

  hoveredcategory: number;
  hoveredmenuitem: number;
  hoveredsubmenuitem: number;

  selectedcategory: number;
  selectedmenuitem: number;
  selectedsubmenuitem: number;



  constructor(private MenuService: MenuModifierService,
              private router: Router) {
                this.workmenunames=[];
                this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                this.MenuService.menunames$.subscribe((menuData) => {
                  this.menunames = menuData;
                  this.assignmenunames(this.menunames);
                });
                this.MenuService.selectedmenuname$.subscribe((name) => {
                  this.workmenuname = name;
                  this.loadworkmenu(name);
                });
                this.MenuService.leftmenu$.subscribe((menuData) => {
                  this.workmenu = menuData;
                  this.makeflattenmenu();
                });
                this.MenuService.selectedindexes$.subscribe((index) =>{
                  this.selectedcategory = index.category;
                  this.selectedmenuitem = index.menuitem;
                  this.selectedsubmenuitem = index.submenuitem;

                  this.selectmenu(this.selectedcategory, this.selectedmenuitem, this.selectedsubmenuitem);
                });
                this.MenuService.hoveredindexes$.subscribe((index) =>{
                  this.hoveredcategory = index.category;
                  this.hoveredmenuitem = index.menuitem;
                  this.hoveredsubmenuitem = index.submenuitem;

                  this.hovermenu(this.hoveredcategory, this.hoveredmenuitem, this.hoveredsubmenuitem);
                });

               }

  ngOnInit() {
   if(this.currentUser){
      this.workmenuname=this.MenuService.getWorkMenuName();
      this.MenuService.getAllMenuNames()
            .subscribe(
              data => {
                       this.assignmenunames(data);
                       this.MenuService.publishMenuNames(data);
                       this.loadworkmenu(data[0]);
                      },
              );
    }else{
      this.router.navigate(['/super-admin-login']);
    }
  }

  assignmenunames(data){
    this.workmenunames=[];
    data.forEach(function(menuname){
      this.workmenunames.push({name: menuname,
                               selected: false});
    }.bind(this));
  }

  loadworkmenu(menuname){
    this.MenuService.getWorkMenu(menuname)
          .subscribe(
            menu => {
                     this.MenuService.publishMenu(menu);
                     this.workmenunames.forEach(function(element){
                       if(element.name==menuname){
                         element.selected=true;
                       }else{
                         element.selected=false;
                       }
                     })
                    },
            );
  }

  makeflattenmenu(){
    this.flattenmenu=[];
    this.workmenu.categories.forEach(function(category, categoryindex){
      this.flattenelement={};
      this.flattenelement.category = "<- "+ category.name + " ->";
      this.flattenelement.categoryindex = categoryindex;
      this.flattenelement.link = category.link;
      this.flattenelement.hovered =false;
      this.flattenelement.selected =false;
      this.flattenmenu.push(this.flattenelement);
      category.menuitems.forEach(function(menuitem, menuitemindex){
        this.flattenelement={};
        this.flattenelement.category = "<- "+ category.name + " ->";
        this.flattenelement.menuitem = "<- "+ menuitem.state + " ->";
        this.flattenelement.categoryindex = categoryindex;
        this.flattenelement.menuitemindex = menuitemindex;
        this.flattenelement.link = menuitem.link;
        this.flattenelement.hovered =false;
        this.flattenelement.selected =false;
        this.flattenmenu.push(this.flattenelement);
        menuitem.submenuitems.forEach(function(submenuitem, submenuitemindex){
          this.flattenelement={};
          this.flattenelement.category = "<- "+ category.name + " ->";
          this.flattenelement.menuitem = "<- "+ menuitem.state + " ->";
          this.flattenelement.submenuitem = "<- "+ submenuitem.query + " ->";
          this.flattenelement.categoryindex = categoryindex;
          this.flattenelement.menuitemindex = menuitemindex;
          this.flattenelement.submenuitemindex = submenuitemindex;
          this.flattenelement.link = submenuitem.link;
          this.flattenelement.hovered =false;
          this.flattenelement.selected =false;
          this.flattenmenu.push(this.flattenelement);
        }.bind(this));
      }.bind(this));
    }.bind(this));
  }

  hovermenurow(category, menuitem, submenuitem){
    this.MenuService.publishHovered(category, menuitem, submenuitem);
    this.hovermenu(category, menuitem, submenuitem);
  }

  hovermenu(category, menuitem, submenuitem){
      this.flattenmenu.forEach(function(element){
        if(typeof category!=="undefined"&& typeof menuitem!=="undefined"&& typeof submenuitem!=="undefined"){
          if(element.categoryindex==category && element.menuitemindex==menuitem && element.submenuitemindex==submenuitem){
            element.hovered=true;
          }else{
            element.hovered=false;
          }
        }else if(typeof category!=="undefined" && typeof menuitem!=="undefined"&& typeof submenuitem=="undefined"){
          if(element.categoryindex==category && element.menuitemindex==menuitem && typeof element.submenuitemindex=="undefined"){
            element.hovered=true;
          }else{
            element.hovered=false;
          }
        }else if(typeof category!=="undefined" && typeof menuitem=="undefined" && typeof submenuitem=="undefined"){
          if(element.categoryindex==category && typeof element.menuitemindex=="undefined" && typeof element.submenuitemindex=="undefined"){
            element.hovered=true;
          }else{
            element.hovered=false;
          }
      }
    });
  }

  selectmenurow(category, menuitem, submenuitem){
    this.MenuService.publishSelected(category, menuitem, submenuitem);
    this.selectmenu(category, menuitem, submenuitem)
  }

  selectmenu(category, menuitem, submenuitem){
      this.flattenmenu.forEach(function(element){
        if(typeof category!=="undefined"&& typeof menuitem!=="undefined"&& typeof submenuitem!=="undefined"){
          if(element.categoryindex==category && element.menuitemindex==menuitem && element.submenuitemindex==submenuitem){
            element.selected=true;
          }else{
            element.selected=false;
          }
        }else if(typeof category!=="undefined" && typeof menuitem!=="undefined"&& typeof submenuitem=="undefined"){
          if(element.categoryindex==category && element.menuitemindex==menuitem && typeof element.submenuitemindex=="undefined"){
            element.selected=true;
          }else{
            element.selected=false;
          }
        }else if(typeof category!=="undefined" && typeof menuitem=="undefined" && typeof submenuitem=="undefined"){
          if(element.categoryindex==category && typeof element.menuitemindex=="undefined" && typeof element.submenuitemindex=="undefined"){
            element.selected=true;
          }else{
            element.selected=false;
          }
      }
    });
  }

  savelink(category, menuitem, submenuitem, link){
    console.log(category, menuitem, submenuitem);
    if(typeof category!=="undefined"&& typeof menuitem!=="undefined"&& typeof submenuitem!=="undefined"){
      this.workmenu.categories[category].menuitems[menuitem].submenuitems[submenuitem].link = link;
      this.workmenu.categories[category].menuitems[menuitem].submenuitems[submenuitem].hovered = false;
      this.workmenu.categories[category].menuitems[menuitem].submenuitems[submenuitem].selected = false;
      this.MenuService.updateWorkMenu(this.workmenu)
      .subscribe(
          data => {
                this.MenuService.getWorkMenu(this.workmenu.menu)
                    .subscribe(
                      data => {
                               this.MenuService.publishMenu(data);
                              },
                      );

          },
          error => {
          });

    } else if(typeof category!=="undefined"&& typeof menuitem!=="undefined"&& typeof submenuitem=="undefined"){
        this.workmenu.categories[category].menuitems[menuitem].link = link;
        this.workmenu.categories[category].menuitems[menuitem].hovered = false;
        this.workmenu.categories[category].menuitems[menuitem].selected = false;
        this.MenuService.updateWorkMenu(this.workmenu)
        .subscribe(
            data => {
                  this.MenuService.getWorkMenu(this.workmenu.menu)
                      .subscribe(
                        data => {
                                 this.MenuService.publishMenu(data);
                                },
                        );

            },
            error => {
            });

    } else if(typeof category!=="undefined"&& typeof menuitem=="undefined"&& typeof submenuitem=="undefined"){
        this.workmenu.categories[category].link = link;
        this.workmenu.categories[category].hovered = false;
        this.workmenu.categories[category].selected = false;
        this.MenuService.updateWorkMenu(this.workmenu)
        .subscribe(
            data => {
                  this.MenuService.getWorkMenu(this.workmenu.menu)
                      .subscribe(
                        data => {
                                 this.MenuService.publishMenu(data);
                                },
                        );

            },
            error => {
            });
    };
  }

}
