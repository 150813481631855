import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { AppConfig } from '../app.config';
import { Company } from '../_models/company';


@Injectable()
export class CompanyService {
     workcompany: Company;
     tempcompany: Company;
     company: Subject<Company>;
     company$: Observable<Company>;

     constructor(private http: HttpClient, private config: AppConfig) {
          this.company = new Subject();
          this.company$ = this.company.asObservable();
     }

     publishCompany(company){
          this.workcompany = company;
          this.company.next(company);
     }

     tempstorecompany(company){
          this.tempcompany = company;
     }

     gettempcompany(){
          return this.tempcompany;
     }

     createcompfromtemp(){
          this.create(this.tempcompany);
     }

     getCompany(){
          return this.workcompany;
     };

     getAll(){
          return this.http.get<Company[]>(this.config.apiUrl + '/companydata', this.jwt());
     }

     getById(_id: string){
          return this.http.get<Company>(this.config.apiUrl + '/companydata/' + _id, this.jwt());
     }

     create(company: Company){
          return this.http.post(this.config.apiUrl + '/companydata', company, this.jwt());
     }

     update(company: Company){
          return this.http.put(this.config.apiUrl + '/companydata/' + company._id, company, this.jwt());
     }

     delete(_id: string){
          return this.http.delete(this.config.apiUrl + '/companydata/' + _id, this.jwt());
     }

     private jwt(){
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          if (currentUser && currentUser.token){
               let headers = new HttpHeaders().set('Content-Type', 'application/json')
               .append('Access-Control-Allow-Origin', '*')
               .append('Authorization', 'Bearer '+ currentUser.token)
               .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS');

               return { headers: headers };
          }
     }
}
