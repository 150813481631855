import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuModifierService } from 'app/_services/menu-modifier.service';
import { LeftMenu , Category , MenuItem, SubMenuItem} from 'app/_models/leftmenu';

@Component({
  selector: 'app-edit-menuitem',
  templateUrl: './edit-menuitem.component.html',
  styleUrls: ['./menubuilder.component.css']
})
export class EditMenuitemComponent implements OnInit {
  model: any={};
  workmenu: LeftMenu;
  workcategory: Category;
  category: number;
  menuitem: number;

  constructor( private MenuService: MenuModifierService,
               private router: Router) {
                 this.model=<MenuItem>{};
                 this.MenuService.leftmenu$.subscribe((menuData) => {
                   this.workmenu = menuData;
                 });
                 this.MenuService.category$.subscribe((categoryData) => {
                   this.category = categoryData;
                 });
                 this.MenuService.menuitem$.subscribe((menuitemData) => {
                   this.menuitem = menuitemData;
                 });
               }

  ngOnInit() {
    this.workmenu = this.MenuService.getWorkMenuVariable();
    this.category = this.MenuService.getInitCategory();
    this.menuitem = this.MenuService.getInitMenuItem();
    this.model = this.workmenu.categories[this.category].menuitems[this.menuitem];
  }

  updatemenuitem(){
    this.workmenu.categories[this.category].menuitems[this.menuitem]=this.model;
    this.MenuService.updateWorkMenu(this.workmenu)
    .subscribe(
        data => {
              this.MenuService.getWorkMenu(this.workmenu.menu)
                  .subscribe(
                    data => {
                             this.MenuService.publishMenu(data);
                             this.model=<MenuItem>{};
                             this.router.navigate(['/super-admin/menu-modify']);
                            },
                    );

        },
        error => {
        });
  }

  cancelaction(){
    this.router.navigate(['/super-admin/menu-modify']);
  }

}
