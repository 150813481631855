import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-data',
  templateUrl: './work-data.component.html',
  styleUrls: ['./work-data.component.css']
})
export class WorkDataComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
