import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
     selector: 'app-add-veh-model-dialoue',
     templateUrl: './add-veh-model-dialoue.component.html',
     styleUrls: ['./add-veh-model-dialoue.component.css']
})
export class AddVehModelDialoueComponent {

     models: string[];
     selectedmodel: string;

     modelsArray: {
          name:string,
          selected: boolean
     }[];

     constructor(
          @Inject(MAT_DIALOG_DATA) public data: any,
          public dialogRef: MatDialogRef<AddVehModelDialoueComponent>
     ) {
          this.modelsArray=[];
          this.models = data.models;
          this.selectedmodel = data.model;

          this.models.map((model)=>{
               var mdobj = {
                    name : model,
                    selected: false
               }
               if(model == this.selectedmodel){
                    mdobj.selected = true;
               }
               this.modelsArray.push(mdobj);
          })
     }
     select(selectedobj){
          selectedobj.selected = true;
          this.selectModel(selectedobj.name);
     }


     selectModel(selectedmodel){
          this.dialogRef.close(selectedmodel);
     }

}
