import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';


@Injectable()
export class HeaderService {

  currentusertype: string;
  usertype: Subject<string>;
  usertype$: Observable<string>;

  activeworktype: string;
  worktype: Subject<string>;
  worktype$: Observable<string>;


  constructor() {
    this.usertype = new Subject();
    this.usertype$ = this.usertype.asObservable();

    this.worktype = new Subject();
    this.worktype$ = this.worktype.asObservable();
  }

  publishUserType(usertype){
    this.currentusertype = usertype;
    this.usertype.next(usertype);
  }

  publishWorkType(worktype){
    if(typeof worktype !=="undefined"){
    this.activeworktype = worktype;
    this.worktype.next(worktype);
    }
  }

  getUserType(){
    return this.currentusertype;
  }

}
