import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { AppConfig } from '../app.config';
import { NomenclatorItem } from '../_models/nomenclator';
import { Checker } from '../_models/checker';


@Injectable()
export class NomenclatorService {

     constructor(private http: HttpClient, private config: AppConfig) {

     }

     getAll(){
          return this.http.get<NomenclatorItem[]>(this.config.apiUrl + '/nomenclator', this.jwt());
     }

     getById(_id: string){
          return this.http.get<NomenclatorItem>(this.config.apiUrl + '/nomenclator/' + _id, this.jwt());
     }

     create(data: NomenclatorItem){
          return this.http.post(this.config.apiUrl + '/nomenclator/create', data, this.jwt());
     }

     update(data: NomenclatorItem){
          return this.http.put(this.config.apiUrl + '/nomenclator/' + data._id, data, this.jwt());
     }

     delete(_id: string){
          return this.http.delete(this.config.apiUrl + '/nomenclator/' + _id, this.jwt());
     }

     getPartByQuery(query: any){
          return this.http.post<any>(this.config.apiUrl + '/nomenclator/getPartByQuery',query)
     }

     getVehicleByQuery(query: any){
          return this.http.post<any>(this.config.apiUrl + '/nomenclator/getVehicleByQuery',query)
     }


     ifExists(query: any){
          return this.http.post<Checker>(this.config.apiUrl + '/nomenclator/queryIfExists',query)
     }

     getLastPartNo(){
          return this.http.get<number>(this.config.apiUrl + '/nomenclator/lastpartno', this.jwt())
     }


     private jwt(){
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          if (currentUser && currentUser.token){
               let headers = new HttpHeaders().set('Content-Type', 'application/json')
               .append('Access-Control-Allow-Origin', '*')
               .append('Authorization', 'Bearer '+ currentUser.token)
               .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS');

               return { headers: headers };
          }
    }
}
