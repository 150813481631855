import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-part-cat-dialogue',
  templateUrl: './add-part-cat-dialogue.component.html',
  styleUrls: ['./add-part-cat-dialogue.component.css']
})
export class AddPartCatDialogueComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
