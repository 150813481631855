import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser';
import { ObservableMedia, MediaChange } from '@angular/flex-layout';
import { Observable, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { AppConfig } from '../../app.config';


import { CheckUniqueInArrayValidation } from '../../_validators/check-unique-in-array';

import { AppElementsService } from '../../_services/appelements.service';
import { VehiclesService } from '../../_services/vehicles.service';
import { PictureService } from '../../_services/picture.service';
import { PartsService } from '../../_services/parts.service';
import { NomenclatorService } from '../../_services/nomenclator.service';

import { AppElement } from '../../_models/appelement';
import { Vehicle } from '../../_models/vehicle';
import { Part } from '../../_models/part';
import { NomenclatorItem } from '../../_models/nomenclator';

import { AddVehCatDialogueComponent } from '../../dialogues/add-veh-cat-dialogue/add-veh-cat-dialogue.component';
import { AddVehMadeDialoueComponent } from '../../dialogues/add-veh-made-dialoue/add-veh-made-dialoue.component';
import { AddVehModelDialoueComponent } from '../../dialogues/add-veh-model-dialoue/add-veh-model-dialoue.component';
import { AddPartCatDialogueComponent } from '../../dialogues/add-part-cat-dialogue/add-part-cat-dialogue.component';
import { AddPartSubcatDialogueComponent } from '../../dialogues/add-part-subcat-dialogue/add-part-subcat-dialogue.component';


import * as _moment from 'moment';
import * as locales from 'moment/min/locales';
const moment= _moment;
moment.locale('ro-RO');

@Component({
     selector: 'app-search',
     templateUrl: './search.component.html',
     styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

     mediawatcher: Subscription;
     activeMedia:string;
     searchmediaport: {
          mobile: boolean;
          tablet: boolean;
          desktop: boolean;
     }


     routeSub: any;
     params: any;
     routestring: any;

     appelements: any;
     vehiclequery: any;
     partsquery: any;
     partscategories: any;
     selectedcategory: any;
     selectedpartcategory: any;
     selectedsubcategory:string;
     selectedcategoryname: string;
     selectedmodel: string;
     selectedpartsubcategory: string;
     vehcats: string[];
     vehiclecats: any;
     vehiclesubcats: any[];
     partsubcats: any[];
     manufacturers: string[];
     models: string[];
     partscats: {category: string, selected:boolean}[];
     partname: string;
     categoryselected: boolean;
     subcategoryselected: boolean;
     partnames: string[];
     thecode: string;

     hassubcategories: boolean;
     haspartsubcategories: boolean;
     hasmodels: boolean;
     modelslist: boolean;

     fromyrgroup: string[];
     fromyrarray: string[];
     fromyrgroupselected: boolean;
     selectedfromyr: string;
     selectedfromyrgroup: string;
     fromyrselected: boolean;


     toyrgroup: string[];
     toyrarray: string[];
     toyrgroupselected: boolean;
     selectedtoyr: string;
     selectedtoyrgroup: string;

     searchmarks: {
          vehicles: boolean,
          parts: boolean,
          code: boolean
     }

     routeParams: {
          vehcat: string;
          vehmade: string;
          vehmodel: string;
          partcat: string;
          partsubcat: string;
          partname: string;
     }

     searchForm = new FormGroup({
          categorie: new FormControl(),
          subcategorie: new FormControl(),
          model: new FormControl(),
          fromyr: new FormControl(),
          toyr: new FormControl(),
          partcat: new FormControl(),
          partsubcat: new FormControl(),
          denumire: new FormControl(),
          cod: new FormControl()
     });

     searchVehicleForm = new FormGroup({
          categorie: new FormControl(),
          subcategorie: new FormControl(),
          model: new FormControl(),
          fromyr: new FormControl(),
          toyr: new FormControl(),
     });

     searchPartForm = new FormGroup({
          categorie: new FormControl(),
          subcategorie: new FormControl(),
          model: new FormControl(),
          fromyr: new FormControl(),
          toyr: new FormControl(),
          partcat: new FormControl(),
          partsubcat: new FormControl(),
          denumire: new FormControl(),
     });


     constructor(
          private router: Router,
          private activatedRoute:ActivatedRoute,
          private elementsService: AppElementsService,
          private vehiclesService: VehiclesService,
          private partsService: PartsService,
          private nomenclatorService: NomenclatorService,
          private config: AppConfig,
          private pictureService: PictureService,
          private sanitization: DomSanitizer,
          private formBuilder: FormBuilder,
          public media: ObservableMedia,
          public dialog: MatDialog

     ) {


          this.getAppElements();

          this.searchmediaport = {
               mobile: false,
               tablet: false,
               desktop: false
          }

          console.log(this.router.url);

          this.mediawatcher = this.media.subscribe((change: MediaChange)=>{
               console.log(change);
                    this.changeMedia(change);
          });

          this.fromyrgroup = [];
          this.toyrgroup = [];
          this.selectedpartcategory = '742433';

          this.vehiclequery={};
          this.vehiclequery.query={};
          this.partsquery={};
          this.partsquery.query = {};

          this.searchForm = this.formBuilder.group({
               categorie: [''],
               subcategorie: {value:'',disabled: !this.hassubcategories},
               model: {value:'',disabled: !this.modelslist},
               fromyr: [''],
               toyr: [''],
               partcat: [''],
               partsubcat:  {value:'',disabled: !this.haspartsubcategories},
               denumire: [''],
               cod: ['']

          })

          this.searchVehicleForm = this.formBuilder.group({
               categorie: [''],
               subcategorie: {value:'',disabled: !this.hassubcategories},
               model: {value:'',disabled: !this.modelslist},
               fromyr: [''],
               toyr: [''],

          })

          this.searchPartForm = this.formBuilder.group({
               categorie: [''],
               subcategorie: {value:'',disabled: !this.hassubcategories},
               model: {value:'',disabled: !this.modelslist},
               fromyr: [''],
               toyr: [''],
               partcat: [''],
               partsubcat:  {value:'',disabled: !this.haspartsubcategories},
               denumire: [''],
          })

          this.routeParams = {
               vehcat: '',
               vehmade: '',
               vehmodel: '',
               partcat: '',
               partsubcat: '',
               partname: ''
          }

          this.searchmarks= {
               vehicles: true,
               parts: false,
               code: false
          }

          this.generateyears();

      }

     ngOnInit() {
          this.mediawatcher = this.media.subscribe((change: MediaChange)=>{
                    this.changeMedia(change);
          });
     }

     ngOnDestroy(){

     }

     changeMedia(change){
          console.log(change);
          if(change.mqAlias == "lg" || change.mqAlias == "xl"){
               this.assignviewport('desktop');
               if(this.searchmediaport.desktop){
                    this.router.navigate(["/main/home"]);
               }
          }else if(change.mqAlias == "xs"){
               this.assignviewport('mobile');
               console.log(this.searchmediaport);
          }
     }

     selectsearcktype(type){
          for(var searchtype in this.searchmarks){
               if(searchtype == type){
                    this.searchmarks[searchtype]=true;
               }else{
                    this.searchmarks[searchtype]=false;
               }
          }
     }

     assignviewport(view: string){
          for(var viewport in this.searchmediaport){
               if(viewport == view){
                    this.searchmediaport[viewport] = true;
               }else{
                    this.searchmediaport[viewport] = false;
               }
          }
     }

     generateyears(){
          var currentyear = parseInt(moment().format('YYYY'));
          var decade = Math.floor(currentyear/10);

          for(let i=0; i<7; i++){
               this.fromyrgroup.push(String(decade*10)+' ->');
               this.toyrgroup.push(String(decade*10)+' ->');
               decade--;
          }

     }

     definevehiclequery(fieldname, thestring, minlength){
          if(!minlength){
               minlength = 2;
          }
          if(thestring){
               if(thestring.length>=minlength){
                    this.vehiclequery.query[fieldname]={fieldname: fieldname, value:thestring};
               }else{
                    this.vehiclequery.query[fieldname]={fieldname: fieldname, value:null};
               }
          }

     }

     definepartsquery(fieldname, thestring, minlength){
          if(thestring){
               if(thestring.length>0){
                    this.partsquery.query[fieldname]={fieldname: fieldname, value:thestring};
               }else{
                    this.partsquery.query[fieldname]={fieldname: fieldname, value:null};
               }
          }

          this.nomenclatorService.getPartByQuery(this.partsquery)
          .subscribe(
               (data)=>{

                    this.partnames = data.parts;
               }
          )
     }

     getAppElements(){
          this.elementsService.getAll()
          .subscribe(
               (appelements)=>{
                    this.vehcats=[];
                    this.partscats=[];
                    this.appelements=appelements[0];
                    this.vehiclecats={};
                    this.vehiclecats = this.appelements.vehicle_categories;
                    this.partscategories = this.appelements.parts_categories;
                    this.vehiclecats.map((category)=>{
                         this.vehcats.push(category.category);
                         if(this.selectedcategoryname == category.category){
                              this.selectCategory();
                              if(typeof category.subcategories!=="undefined" && category.subcategories.length>0){
                                   this.manufacturers=[];
                                   this.vehiclesubcats = category.subcategories;
                                   this.vehiclesubcats.map((manufacturer)=>{
                                        this.manufacturers.push(manufacturer.marca);
                                        if(this.selectedsubcategory == manufacturer.marca){
                                             this.selectSubCategory();
                                        }
                                   })
                                   this.hassubcategories = true;
                              }else{
                                   this.hassubcategories = false;
                              }
                         }
                    })
                    this.partscategories.map((category)=>{
                         this.partscats.push({category: category.category, selected: false});
                    })
               }
          )
     }

     selectCategory(){
          this.vehiclequery.query = {};
          this.categoryselected = false;
          this.vehiclecats.map((category)=>{
               if(this.selectedcategoryname==category.category){
                    this.categoryselected = true;
                    this.selectedcategory = category;
                    console.log(this.selectedcategory);
                    this.definevehiclequery('categorie', this.selectedcategoryname, null);
                    if(typeof this.selectedcategory.subcategories !== "undefined" && this.selectedcategory.subcategories.length>0){
                         this.vehiclesubcats = this.selectedcategory.subcategories;
                         this.manufacturers = [];
                         this.vehiclesubcats.map((manufacturer)=>{
                              this.manufacturers.push(manufacturer.marca);
                         })
                         this.hassubcategories = true;
                         this.searchForm.controls['subcategorie'].enable()
                         this.searchVehicleForm.controls['subcategorie'].enable()
                         this.searchPartForm.controls['subcategorie'].enable()

                         this.selectedpartsubcategory = '742433'
                    }else{
                         this.hassubcategories = false;
                         this.searchForm.controls['subcategorie'].disable()
                         this.searchVehicleForm.controls['subcategorie'].disable()
                         this.searchPartForm.controls['subcategorie'].disable()

                         this.vehiclesubcats = [];
                    }
               }
          })
          if(this.categoryselected){
               this.routeParams.vehcat = this.selectedcategoryname;
               this.routeParams.vehmade = '';
               this.routeParams.vehmodel ='';
          }else{
               this.routeParams.vehcat = '';
          }

          console.log(this.searchmediaport);

          if(this.searchmediaport.desktop){
               this.navigateToSearch("vehicle");
          }
     }

     openSelectVehCatDialog(): void {
         let addVehCatDialogRef = this.dialog.open(AddVehCatDialogueComponent, {
           width: '90%',
           height: '60%',
           data: {
                categories: this.vehcats
           }
         });


         addVehCatDialogRef.afterClosed().subscribe(result => {
              this.selectedcategoryname = result;
              this.selectCategory();
              if(this.searchmarks.vehicles){
                   this.searchVehicleForm.controls['categorie'].setValue(this.selectedcategoryname);
              }else if(this.searchmarks.parts){
                   this.searchPartForm.controls['categorie'].setValue(this.selectedcategoryname);
              }
         })
     }

     openSelectVehMadeDialog(): void {

          let data= {
               subcategories: this.manufacturers,
               subcategory: ''
          }
          if(this.selectedsubcategory){
               data.subcategory = this.selectedsubcategory
          }
         let addVehMadeDialogRef = this.dialog.open(AddVehMadeDialoueComponent, {
           width: '90%',
           height: '80%',
           data: data

         });


         addVehMadeDialogRef.afterClosed().subscribe(result => {
              this.selectedsubcategory = result;
              this.selectSubCategory();
              if(this.searchmarks.vehicles){
                   this.searchVehicleForm.controls['subcategorie'].setValue(this.selectedsubcategory);
              }else if(this.searchmarks.parts){
                   this.searchPartForm.controls['subcategorie'].setValue(this.selectedsubcategory);
              }
         })
     }

     openSelectVehModelDialog(): void {
         let data={
              models: this.models,
              model: ''
         }
         if(this.selectedmodel){
              data.model = this.selectedmodel;
         }
         let addVehModelDialogRef = this.dialog.open(AddVehModelDialoueComponent, {
           width: '90%',
           height: '80%',
           data: data
         });


         addVehModelDialogRef.afterClosed().subscribe(result => {
              this.selectedmodel = result;
              this.selectModel();
              if(this.searchmarks.vehicles){
                   this.searchVehicleForm.controls['model'].setValue(this.selectedmodel);
              }else if(this.searchmarks.parts){
                   this.searchPartForm.controls['model'].setValue(this.selectedmodel);
              }
         })
     }

     selectSubCategory(){
          this.models = [];
          this.subcategoryselected=false;
          this.vehiclequery.query['model']={fieldname: 'model', value:''};


               this.vehiclesubcats.map((manufacturer)=>{
                    if(this.selectedsubcategory==manufacturer.marca){
                         this.subcategoryselected=true;
                         this.models = manufacturer.models;
                         this.definevehiclequery('marca', this.selectedsubcategory, null);
                         if(typeof this.models !== "undefined" && this.models.length>0){
                              this.hasmodels = true;
                              this.searchForm.controls['model'].enable();
                              this.searchVehicleForm.controls['model'].enable();
                              this.searchPartForm.controls['model'].enable();
                              this.modelslist = true;
                         }else{
                              this.searchForm.controls['model'].disable();
                              this.searchVehicleForm.controls['model'].enable();
                              this.searchPartForm.controls['model'].enable();
                              this.hasmodels = false;
                         }
                    }
               })

               if(this.subcategoryselected){
                    this.routeParams.vehmade = this.selectedsubcategory;
                    this.routeParams.vehmodel = '';
               }else{
                    this.routeParams.vehmade = ''
               }

               if(this.searchmediaport.desktop){
                    this.navigateToSearch("vehicle");
               }


     }

     selectPartCategory(){
          this.partsquery.query = {};
          if(this.selectedpartcategory!=='742433'){
               this.routeParams.partcat = this.selectedpartcategory.category;
               this.routeParams.partsubcat = '';
               this.routeParams.partname = '';
               this.partscategories.map((category)=>{
                    if(this.selectedpartcategory.category == category.category){
                         if(typeof category.subcategories !=="undefined" && category.subcategories.length>0){
                         this.partsubcats = category.subcategories;
                         this.haspartsubcategories = true;
                         this.definepartsquery('partcat', this.selectedpartcategory.category, null);
                         this.searchForm.controls['partsubcat'].enable()
                    }else{
                         this.haspartsubcategories = false;
                         this.searchForm.controls['partsubcat'].disable()

                    }
               }
               })
          }else{
               this.definepartsquery('partcat','',null);
               this.haspartsubcategories = false;
               this.searchForm.controls['partsubcat'].disable()
               this.routeParams.partcat = '';
               this.routeParams.partsubcat = '';

          }
          if(this.searchmediaport.desktop){
               this.navigateToSearch("part");
          }

     }

     selectPartSubCategory(){
          if(this.selectedpartsubcategory !== '742433'){
               this.definepartsquery('partsubcat', this.selectedpartsubcategory,1)
               this.routeParams.partsubcat = this.selectedpartsubcategory;
          }else{
               this.definepartsquery('partsubcat','',null)
               this.routeParams.partsubcat = '';
          }
          if(this.searchmediaport.desktop){
               this.navigateToSearch("part");
          }
     }

     selectModel(){
          this.definevehiclequery('model', this.selectedmodel, 1);
          this.routeParams.vehmodel = this.selectedmodel;
          if(this.searchmediaport.desktop){
               this.navigateToSearch("vehicle");
          }
     }


     searchforpart(thestring){
          this.definepartsquery('partname', this.partname, 3);
     }

     selectPartName(){
          this.routeParams.partname = this.partname;
          if(this.searchmediaport.desktop){
               this.navigateToSearch("part");
          }
     }

     navigateToSearch(type){
          var queryParams= {vehcat: this.routeParams.vehcat,
                            vehmade: this.routeParams.vehmade,
                            vehmodel: this.routeParams.vehmodel,
                            partcat: this.routeParams.partcat,
                            partsubcat: this.routeParams.partsubcat,
                            partname: this.routeParams.partname
                         }
                         console.log(queryParams);

          if(type == "vehicle"){
               var route="main/vehicles"

          }else if(type == "part"){
               var route = '/main/search';
          }


          for(var key in queryParams){
               route = route + '/' + queryParams[key];
          }

          console.log(route);
          this.router.navigate([route]);
     }

     navigateToSearchMobile(){
          if(this.searchmarks.vehicles){
               this.navigateToSearch('vehicle')
          }else if(this.searchmarks.parts){
               this.navigateToSearch('part')
          }
     }

     selectFromYrGroup(){
          this.fromyrgroupselected = true;
          this.fromyrarray = [];
          var year = parseInt(this.selectedfromyrgroup);
          if((parseInt(moment().format('YYYY'))-parseInt(this.selectedfromyrgroup))<10){

               while(year<=parseInt(moment().format('YYYY'))){
                    this.fromyrarray.push(String(year));
                    year++;
               }
          }else{
               var targetyear = year + 10;
               while(year < targetyear){
                    this.fromyrarray.push(String(year));
                    year++;
               }
          }
          this.selectedfromyr = String(parseInt(this.selectedfromyrgroup));
          this.fromyrarray.reverse();
     }

     selectFromYr(){
          this.fromyrselected=true;
          var year = parseInt(this.selectedfromyr)
          var decade = (Math.floor(year/10)+1)*10;
          var currentdecade = (Math.floor(parseInt(moment().format('YYYY')))/10)*10;

          if(this.selectedfromyr == '742433'){
               this.fromyrselected = false;
               this.fromyrgroupselected = false;
               this.searchForm.controls['fromyr'].reset();
          }else{
               if((parseInt(moment().format('YYYY'))-parseInt(this.selectedfromyr))<10){

                    this.toyrgroupselected = true;
                    this.toyrarray = [];
                    while( year <= parseInt(moment().format('YYYY'))){
                         this.toyrarray.push(String(year));
                         year++
                    }
               }else{
                    this.toyrgroupselected = false;
                    this.toyrgroup = [];
                    this.toyrgroup.push(String(year)+ ' ->');
                    while(decade<=currentdecade){
                         this.toyrgroup.push(String(decade + ' ->'));
                         decade = decade+10;
                    }
               }
          }
     }

     selectToYrGroup(){
          this.toyrarray = [];
          this.toyrgroupselected = true;
          var targetdecade = (Math.floor(parseInt(this.selectedtoyrgroup)/10)*10)+10;
          var year = parseInt(this.selectedtoyrgroup);
          if((parseInt(moment().format('YYYY')))-(targetdecade-1)<10){
               while(year < parseInt(moment().format('YYYY'))){
                    this.toyrarray.push(String(year));
                    year++;
               }
          }else{
               while(year < targetdecade){
                    this.toyrarray.push(String(year));
                    year++;
               }
          }

          this.selectedtoyr = String(parseInt(this.selectedtoyrgroup));
     }

     selectToYr(){

     }

     searchforpartcode(code){

     }

}
