import { Component, ElementRef, Renderer2, ViewChild, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import 'rxjs/add/observable/combineLatest';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { AppConfig } from '../../app.config';


import { AppElementsService } from '../../_services/appelements.service';
import { VehiclesService } from '../../_services/vehicles.service';
import { PartsService } from '../../_services/parts.service';
import { PictureService } from '../../_services/picture.service';
import { NomenclatorService } from '../../_services/nomenclator.service';

import { PictureCollection, PictureGallery, PictureData} from '../../_models/picturecollection';
import { AppElement } from '../../_models/appelement';
import { Vehicle } from '../../_models/vehicle';
import { Part } from '../../_models/part';
import { NomenclatorItem } from '../../_models/nomenclator';

@Component({
     selector: 'app-home',
     templateUrl: './home.component.html',
     styleUrls: ['./home.component.css']
})



export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

     routeSub: any;
     params: any;
     vehiclequery: any;
     partquery: any;

     vehicles: Vehicle[];
     parts: Part[];

     searchForm = new FormGroup({
          categorie: new FormControl(),
          subcategorie: new FormControl(),
          model: new FormControl(),
          fromyr: new FormControl(),
          toyr: new FormControl(),
          partcat: new FormControl(),
          partsubcat: new FormControl(),
          denumire: new FormControl(),
          cod: new FormControl()
     });

     @ViewChild('mobilePage')
     private mobilePage: ElementRef;

     @ViewChild('banner')
     private banner: ElementRef;

     constructor( private router: Router,
                  private activatedRoute:ActivatedRoute,
                  private elementsService: AppElementsService,
                  private vehiclesService: VehiclesService,
                  private partsService: PartsService,
                  private nomenclatorService: NomenclatorService,
                  private config: AppConfig,
                  private pictureService: PictureService,
                  private sanitization: DomSanitizer,
                  private renderer: Renderer2
     ) {

     }

     ngOnInit() {
          if(this.activatedRoute.params){
               this.routeSub=this.activatedRoute.params.subscribe((params)=>{
                    this.params = params;
                    this.initializeComponent();
               })
          }

     }

     ngAfterViewInit(){
          this.renderer.listen(this.mobilePage.nativeElement, 'scroll',(evt)=>{
               let totop = Math.round(this.mobilePage.nativeElement.scrollTop*.9);
               this.renderer.setStyle(this.banner.nativeElement, 'top', totop+'px');
          })
     }

     initializeComponent(){
          this.defineVehicleQuery();
          this.definePartQuery();
          console.log(this.vehiclequery);
          console.log(this.partquery);
     }

     defineVehicleQuery(){
          this.vehiclequery = {};
          this.vehiclequery.query = {};
          this.vehiclequery.sort={updated_at: -1};
          this.vehiclequery.pagination={skip: 0, limit: 5};
          this.vehiclequery.query['categorie']={fieldname: 'categorie', value:this.params.vehcat};
          this.vehiclequery.query['marca']={fieldname: 'marca', value:this.params.vehmade};
          this.vehiclequery.query['model']={fieldname: 'model', value:this.params.vehmodel};
          // this.vehiclequery.query['valid']={fieldname: 'valid', value: true};

          this.vehiclesService.getByQuery(this.vehiclequery)
          .subscribe((data)=>{
               console.log(data);
               this.vehicles = data.vehicles;
               this.vehicles.map((vehicle)=>{
                    vehicle.pictures.map((picture)=>{
                         picture.fullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
                         if(picture.mainpicture){
                              vehicle.mainpicturefullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
                              vehicle.hasmainpicture = true;
                              vehicle.fullpathloaded = true;
                         }
                    })

                    if(!vehicle.descriere){
                         var vehicledefinition = vehicle.marca + ' ' + vehicle.model;
                         var vehicletype = '';

                         if(vehicle.tip){
                              vehicledefinition = vehicledefinition + ' ' + vehicle.tip;
                         }
                         if(vehicle.fabricatie){
                              vehicledefinition = vehicledefinition + ' fabricat in anul ' + vehicle.fabricatie;
                         }
                         if(vehicle.km){
                              vehicledefinition = vehicledefinition + ', rulaj ' + vehicle.km + ' km'
                         }

                         if(vehicle.categorie == "Autocare") vehicletype="autocar ";
                         if(vehicle.categorie == "Camioane") vehicletype="camion ";
                         if(vehicle.categorie == "Autoutilitare") vehicletype="autoutilitara ";
                         if(vehicle.categorie == "Microbuze") vehicletype="microbuz ";
                         if(vehicle.categorie == "Autoturisme") vehicletype="autoturism ";


                         vehicle.descriere = "Dezmembram " + vehicletype + vehicledefinition + '. Mentionam ca toate piesele listate pentru acest autovehicul au fost verificate in prealabil de specialistii nostri. In cazul in care piesa cautata nu apare in lista nu ezitati sa ne contactati pentru verificarea disponibilitatii acesteia';
                    }
               })
          })
     }

     definePartQuery(){
          this.partquery = {};
          this.partquery.query={};
          this.partquery.sort={updated_at: -1};
          this.partquery.pagination={skip: 0, limit: 10};
          this.partquery.query['vehcat']={fieldname: 'vehcat', value:this.params.vehcat};
          this.partquery.query['marca']={fieldname: 'marca', value:this.params.vehmade};
          this.partquery.query['model']={fieldname: 'model', value:this.params.vehmodel};
          this.partquery.query['categorie']={fieldname: 'categorie', value:this.params.partcat};
          this.partquery.query['subcategorie']={fieldname: 'subcategorie', value:this.params.partsubcat};
          this.partquery.query['denumire']={fieldname: 'denumire', value:this.params.partname};
          // this.partquery.query['valid']={fieldname: 'valid', value: true}

          this.partsService.getByQuery(this.partquery)
          .subscribe((data)=>{
               console.log(data);
               this.parts = data.parts;
               this.parts.map((part)=>{
                    part.pictures.map((picture)=>{
                         picture.fullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
                         if(picture.mainpicture){
                              part.mainpicturefullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
                              part.hasmainpicture = true;
                              part.fullpathloaded = true;
                         }
                    })

                    if(part.vehcat == "Autocare") part.viewcat="autocar ";
                    if(part.vehcat == "Camioane") part.viewcat="camion ";
                    if(part.vehcat == "Autoutilitare") part.viewcat="autoutilitara ";
                    if(part.vehcat == "Microbuze") part.viewcat="microbuz ";
                    if(part.vehcat == "Autoturisme") part.viewcat="autoturism ";

                    if(!part.descriere){
                         part.descriere = "Vindem din dezmembrari " + part.denumire.toLowerCase() + " pentru " + part.viewcat + ' ' + part.marca + ' ' + part.model + ' ' + part.tip + '. Mentionam ca toate piesele comercializate au fost verificate in prealabil de specialistii nostri.'
                    }
               })
          })

     }

     ngOnDestroy(){
          if(this.routeSub){
               this.routeSub.unsubscribe();
          }
     }

}
