import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuModifierService } from 'app/_services/menu-modifier.service';
import { LeftMenu , Category , MenuItem, SubMenuItem} from 'app/_models/leftmenu';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./menubuilder.component.css']
})
export class EditCategoryComponent implements OnInit {
  model: any={};
  workmenu: LeftMenu;
  category: number;


  constructor(private MenuService: MenuModifierService,
              private router: Router) {
                this.model=<Category>{};
                this.MenuService.leftmenu$.subscribe((menuData) => {
                  this.workmenu = menuData;
                });
                this.MenuService.category$.subscribe((categoryData) => {
                  this.category = categoryData;
                });
              }

  ngOnInit() {
    this.workmenu = this.MenuService.getWorkMenuVariable();
    this.category = this.MenuService.getInitCategory();
    this.model = this.workmenu.categories[this.category];
  }

  updatecategory(){
    this.workmenu.categories[this.category]=this.model;
    this.MenuService.updateWorkMenu(this.workmenu)
    .subscribe(
        data => {
              this.MenuService.getWorkMenu(this.workmenu.menu)
                  .subscribe(
                    data => {
                             this.MenuService.publishMenu(data);
                             this.model=<Category>{};
                             this.router.navigate(['/super-admin/menu-modify']);
                            },
                    );

        },
        error => {
        });
  }

  cancelaction(){
    this.router.navigate(['/super-admin/menu-modify']);
  }

}
