import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { User } from '../_models/user';
import { LeftMenuService } from '../_services/leftmenu.service';
import { HeaderService } from '../_services/header.service';
import { CurrentUserService } from '../_services/currentuser.service';
import { SUAuthenticationService } from '../_services/suauthentication.service';


import { LeftMenu , Category , MenuItem, SubMenuItem} from '../_models/leftmenu';



@Component({
  moduleId: module.id,
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.css']
})
export class SuperAdminComponent implements OnInit {
  currentUser: User;
  leftmenu: LeftMenu;
  usertype: string;

  constructor( private MenuService: LeftMenuService,
               private HeaderService: HeaderService,
               private UserService: CurrentUserService,
               private suAuthenticationService: SUAuthenticationService,
               private router: Router ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
 }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(this.currentUser){
      this.MenuService.getLeftMenu(this.currentUser.role)
            .subscribe(
              menu => {
                       this.MenuService.publishLeftMenu(menu);
                       this.UserService.publishCurrentUser(this.currentUser);
                       this.HeaderService.publishUserType(this.currentUser.role);
                       if(this.currentUser.role=="superadmin"){
                         this.router.navigate(['/super-admin']);
                       }else{
                         this.suAuthenticationService.logout();
                         this.router.navigate(['/login']);
                       }
                      },
              );
     }else{
       this.router.navigate(['/login']);
     }
   }


}
