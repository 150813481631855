import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
     selector: 'app-add-veh-made-dialoue',
     templateUrl: './add-veh-made-dialoue.component.html',
     styleUrls: ['./add-veh-made-dialoue.component.css']
})

export class AddVehMadeDialoueComponent {


     subcategories: string[];
     selectedsubcategory: string;

     subcategoriesArray: {
          name:string,
          selected: boolean
     }[];

     constructor(
          @Inject(MAT_DIALOG_DATA) public data: any,
          public dialogRef: MatDialogRef<AddVehMadeDialoueComponent>
     ) {
          this.subcategoriesArray=[];
          this.subcategories = data.subcategories;
          this.selectedsubcategory = data.subcategory;

          this.subcategories.map((subcat)=>{
               var sbobj = {
                    name : subcat,
                    selected: false
               }
               if(subcat == this.selectedsubcategory){
                    sbobj.selected = true;
               }
               this.subcategoriesArray.push(sbobj);
          })

     }

     select(selectedobj){
          selectedobj.selected = true;
          this.selectSubCategory(selectedobj.name);
     }


     selectSubCategory(selectedsubcategory){
          this.dialogRef.close(selectedsubcategory);
     }
}
