import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '../shared.module';
import { MaterialModule } from '../material.module';
import { FileUploadModule } from 'ng2-file-upload';

import { AdminRouting } from './admin.routing';

import { AppElementsService } from '../_services/appelements.service';
import { VehiclesService } from '../_services/vehicles.service';
import { PartsService } from '../_services/parts.service';

import { AdminComponent } from './admin.component';
import { AppElementsComponent } from './admin-components/app-elements/app-elements.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { AddVehicleComponent } from './vehicles/add-vehicle/add-vehicle.component';
import { PartsComponent } from './parts/parts.component';
import { AddPartComponent } from './parts/add-part/add-part.component';


@NgModule({
  imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      BrowserAnimationsModule,
      FileUploadModule,
      SharedModule,
      MaterialModule,
      AdminRouting,
      FlexLayoutModule
  ],
  declarations: [
     AdminComponent,
     AppElementsComponent,
     VehiclesComponent,
     AddVehicleComponent,
     PartsComponent,
     AddPartComponent,
  ],
  providers: [
       AppElementsService,
       VehiclesService,
       PartsService
 ]
})
export class AdminModule { }
