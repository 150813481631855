import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Observable} from 'rxjs';
import 'rxjs/add/observable/combineLatest';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  hasheader: boolean;
  currentroute: string;
  routeSub :any;
  params: any;


  constructor(
          private router: Router,
          private activatedRoute:ActivatedRoute,
){


  }

  ngOnInit(){

       this.routeSub=this.activatedRoute.params.subscribe((params)=>{
            this.params = params;
            this.initializeComponent();
            console.log(this.params);
       })

 }

     initializeComponent(){
          console.log(this.router.url);
          if(this.router.url == '/'){
               console.log('unu')
              this.hasheader = false;
         }else{
              console.log('popop')
              this.hasheader = true;
         }
     }

 ngOnDestroy(){

}
}
