import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SUAuthenticationService } from '../_services/suauthentication.service';
import { LeftMenuService } from '../_services/leftmenu.service';
import { HeaderService } from '../_services/header.service'
import { CurrentUserService } from '../_services/currentuser.service';


@Component({
  moduleId: module.id,
  selector: 'app-super-admin-login',
  templateUrl: './super-admin-login.component.html',
  styleUrls: ['../common-elements/login.component.css']
})
export class SuperAdminLoginComponent implements OnInit {
  model: any = {};
  currentUser: any={};
  loading = false;
  returnUrl: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private suAuthenticationService: SUAuthenticationService,
    private MenuService: LeftMenuService,
    private HeaderService: HeaderService,
    private UserService: CurrentUserService) { }

    ngOnInit() {
      this.suAuthenticationService.logout();
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/super-admin';
    }

    gohome() {
      this.router.navigate(['/']);
    }

    login(){
      this.suAuthenticationService.login(this.model.username, this.model.password)
      .subscribe(
        data => {
             let superuser = data;
             if(superuser && superuser.token){
              localStorage.setItem('currentUser', JSON.stringify(superuser));
             }
          this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.MenuService.getLeftMenu(this.currentUser.role)
          .subscribe(
            menu => {
              this.MenuService.publishLeftMenu(menu);
              this.UserService.publishCurrentUser(this.currentUser);
              this.HeaderService.publishUserType(this.currentUser.role);
              if(this.currentUser.role=="superadmin"){
                this.router.navigate(['/super-admin']);
              }
            },
          );
        },
        error => {
        });
      }

    }
