import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SharedModule } from 'app/shared.module';
import { MaterialModule } from 'app/material.module';

import { superAdminRoutig } from '../super-admin/super-admin.routing';

import { SUAuthenticationService } from '../_services/suauthentication.service';
import { SuperUserService } from '../_services/superuser.service';
import { CurrentUserService } from '../_services/currentuser.service';
import { LeftMenuService } from 'app/_services/leftmenu.service';
import { MenuModifierService } from 'app/_services/menu-modifier.service';
import { AssignGeodataService } from 'app/_services/assignGeodata.service';
import { DorneeanService } from 'app/_services/dorneean.service';
import { AppUserService } from 'app/_services/appuser.service';
import { WorkDataService } from 'app/_services/workdata.service';
import { PictureService } from 'app/_services/picture.service';


import { MenuBuilderComponent } from 'app/_directives/menubuilder/menubuilder.component';
import { MenuscrollerComponent } from 'app/_directives/menuscroller/menuscroller.component';

import { SuperAdminLoginComponent } from './super-admin-login.component';
import { SuperAdminRegisterComponent } from './super-admin-register.component';
import { SuperAdminComponent } from './super-admin.component';
import { LandingPageComponent } from './super-admin-components/landing-page.component';
import { MenuModifyComponent } from './super-admin-components/menu-modify-component/menu-modify.component';
import { MenuModifyMainPageComponent } from './super-admin-components/menu-modify-components/menu-modify-main-page.component';
import { AddMenuComponent } from './super-admin-components/menu-modify-components/add-menu.component';
import { AddCategoryComponent } from './super-admin-components/menu-modify-components/add-category.component';
import { EditMenuComponent } from './super-admin-components/menu-modify-components/edit-menu.component';
import { EditCategoryComponent } from './super-admin-components/menu-modify-components/edit-category.component';
import { AddMenuitemComponent } from './super-admin-components/menu-modify-components/add-menuitem.component';
import { EditMenuitemComponent } from './super-admin-components/menu-modify-components/edit-menuitem.component';
import { AddSubmenuitemComponent } from './super-admin-components/menu-modify-components/add-submenuitem.component';
import { EditSubmenuitemComponent } from './super-admin-components/menu-modify-components/edit-submenuitem.component';
import { EditLinkComponent } from './super-admin-components/menu-modify-components/edit-link.component';
import { LinkTableComponent } from './super-admin-components/link-table-component/link-table.component';
import { AdminsListComponent } from './super-admin-components/admins-list-component/admins-list.component';
import { NewAdminComponent } from './super-admin-components/new-admin-component/new-admin.component';
import { DorneeanDataComponent } from './dorneean-components/dorneean-data-component/dorneean-data.component';
import { PricingPlanComponent } from './work-data-components/pricing-plan/pricing-plan.component';
import { WorkDataComponent } from './work-data-components/work-data.component';
import { TvaValueComponent } from './work-data-components/tva-value/tva-value.component';
import { ProformeComponent } from './work-data-components/proforme/proforme.component';
import { InvoicesComponent } from './work-data-components/invoices/invoices.component';
import { ReceiptsComponent } from './work-data-components/receipts/receipts.component';
import { CreateUserComponent } from './dezbus-users.components/create-user/create-user.component';
import { UsersListComponent } from './dezbus-users.components/users-list/users-list.component';
import { DezbusUsersComponent } from './dezbus-users.components/dezbus-users.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MaterialModule,
    superAdminRoutig,
    FlexLayoutModule
  ],
  declarations: [
    SuperAdminLoginComponent,
    SuperAdminRegisterComponent,
    SuperAdminComponent,
    LandingPageComponent,
    MenuModifyComponent,
    MenuModifyMainPageComponent,
    MenuBuilderComponent,
    MenuscrollerComponent,
    AddMenuComponent,
    AddCategoryComponent,
    EditMenuComponent,
    EditCategoryComponent,
    AddMenuitemComponent,
    EditMenuitemComponent,
    AddSubmenuitemComponent,
    EditSubmenuitemComponent,
    EditLinkComponent,
    LinkTableComponent,
    AdminsListComponent,
    NewAdminComponent,
    DorneeanDataComponent,
    PricingPlanComponent,
    WorkDataComponent,
    TvaValueComponent,
    ProformeComponent,
    InvoicesComponent,
    ReceiptsComponent,
    CreateUserComponent,
    UsersListComponent,
    DezbusUsersComponent,

  ],
  providers: [
    SuperUserService,
    CurrentUserService,
    SUAuthenticationService,
    LeftMenuService,
    MenuModifierService,
    AssignGeodataService,
    AppUserService,
    DorneeanService,
    WorkDataService,
    PictureService,
  ]
})
export class SuperAdminModule { }
