export class AppConfig {
    // public readonly apiUrl = 'http://localhost:4000/api';
    // public readonly uploadUrl = 'http://localhost:4001/imgs';
    // public readonly picUrl = 'http://localhost:4001/';
    // public readonly outsideUrl ='http://localhost:4002/outside-link'

    public readonly apiUrl = 'https://www.dezmembraridorna.ro/api';
    public readonly uploadUrl = 'https://www.dezmembraridorna.ro/imgs';
    public readonly picUrl = 'https://www.dezmembraridorna.ro/';
    public readonly outsideUrl ='https://www.dezmembraridorna.ro/outside-link'

    // public readonly apiUrl = 'http://www.streetfoodromania.ro/api';
    // public readonly uploadUrl = 'http://www.streetfoodromania.ro/imgs';
    // public readonly picUrl = 'http://www.streetfoodromania.ro/';

};
