import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { DomSanitizer} from '@angular/platform-browser'

import { AppConfig } from '../../../app.config';

import { CheckUniqueInArrayValidation } from '../../../_validators/check-unique-in-array';

import { AppElementsService } from '../../../_services/appelements.service';
import { VehiclesService } from '../../../_services/vehicles.service';
import { PictureService } from '../../../_services/picture.service';
import { NomenclatorService } from '../../../_services/nomenclator.service'

import { PictureCollection, PictureGallery, PictureData} from '../../../_models/picturecollection';
import { AppElement } from '../../../_models/appelement';
import { Vehicle } from '../../../_models/vehicle';
import { NomenclatorItem } from '../../../_models/nomenclator';


@Component({
     selector: 'app-add-vehicle',
     templateUrl: './add-vehicle.component.html',
     styleUrls: ['./add-vehicle.component.css']
})
export class AddVehicleComponent implements OnInit, OnDestroy {

     appelements: any;
     vehiclecats: any;
     partscategories : any;
     vehcats: string[];
     partscats: {category: string, selected:boolean}[];
     selectedcategory: any;
     selectedcategoryname: string;
     selectedsubcategory: string;
     selectedmodel: string;
     manufacturers: string[];
     models: string[];
     vehiclesubcats: any[];
     subcategory: string;

     vehicle: Vehicle;
     picturemeta: PictureData;

     vehiclecatindex:number;
     mentions: string[];

     hassubcategories: boolean;
     hasmodels: boolean;
     categoryselected: boolean;
     subcategoryselected: boolean;
     vehicledefined: boolean;
     picturesloaded: boolean;

     selectallcats: boolean;

     addnewcat: boolean;
     addnewmade: boolean;
     modelslist: boolean;
     addmodel: boolean;


     cutvachecked: boolean;
     imageloaded: boolean;

     selectedtva: any;
     selectedum: any;

     pictureuploader: FileUploader;
     newimage: any;



     addVehicleTypeForm = new FormGroup({
          categorie: new FormControl(),
          subcategorie: new FormControl(),
          model: new FormControl(),
          type: new FormControl(),
          year: new FormControl(),
          km: new FormControl()
     });

     addVehicleAttrsForm = new FormGroup({
          um: new FormControl(),
          tva: new FormControl(),
          pret: new FormControl(),
          cutva: new FormControl()
     });

     addVehicleCategoryForm = new FormGroup({
          categorie: new FormControl()
     });

     addVehicleMadeForm = new FormGroup({
          marca: new FormControl()
     });

     addVehicleModelForm = new FormGroup({
          model: new FormControl()
     });

     addVehicleDescriptionForm = new FormGroup({
          descriere: new FormControl()
     });

     constructor(
          private router: Router,
          private elementsService: AppElementsService,
          private vehiclesService: VehiclesService,
          private nomenclatorService: NomenclatorService,
          private config: AppConfig,
          private pictureService: PictureService,
          private sanitization: DomSanitizer,
          private formBuilder: FormBuilder
     ) {

          this.addnewcat = false;
          this.addnewmade = false;
          this.categoryselected = false;
          this.subcategoryselected =false;
          this.vehcats = [];
          this.vehicle = {} as Vehicle;
          this.vehicle.pictures = [];
          this.vehicle.partscats = [];

          this.pictureuploader = new FileUploader({url: this.config.uploadUrl});

          this.getAppElements();

          this.vehiclesService.getLastVehNo()
          .subscribe((data)=>{
               if(data.vehno==null){
                    this.vehicle.vehno = 1001;
               }else{
                    this.vehicle.vehno = data.vehno + 1;
               }
          })

          this.addVehicleTypeForm = this.formBuilder.group({
               categorie: ['', [Validators.required, Validators.minLength(3)]],
               subcategorie: ['', [Validators.required, Validators.minLength(3)]],
               model: ['', [Validators.required, Validators.minLength(1)]],
               year: ['',[Validators.required, Validators.minLength(4)]],
               type: [''],
               km: ['']

          },{
               validator: CheckUniqueInArrayValidation.MatchElements
          })


          this.addVehicleCategoryForm = this.formBuilder.group({
               categorie:['', [Validators.required, Validators.minLength(3)]]
          })

          this.addVehicleMadeForm = this.formBuilder.group({
               marca:['', [Validators.required, Validators.minLength(3)]]
          })

          this.addVehicleDescriptionForm = this.formBuilder.group({
               descriere:['']
          })
     }

     ngOnInit() {
     }

     ngOnDestroy() {

     }

     getAppElements(){
          console.log(this.selectedcategory);
          console.log(this.selectedsubcategory);


          this.elementsService.getAll()
          .subscribe(
               (appelements)=>{
                    console.log(this.selectedcategoryname);
                    this.vehcats=[];
                    this.partscats=[];
                    this.vehiclecats={};
                    this.appelements=appelements[0];
                    this.vehiclecats = this.appelements.vehicle_categories;
                    this.partscategories = this.appelements.parts_categories;
                    this.vehiclecats.map((category)=>{
                         this.vehcats.push(category.category);
                         if(this.selectedcategoryname == category.category){
                              this.selectCategory();
                              if(typeof category.subcategories!=="undefined" && category.subcategories.length>0){
                                   this.manufacturers=[];
                                   this.vehiclesubcats = category.subcategories;
                                   console.log(this.vehiclesubcats)
                                   this.vehiclesubcats.map((manufacturer)=>{
                                        this.manufacturers.push(manufacturer.marca);
                                        if(this.selectedsubcategory == manufacturer.marca){
                                             this.selectSubCategory();
                                        }
                                   })
                                   console.log(this.manufacturers)
                                   this.hassubcategories = true;
                                   this.addnewmade = false;
                              }else{
                                   this.addnewmade = true;
                                   this.hassubcategories = false;
                              }
                         }
                    })
                    this.partscategories.map((category)=>{
                         this.partscats.push({category: category.category, selected: false});
                    })
                    console.log(this.vehcats);
                    console.log(this.partscats);
               }
          )
     }

     selectCategory(){
          this.modelslist = false;
          this.addmodel = false;
          if(this.selectedcategoryname == '742433'){
               this.addnewcat = true;
               this.addnewmade = false;
               this.hassubcategories = false;
          }else{
               this.categoryselected=true;
               this.addnewcat = false;
               this.addnewmade = false;
               this.vehiclecats.map((category)=>{
                    if(this.selectedcategoryname==category.category){
                         this.selectedcategory = category;
                         this.vehicle.categorie = this.selectedcategory.category;
                         if(typeof this.selectedcategory.subcategories !== "undefined" && this.selectedcategory.subcategories.length>0){
                              this.vehiclesubcats = this.selectedcategory.subcategories;
                              this.manufacturers = [];
                              this.vehiclesubcats.map((manufacturer)=>{
                                   this.manufacturers.push(manufacturer.marca);
                              })
                              this.hassubcategories = true;

                         }else{
                              this.hassubcategories = false;
                              this.addnewmade = true;
                              this.vehiclesubcats = [];
                         }
                    }
               })
          }

     }

     selectSubCategory(){
          this.modelslist = false;
          this.addmodel = false;
          this.models = [];
          this.subcategoryselected=true;

          console.log(this.selectedsubcategory);

          if(this.selectedsubcategory == '742433'){
               this.addnewcat = false;
               this.addnewmade = true;
               this.hassubcategories = false;

          }else{
               this.addnewcat = false;
               this.addnewmade = false;
               this.vehiclesubcats.map((manufacturer)=>{
                    if(this.selectedsubcategory==manufacturer.marca){
                         this.models = manufacturer.models;
                         if(typeof this.models !== "undefined" && this.models.length>0){
                              this.hasmodels = true;
                              this.modelslist = true;
                              this.vehicle.marca = this.selectedsubcategory;
                         }else{
                              this.hasmodels = false;
                              this.addmodel = true;
                         }
                         console.log(this.models);
                    }
               })
          }
     }


     selectModel(){
          if(this.selectedmodel == '742433'){
               this.addnewcat = false;
               this.addnewmade = false;
               this.addmodel =true;
               this.modelslist = false;
               this.addVehicleModelForm.reset();
               this.selectedmodel = '';

          }
          else{
               this.vehicle.model = this.selectedmodel;
               this.vehicle.pathfolder = this.vehicle.categorie + '_' + this.vehicle.marca + '_' + this.vehicle.model;
               console.log(this.vehicle);
          }
     }

     gotomodels(){
          this.addmodel =false;
          this.modelslist = true;
     }

     onSubmitNewCatForm({ value, valid }: { value: any, valid: boolean }){
          var newcategory = {
               category: value.categorie,
               subcategories: []
          }

          this.appelements.vehicle_categories.push(newcategory);

          this.elementsService.update(this.appelements)
          .subscribe(
               (data)=>{

                    this.selectedcategory=newcategory;
                    this.selectedcategoryname = this.selectedcategory.category;
                    this.categoryselected = true;
                    this.addVehicleCategoryForm.reset();
                    this.addnewcat = false;
                    this.addnewmade = true;
                    this.vehiclesubcats = [];
                    this.getAppElements();
               }
          )


     }

     onSubmitNewMadeForm({value, valid }: {value:any, valid: boolean}){
          this.vehiclesubcats.push({marca: value.marca, models:[]});

          this.vehiclecats.map((category)=>{
               if(category==this.selectedcategoryname){
                    category.subcategories = this.vehiclesubcats;
               }


          })

          this.appelements.vehicle_categories = this.vehiclecats;
          this.elementsService.update(this.appelements)
          .subscribe(
               ()=>{
                    this.hassubcategories = true;
                    this.selectedsubcategory=value.marca;
                    this.addVehicleModelForm.reset();
                    this.addnewcat = false;
                    this.addnewmade = false;
                    this.getAppElements();
                    console.log(this.selectedsubcategory);

               }
          )

     }

     onSubmitNewModelForm({value, valid }: {value:any, valid: boolean}){
          this.models.push(value.model);
          this.vehiclesubcats.map((vehiclemade)=>{
               if(vehiclemade.made == this.selectedsubcategory){
                    vehiclemade.models = this.models;
               }
          });

          this.vehiclecats.map((category)=>{
               if(category==this.selectedcategoryname){
                    category.subcategories = this.vehiclesubcats;
               }
          })

          this.appelements.vehicle_categories = this.vehiclecats;
          this.elementsService.update(this.appelements)
          .subscribe(
               (data)=>{
                    this.modelslist = true;
                    this.addmodel = false;
                    this.selectedmodel = value.model;
                    this.addVehicleMadeForm.reset();
                    this.addnewcat = false;
                    this.addnewmade = false;
                    this.selectModel();
                    this.getAppElements();
                    console.log(this.selectedmodel);

               }
          )

     }

     onSubmitVehicleForm({value, valid }: {value:any, valid: boolean}){
          this.vehicle.categorie = value.categorie;
          this.vehicle.marca = value.subcategorie;
          this.vehicle.model = value.model;
          this.vehicle.tip = value.type;
          this.vehicle.fabricatie = value.year;
          this.vehicle.km = value.km;
          this.vehicle.valid = true;
          this.vehiclesService.create(this.vehicle)
          .subscribe((data)=>{
               console.log(data);
               this.vehicle = data;
               this.vehicledefined = true;
               var query = {type:'', vehcat:'', vehmade:'', vehmodel:'', vehtype:''};
               query.type = "vehicle";
               query.vehcat = this.vehicle.categorie;
               query.vehmade = this.vehicle.marca;
               query.vehmodel = this.vehicle.model;
               query.vehtype = this.vehicle.tip;

               this.nomenclatorService.ifExists(query)
               .subscribe((data)=>{
                    console.log(data);
                    if(!data.exists){
                         var nomenclatorItem = {} as NomenclatorItem;
                         nomenclatorItem.type = "vehicle";
                         nomenclatorItem.vehcat = this.vehicle.categorie;
                         nomenclatorItem.vehmade = this.vehicle.marca;
                         nomenclatorItem.vehmodel = this.vehicle.model;
                         nomenclatorItem.vehtype = this.vehicle.tip;
                         this.nomenclatorService.create(nomenclatorItem)
                         .subscribe((response)=>{
                              console.log(response);
                         })
                    }
               })
          })

     }

     onSubmitDescriptionForm({value, valid }: {value:any, valid: boolean}){
          this.vehicle.descriere = value.descriere

          this.vehiclesService.update(this.vehicle)
          .subscribe((data)=>{
               console.log(data);
          })
     }

     pictureFileListener(e){
       this.picturemeta = {} as PictureData;
       var file:File = e.target.files[0];
       var myReader = new FileReader();
       var image = new Image();

       myReader.onloadend = function (loadEvent:any) {
         image.src = loadEvent.target.result;
         image.onload = function() {
              var width = image.width;
              var height = image.height;
              var maxwidth = 900;
              var maxheight = 600;
              var canvas = document.createElement("canvas");

              if(width>height){
                   canvas.width = maxwidth;
                   canvas.height = maxwidth * height/width;
              }else{
                   canvas.height = maxheight;
                   canvas.width = maxheight * width/height;
              }
              canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
              this.newimage = canvas.toDataURL('image/jpeg');
              this.imageloaded=true;

         }.bind(this);
       }.bind(this);
       myReader.readAsDataURL(file);
       this.picturemeta.filename=file.name;
       this.picturemeta.category="vehicles";
       this.picturemeta.ratio="1:1";
       this.picturemeta.parent = this.vehicle.pathfolder;
       this.picturemeta.path = this.picturemeta.category + '/' + this.vehicle.pathfolder;

     }

     pictureUpload(){
          this.pictureService.upload(this.newimage, this.picturemeta)
          .subscribe((data)=>{
               this.picturemeta.filename = data.filename;
               this.picturemeta.path = data.path;
               this.vehicle.pictures.push(this.picturemeta);
               if(this.vehicle.pictures.length==1){
                    this.vehicle.pictures[0].mainpicture = true;
               }
               this.imageloaded = false;
               this.picturemeta = {} as PictureData;
               this.updatevehicle(this.vehicle);
          })
     }


     selectPartsCategory(){
          this.partscats.map((category)=>{
               if(category.selected){
                    this.vehicle.partscats.push(category.category);
               }
          })

          this.updatevehicle(this.vehicle);
     }

     selectAllCategories(){
          if(this.selectallcats){
               this.vehicle.partscats=[];
               this.partscats.map((category)=>{
                    category.selected=true;
                    this.vehicle.partscats.push(category.category);
               })

               this.updatevehicle(this.vehicle);
          }else{
               this.vehicle.partscats=[];
               this.partscats.map((category)=>{
                    category.selected=false
               })
               this.updatevehicle(this.vehicle);
          }
     }

     updatevehicle(vehicle){
          this.vehiclesService.update(vehicle)
          .subscribe((data)=>{
               this.vehiclesService.getById(vehicle._id)
               .subscribe((vehicledata)=>{
                    this.vehicle = vehicledata;
                    if(this.vehicle.pictures.length>0){
                         this.picturesloaded = true;
                         this.vehicle.pictures.map((picture)=>{
                              picture.fullpath = this.sanitization.bypassSecurityTrustStyle(`url('${this.config.picUrl+picture.path+'/'+picture.filename}')`);
                         })
                    }else{
                         this.picturesloaded = false;
                    }

               })
          })
     }

     selectmainpicture(i){

          console.log(i);
          this.vehicle.pictures.map((picture, index)=>{
               console.log(index);
               if(index == i){
                    picture.mainpicture = true;
               }else{
                    picture.mainpicture = false;
               }
          })

          this.vehiclesService.update(this.vehicle);
     }

     abortvehicle(){
          this.vehiclesService.delete(this.vehicle._id)
          .subscribe(
               (data)=>{
                    console.log(data);
                    this.router.navigate(["/admin"]);
               }
          )
     }

     finishvehicle(){
          this.updatevehicle(this.vehicle);
          this.router.navigate(["/admin"]);
     }

}
