import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import 'rxjs/add/observable/combineLatest';

import { AppConfig } from '../../app.config';

import { AppElementsService } from '../../_services/appelements.service';
import { VehiclesService } from '../../_services/vehicles.service';
import { PartsService } from '../../_services/parts.service';
import { PictureService } from '../../_services/picture.service';
import { NomenclatorService } from '../../_services/nomenclator.service';

import { PictureCollection, PictureGallery, PictureData} from '../../_models/picturecollection';
import { AppElement } from '../../_models/appelement';
import { Vehicle } from '../../_models/vehicle';
import { Part } from '../../_models/part';
import { NomenclatorItem } from '../../_models/nomenclator';


@Component({
     selector: 'app-vehicle',
     templateUrl: './vehicle.component.html',
     styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {

     routeSub: any;
     params: any;
     vehicles: Vehicle[];
     vehicle: Vehicle;
     parts: Part[];
     vehiclequery: any;
     partquery: any;
     galdoubles: boolean;
     partscatseven: boolean;
     partscats:{partcat: string,
               selected: boolean}[];


     constructor(
          private router: Router,
          private activatedRoute:ActivatedRoute,
          private elementsService: AppElementsService,
          private vehiclesService: VehiclesService,
          private partsService: PartsService,
          private nomenclatorService: NomenclatorService,
          private config: AppConfig,
          private pictureService: PictureService,
          private sanitization: DomSanitizer
     ) { }

     ngOnInit() {
          this.routeSub=this.activatedRoute.params.subscribe((params)=>{
               this.params = params;
               this.initializeComponent();
          })
     }

     initializeComponent(){
          this.initializeVehicleQuery();
          console.log(this.vehiclequery);
     }

     initializeVehicleQuery(){
          this.vehiclequery = {};
          this.vehiclequery.query = {};
          this.vehiclequery.sort={updated_at: -1};
          this.vehiclequery.pagination={skip: 0, limit: 5};
          this.partquery = {};
          this.partquery.query = {};
          this.partquery.sort={updated_at: -1};
          this.partquery.pagination={skip: 0, limit: 5};
          this.vehiclequery.query['vehno']={fieldname: 'vehno', value:this.params.vehno};
          this.vehiclequery.query['marca']={fieldname: 'marca', value:this.params.vehmade};
          this.vehiclequery.query['model']={fieldname: 'model', value:this.params.vehmodel};
          // this.vehiclequery.query['valid']={fieldname: 'valid', value: true};

          this.getVehicleByQuery(this.vehiclequery);

     }

     generateVehDescription(vehicle){
               var vehicledefinition = vehicle.marca + ' ' + vehicle.model;
               var vehicletype = '';

               if(vehicle.tip){
                    vehicledefinition = vehicledefinition + ' ' + vehicle.tip;
               }
               if(vehicle.fabricatie){
                    vehicledefinition = vehicledefinition + ' fabricat in anul ' + vehicle.fabricatie;
               }
               if(vehicle.km){
                    vehicledefinition = vehicledefinition + ', rulaj ' + vehicle.km + ' km'
               }

               if(vehicle.categorie == "Autocare") vehicletype="autocar ";
               if(vehicle.categorie == "Camioane") vehicletype="camion ";
               if(vehicle.categorie == "Autoutilitare") vehicletype="autoutilitara ";
               if(vehicle.categorie == "Microbuze") vehicletype="microbuz ";
               if(vehicle.categorie == "Autoturisme") vehicletype="autoturism ";


               vehicle.descriere = "Dezmembram " + vehicletype + vehicledefinition + '. Mentionam ca toate piesele listate pentru acest autovehicul au fost verificate in prealabil de specialistii nostri. In cazul in care piesa cautata nu apare in lista nu ezitati sa ne contactati pentru verificarea disponibilitatii acesteia.';

     }

     getVehicleByQuery(query){
          this.vehiclesService.getByQuery(this.vehiclequery)
          .subscribe((data)=>{
               this.partscats=[];
               this.vehicles = data.vehicles;
               this.vehicle = this.vehicles[0];
               this.organizeVehicle(this.vehicle);
               this.partquery.query['veh_id']={fieldname: 'veh_id', value:this.vehicle._id};
               this.getPartsByQuery(this.partquery);
          })
     }

     getPartsByQuery(query){
          this.partsService.getByQuery(query)
          .subscribe((data)=>{
               console.log(data);
               this.parts = data.parts;
               this.organizeParts(this.parts);
          })
     }

     organizeVehicle(vehicle){
          if(vehicle.pictures.length%2==0){
               this.galdoubles= true;
          }else{
               this.galdoubles = false;
          }

          vehicle.partscats.map((partcat)=>{
               let partcategory = {partcat: partcat,
                                   selected: false};
               this.partscats.push(partcategory);
          })

          if(vehicle.partscats.length%2==0){
               this.partscatseven = true;
          }else{
               this.partscatseven = false;
          }

          vehicle.pictures.map((picture)=>{
               picture.fullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
               if(picture.mainpicture){
                    picture.selected = true;
                    vehicle.mainpicturefullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
                    vehicle.hasmainpicture = true;
                    vehicle.fullpathloaded = true;
               }
          })

          if(!vehicle.descriere){
               this.generateVehDescription(this.vehicle);
          }
     }

     organizeParts(parts){
          parts.map((part)=>{
               part.pictures.map((picture)=>{
                    picture.fullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
                    if(picture.mainpicture){
                         part.mainpicturefullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
                         part.hasmainpicture = true;
                         part.fullpathloaded = true;
                    }
               })

               if(part.vehcat == "Autocare") part.viewcat="autocar ";
               if(part.vehcat == "Camioane") part.viewcat="camion ";
               if(part.vehcat == "Autoutilitare") part.viewcat="autoutilitara ";
               if(part.vehcat == "Microbuze") part.viewcat="microbuz ";
               if(part.vehcat == "Autoturisme") part.viewcat="autoturism ";

               if(!part.descriere){
                    part.descriere = "Vindem din dezmembrari " + part.denumire.toLowerCase() + " pentru " + part.viewcat + ' ' + part.marca + ' ' + part.model + ' ' + part.tip + '. Mentionam ca toate piesele comercializate au fost verificate in prealabil de specialistii nostri.'
               }
          })
     }

     selectPicture(index){
          console.log(index);

          this.vehicle.mainpicturefullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+this.vehicle.pictures[index].path+'/'+this.vehicle.pictures[index].filename);

          this.vehicle.pictures.map((picture, idx)=>{
                    if(idx == index){
                         picture.selected = true;
                    }else{
                         picture.selected = false;
                    }
          })
     }

     selectPartCat(index){
          this.partscats.map((category, idx)=>{
               if(idx == index){
                    category.selected = !category.selected;
                    if(category.selected){
                         this.partquery.query['categorie']={fieldname: 'categorie', value: category.partcat};
                    }else{
                         this.partquery.query['categorie']={fieldname: 'categorie', value: ''};
                    }
                    this.getPartsByQuery(this.partquery);
               }else{
                    category.selected = false;
               }
          })
     }

}
