import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'

import { AppConfig } from '../app.config';
import { AppUser } from '../_models/appuser';


@Injectable()
export class SUAuthenticationService {

  constructor(private http: HttpClient, private config: AppConfig) { }

  login(username: string, password: string){
    return this.http.post<AppUser>(this.config.apiUrl + '/superusers/authenticate', {username: username, password: password})

  }

  logout(){
    localStorage.removeItem('currentUser');
  }

}
