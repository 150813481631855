import { Component, Directive, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from './_services/authentication.service';
import { SUAuthenticationService } from './_services/suauthentication.service';
import { LeftMenuService } from './_services/leftmenu.service';
import { HeaderService } from './_services/header.service'
import { CurrentUserService } from './_services/currentuser.service';

import { AppUser } from './_models/appuser'


@Component({
     selector: 'app-login',
     templateUrl: './login.component.html',
     styleUrls: ['./common-elements/login.component.css']
})
export class LoginComponent implements OnInit {


     model: any = {};
     currentUser: any={};
     returnUrl: string;
     constructor(private route: ActivatedRoute,
          private router: Router,
          private AuthenticationService: AuthenticationService,
          private SUAuthenticationService: SUAuthenticationService,
          private MenuService: LeftMenuService,
          private HeaderService: HeaderService,
          private UserService: CurrentUserService,) { }

          ngOnInit( ) {
               this.AuthenticationService.logout();
               this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
          }

          gohome() {
               this.router.navigate(['/']);
          }


          login(){
               if(this.model.username && this.model.password){
                    this.AuthenticationService.login(this.model.username, this.model.password)
                    .subscribe(
                         data => {

                                    let appuser = data;
                                    if(appuser && appuser.token){
                                     localStorage.setItem('currentUser', JSON.stringify(appuser));
                                    }

                              this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                              this.MenuService.getLeftMenu(this.currentUser.role)
                              .subscribe(
                                   menu => {
                                        this.MenuService.publishLeftMenu(menu);
                                        this.UserService.publishCurrentUser(this.currentUser);
                                        this.HeaderService.publishUserType(this.currentUser.role);
                                        if(this.currentUser.role=="dezbusadmin"){
                                             this.router.navigate(['/admin']);
                                        }else if(this.currentUser.role=="organizer"){
                                             this.router.navigate(['/organizer']);
                                        } else if(this.currentUser.role=="superadmin"){
                                             this.router.navigate(['/super-admin']);
                                        }

                              })

                         },
                         error => {
                              this.SUAuthenticationService.login(this.model.username, this.model.password)
                              .subscribe(
                                   data => {
                                        let appuser = data;
                                       if(appuser && appuser.token){
                                        localStorage.setItem('currentUser', JSON.stringify(appuser));
                                       }
                                        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                                        this.MenuService.getLeftMenu(this.currentUser.role)
                                        .subscribe(
                                             menu => {
                                                  this.MenuService.publishLeftMenu(menu);
                                                  this.UserService.publishCurrentUser(this.currentUser);
                                                  this.HeaderService.publishUserType(this.currentUser.role);
                                                  if(this.currentUser.role=="vendor"){
                                                       this.router.navigate(['/vendor']);
                                                  }else if(this.currentUser.role=="organizer"){
                                                       this.router.navigate(['/organizer']);
                                                  } else if(this.currentUser.role=="superadmin"){
                                                       this.router.navigate(['/super-admin']);
                                                  }

                                        })

                                   })
                         }
               )

               }
          }
     }
