import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { DomSanitizer} from '@angular/platform-browser';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { AppConfig } from '../../../app.config';

import { CheckUniqueInArrayValidation } from '../../../_validators/check-unique-in-array';

import { AppElementsService } from '../../../_services/appelements.service';
import { VehiclesService } from '../../../_services/vehicles.service';
import { PartsService } from '../../../_services/parts.service';
import { PictureService } from '../../../_services/picture.service';
import { NomenclatorService } from '../../../_services/nomenclator.service';

import { PictureCollection, PictureGallery, PictureData} from '../../../_models/picturecollection';
import { AppElement } from '../../../_models/appelement';
import { Vehicle } from '../../../_models/vehicle';
import { Part } from '../../../_models/part';
import { NomenclatorItem } from '../../../_models/nomenclator'

import * as _moment from 'moment';
import * as locales from 'moment/min/locales';
const moment= _moment;

moment.locale('ro-RO');


@Component({
     selector: 'app-add-part',
     templateUrl: './add-part.component.html',
     styleUrls: ['./add-part.component.css'],
     animations: [
          trigger('vehicles-list-wrapper',[
               state('little',style({
                    height: '250px'
               })),
               state('full-list',style({
                    height: '100%',
                    transform: 'translateX(0)'
               })),
               state('swipe-right',style({
                    height: '500px',
                    transform: 'translateX(100%)'
               })),
               state('swipe-left',style({
                    height: '300px',
                    transform: 'translateX(0)'
               })),
               transition('little => full-list', animate('600ms ease-in')),
               transition('full-list => little', animate('1000ms ease-out')),
               transition('full-list => swipe-right', animate('500ms ease-out')),
               transition('swipe-right => swipe-left', animate('500ms ease-in')),
               transition('swipe-left => swipe-right', animate('500ms ease-in')),
               transition('swipe-right => full-list', animate('500ms ease-in'))

          ]),
          trigger('vehicles-list',[
               state('hide',style({
                    opacity: 0
               })),
               state('show',style({
                    opacity: 1
               })),
               transition('hide => show', animate('1500ms ease-out')),
               transition('show => hide', animate('600ms ease-in'))

          ]),
          trigger('showcurrent',[
               state('hide',style({
                    opacity: 0
               })),
               state('show',style({
                    opacity: 1
               })),
               transition('hide => show', animate('1500ms ease-out')),
               transition('show => hide', animate('600ms ease-in'))
          ]),
          trigger('part-form-wrapper',[
               state('initial',style({
                    height: '1px'
               })),
               state('deployed', style({
                    height: '580px'
               })),
               transition('initial => deployed', animate('600ms ease-in')),
               transition('deployed => initial', animate('1000ms ease-out'))
          ]),
          trigger('part-form-container',[
               state('hide',style({
                    opacity: 0
               })),
               state('show',style({
                    opacity: 1
               })),
               transition('hide => show', animate('1500ms ease-out')),
               transition('show => hide', animate('600ms ease-in'))
          ])
     ]
})
export class AddPartComponent implements OnInit {

     appelements: any;
     vehiclecats: any;
     vehcats: string[];
     partscats: {category: string, selected:boolean}[];
     newcat: string;
     partscategories: any;
     selectedcategory: any;
     selectedcategoryname: string;
     selectedsubcategory: string;
     selectedpartsubcategory: string;
     selectedmodel: string;
     manufacturers: string[];
     models: string[];
     vehiclesubcats: any[];
     subcategory: string;
     partsubcats: string[];
     toState: string;

     vehicle: Vehicle;
     vehicles: Vehicle[];
     part: Part;
     picturemeta: PictureData;

     categories: {category: string, selected: boolean}[];

     wrapperStateName: string;
     vehicleShowState: string;
     currentVehicleState:string;
     partWrapperState: string;
     partContainerState: string;

     partWrapperStateInitialized: boolean;

     hassubcategories: boolean;
     parthassubcategories: boolean;
     categoryselected: boolean;
     subcategoryselected: boolean;
     hasmodels: boolean;
     modelslist: boolean;
     vehiclesloaded: boolean;
     categoryloaded: boolean;
     imageloaded: boolean;
     picturesloaded: boolean;
     partloaded: boolean;

     addsubcat: boolean;


     loadvehiclesview:boolean;
     selectedvehicleview: boolean;

     vehiclesno: number;
     selectedoderby: any;
     selectedelsperpage: any;
     selectedpageno: number;

     less10pages: boolean;
     less20pages: boolean;

     orderBys: any[];
     elsperpages: any[];

     pictureuploader: FileUploader;
     newimage: any;


     query: any;
     pagesno: number;
     pagesnos: number[];
     startpagenos: number[];
     middlelowpagenos: number[];
     middlehighpagenos: number[];
     endpagenos: number[];

     selectVehicleTypeForm = new FormGroup({
          categorie: new FormControl(),
          subcategorie: new FormControl(),
          model: new FormControl(),
          vehno: new FormControl(),
          orderBy: new FormControl(),
          elsperpage: new FormControl()
     });

     addPartForm = new FormGroup({
          subcategorie: new FormControl(),
          denumire: new FormControl(),
          cod: new FormControl(),
          descriere: new FormControl(),
          pret: new FormControl()
     });

     addPartSubCatForm = new FormGroup({
          subcategorie: new FormControl()
     })


     constructor(
          private router: Router,
          private elementsService: AppElementsService,
          private vehiclesService: VehiclesService,
          private partsService: PartsService,
          private nomenclatorService: NomenclatorService,
          private config: AppConfig,
          private pictureService: PictureService,
          private sanitization: DomSanitizer,
          private formBuilder: FormBuilder
     ) {
          this.getAppElements();
          this.part = {} as Part;
          this.part.pictures = [];
          this.pictureuploader = new FileUploader({url: this.config.uploadUrl});

          this.query = {};
          this.query.query = {};
          this.selectedpageno = 1;

          this.wrapperStateName = "little";
          this.partWrapperState = "initial";

          this.orderBys = [
               {label: "cele mai noi", value:"bynewest"},
               {label: "cele mai vechi", value:"byoldest"},
               {label: "ordine alfabetica - ascendent", value:"byalphorderasc"},
               {label: "ordine alfabetica - descendent", value:"byalphorderdesc"},
               {label: "cele mai ieftine", value:"bypriceasc"},
               {label: "cele mai scumpe", value:"bypricedesc"}
          ]

          this.selectedoderby = this.orderBys[0].value;
          this.defineorder(this.selectedoderby);

          this.elsperpages =[
               {label: "10 clienti/pagina", value:10},
               {label: "15 clienti/pagina", value:15},
               {label: "20 clienti/pagina", value:20},
          ]

          this.selectedelsperpage = this.elsperpages[0].value;
          this.definepagination(this.selectedpageno, this.selectedelsperpage);
          this.vehiclesloaded = true;
          this.loadvehiclesview =true;
          this.selectedvehicleview = false;

          this.partsService.getLastPartNo()
          .subscribe((data)=>{
               if(data.partno==null){
                    this.part.partno = 100001;
               }else{
                    this.part.partno = data.partno + 1;
               }
          })
     }

     ngOnInit() {
     }

     defineorder(orderby){
          if(orderby == "bynewest"){
               this.query.sort={updated_at: -1}
          }else if (orderby == "byoldest"){
               this.query.sort={updated_at: 1}
          }else if (orderby == "byalphorderasc"){
               this.query.sort={denumire: 1}
          }else if (orderby == "byalphorderdesc"){
               this.query.sort={denumire: -1}
          }else if (orderby == "bypriceasc"){
               this.query.sort={pret: 1}
          }else if (orderby == "bypricedesc"){
               this.query.sort={pret: -1}
          }
     }

     selectSearchAttrs(){
          this.defineorder(this.selectedoderby);
          this.getVehiclesByQuery(this.query);
     }

     showvehicles(e){
          if(e.toState=="full-list"){
               this.vehicleShowState = "show";
          }

          if(e.toState=="swipe-right"){

               if(this.toState=="toVehicle"){
                    this.loadvehiclesview = false;
                    this.selectedvehicleview = true;
                    this.currentVehicleState = "show";
                    this.wrapperStateName = "swipe-left";
               }else if(this.toState=="toList"){
                    this.loadvehiclesview = true;
                    this.selectedvehicleview = false;
                    this.wrapperStateName = "full-list";
               }
          }
     }

     loadvehicles(e){
          if(e.toState=="hide"){
               this.getVehiclesByQuery(this.query);
               this.vehicleShowState = "show";
          }
     }


     currentloadevt(e){
          console.log(e);
     }

     getAppElements(){

          this.elementsService.getAll()
          .subscribe(
               (appelements)=>{
                    this.vehcats=[];
                    this.partscats=[];
                    this.vehiclecats={};
                    this.appelements=appelements[0];
                    this.vehiclecats = this.appelements.vehicle_categories;
                    this.partscategories = this.appelements.parts_categories;
                    this.vehiclecats.map((category)=>{
                         this.vehcats.push(category.category);
                         if(this.selectedcategoryname == category.category){
                              this.selectCategory();
                              if(typeof category.subcategories!=="undefined" && category.subcategories.length>0){
                                   this.manufacturers=[];
                                   this.vehiclesubcats = category.subcategories;
                                   this.vehiclesubcats.map((manufacturer)=>{
                                        this.manufacturers.push(manufacturer.marca);
                                        if(this.selectedsubcategory == manufacturer.marca){
                                             this.selectSubCategory();
                                        }
                                   })
                                   this.hassubcategories = true;
                              }else{
                                   this.hassubcategories = false;
                              }
                         }
                    })
                    this.partscategories.map((category)=>{

                    })
               }
          )
     }

     definepagination(page, elsperpage){
          let skip = (page-1)*elsperpage;
          this.query.pagination={skip: skip, limit: elsperpage};
     }

     definequery(fieldname, thestring){
          this.vehicleShowState = "hide";
          if(thestring){
               if(thestring.length>2){
                    this.query.query[fieldname]={fieldname: fieldname, value:thestring};
               }else{
                    this.query.query[fieldname]={fieldname: fieldname, value:null};
               }
          }
     }

     getVehiclesByQuery(query){
          if(query.query.length==0){
               query.query=null;
          }
          this.vehiclesService.getByQuery(query)
          .subscribe(
               (data)=>{
                    this.vehicles = data.vehicles;

                    this.vehicles.map((vehicle)=>{
                         vehicle.prettydateadd = moment(vehicle.created_at).format('Do MMMM YYYY');
                         vehicle.prettydatemod = moment(vehicle.updated_at).format('Do MMMM YYYY');

                         vehicle.pictures.map((picture)=>{
                              if(picture.mainpicture){
                                   vehicle.mainpicturefullpath = this.sanitization.bypassSecurityTrustUrl(this.config.picUrl+picture.path+'/'+picture.filename);
                                   vehicle.hasmainpicture = true;
                                   vehicle.fullpathloaded = true;
                              }
                         })
                    })

                    if(this.vehicles.length>0){
                         this.vehiclesloaded = true;
                         this.wrapperStateName="full-list"
                    }else{
                         this.vehiclesloaded = false;
                         this.wrapperStateName= "little";
                         this.vehicleShowState="hide"
                    }
                    this.vehiclesno = data.vehiclesno;
                    let pagesno = Math.ceil(this.vehiclesno/this.selectedelsperpage);
                    this.calcpagination(pagesno);
               }
          )
     }

     selectCategory(){
          this.modelslist = false;
          this.query.query = {};
          this.categoryselected=true;
          this.vehiclecats.map((category)=>{
               if(this.selectedcategoryname==category.category){
                    this.selectedcategory = category;
                    this.definequery('categorie', this.selectedcategoryname);
                    if(typeof this.selectedcategory.subcategories !== "undefined" && this.selectedcategory.subcategories.length>0){
                         this.vehiclesubcats = this.selectedcategory.subcategories;
                         this.manufacturers = [];
                         this.vehiclesubcats.map((manufacturer)=>{
                              this.manufacturers.push(manufacturer.marca);
                         })
                         this.hassubcategories = true;

                    }else{
                         this.hassubcategories = false;
                         this.vehiclesubcats = [];
                    }
               }
          })
     }

     selectSubCategory(){
          this.models = [];
          this.subcategoryselected=true;
          this.query.query['model']={fieldname: 'model', value:''};


          this.vehiclesubcats.map((manufacturer)=>{
               if(this.selectedsubcategory==manufacturer.marca){
                    this.models = manufacturer.models;
                    this.definequery('marca', this.selectedsubcategory);
                    if(typeof this.models !== "undefined" && this.models.length>0){
                         this.hasmodels = true;
                         this.modelslist = true;
                    }else{
                         this.hasmodels = false;
                    }
               }
          })

     }

     selectModel(){
          this.definequery('model', this.selectedmodel);

     }

     searchforvehno(thevehno){
          var vehno = parseInt(thevehno);
          this.definequery('vehno', thevehno);
     }

     selectVehicle(vehicle){
          this.vehicle = vehicle;
          this.categories = [];
          this.vehicle.partscats.map((categoryname)=>{
               var newcat = {category: categoryname, selected:false};
               this.categories.push(newcat);
          })
          this.toState = "toVehicle";
          this.wrapperStateName = "swipe-right";
     }

     backtolist(){
          this.toState = "toList";
          this.currentVehicleState = "hide"
          this.wrapperStateName = "swipe-right";
     }

     partwrapperloaded(e){
          if(e.toState == "initial"){
               this.partWrapperState = "deployed";
          }else if(e.toState == "deployed"){
               this.partContainerState = "show";
          }

     }

     partcontainerloaded(e){
          if(e.toState == "hide"){
               this.part.categorie = this.newcat;

          }
     }

     selectpartcat(i){
          this.categories.map((category, index)=>{
               if(i == index){
                    category.selected = true;
                    this.categoryloaded = true;
                    this.partWrapperState = "initial";
                    this.partContainerState = "hide";
                    this.newcat =  category.category;
                    this.partscategories.map((partcat)=>{
                         if(partcat.category == this.newcat){
                              this.partsubcats = partcat.subcategories;
                              if(this.partsubcats.length>0){
                                   this.parthassubcategories=true;
                                   this.addsubcat = false;
                              }else{
                                   this.parthassubcategories=false;
                                   this.addsubcat = true;
                              }
                         }
                    })
               }else{
                    category.selected = false;
               }
          })
     }

     selectPartSubCategory(){
          if(this.selectedpartsubcategory !== "742433"){
               this.part.subcategorie = this.selectedpartsubcategory;
          }else{
               this.addsubcat = true;
          }
     }

     onSubmitPartSubCatForm({ value, valid }: { value: any, valid: boolean }){
          this.partsubcats.push(value.subcategorie);
          this.selectedpartsubcategory = value.subcategorie;
          this.addsubcat = false;
          this.parthassubcategories=true;
          this.partscategories.map((category)=>{
               if(this.part.categorie == category.category){
                    category.subcategories = this.partsubcats
               }
          });
          this.appelements.parts_categories = this.partscategories;
          this.elementsService.update(this.appelements)
          .subscribe((data)=>{
          })

     }

     createPart({ value, valid }: { value: any, valid: boolean }){
          this.partloaded = true;
          this.part.denumire = value.denumire;
          this.part.marca = this.vehicle.marca;
          this.part.model = this.vehicle.model;
          this.part.tip = this.vehicle.tip;
          this.part.vehcat = this.vehicle.categorie;
          this.part.cod = value.cod;
          this.part.descriere = value.descriere;
          this.part.fabricatie = this.vehicle.fabricatie;
          this.part.veh_id = this.vehicle._id;
          this.part.pret = value.pret;
          this.part.km = this.vehicle.km;
          this.part.valid = true;

          this.part.pathfolder = this.part.denumire.split(' ').join('_')+'_'+this.part.marca+'_'+this.part.model;

          this.partsService.create(this.part)
          .subscribe((data)=>{

               this.part = data;


               this.definequery('type', 'part');
               this.definequery('partcat', this.part.categorie);
               this.definequery('partsubcat', this.part.subcategorie);
               this.definequery('partname', this.part.denumire);

               this.nomenclatorService.ifExists(this.query)
               .subscribe((data)=>{
                    console.log(data);
                    if(!data.exists){
                         var nomenclatorItem = {} as NomenclatorItem;
                         nomenclatorItem.type = "part";
                         nomenclatorItem.partcat = this.part.categorie;
                         nomenclatorItem.partsubcat = this.part.subcategorie;
                         nomenclatorItem.partname = this.part.denumire;
                         this.nomenclatorService.create(nomenclatorItem)
                         .subscribe((response)=>{
                              console.log(response);
                         })
                    }
               })
          })
     }


     updatepart(part){
          this.partsService.update(this.part)
          .subscribe((data)=>{
               this.partsService.getById(this.part._id)
               .subscribe((part)=>{
                    this.part = part;
                    if(this.part.pictures.length>0){
                         this.picturesloaded = true;
                         this.part.pictures.map((picture)=>{
                              picture.fullpath = this.sanitization.bypassSecurityTrustStyle(`url('${this.config.picUrl+picture.path+'/'+picture.filename}')`);
                         })
                    }else{
                         this.picturesloaded = false;
                    }
               })
          })
     }

     selectmainpicture(i){

          this.part.pictures.map((picture, index)=>{
               if(index == i){
                    picture.mainpicture = true;
               }else{
                    picture.mainpicture = false;
               }
          })

          this.updatepart(this.part);
     }

     pictureFileListener(e){
          this.picturemeta = {} as PictureData;
          var file:File = e.target.files[0];
          var myReader = new FileReader();
          var image = new Image();

          myReader.onloadend = function (loadEvent:any) {
               image.src = loadEvent.target.result;
               image.onload = function() {
                    var width = image.width;
                    var height = image.height;
                    var maxwidth = 900;
                    var maxheight = 600;
                    var canvas = document.createElement("canvas");

                    if(width>height){
                         canvas.width = maxwidth;
                         canvas.height = maxwidth * height/width;
                    }else{
                         canvas.height = maxheight;
                         canvas.width = maxheight * width/height;
                    }
                    canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
                    this.newimage = canvas.toDataURL('image/jpeg');
                    this.imageloaded=true;

               }.bind(this);
          }.bind(this);
          myReader.readAsDataURL(file);
          this.picturemeta.filename=file.name;
          this.picturemeta.category="parts";
          this.picturemeta.ratio="1:1";
          this.picturemeta.parent = this.part.pathfolder;
          this.picturemeta.path = this.picturemeta.category + '/' + this.part.pathfolder;

     }


     pictureUpload(){
          this.pictureService.upload(this.newimage, this.picturemeta)
          .subscribe((data)=>{
               this.picturemeta.filename = data.filename;
               this.picturemeta.path = data.path;
               this.part.pictures.push(this.picturemeta);
               if(this.part.pictures.length==1){
                    this.part.pictures[0].mainpicture = true;
               }
               this.imageloaded = false;
               this.picturemeta = {} as PictureData;
               this.updatepart(this.part);

          })
     }

     calcpagination(pagesno){
          this.pagesnos = [];
          this.startpagenos = [];
          this.middlelowpagenos = [];
          this.middlehighpagenos = [];
          this.endpagenos = [];

          if(pagesno <= 10 ){
               this.less10pages = true;
               for(let i=0; i<pagesno; i++){
                    var pgno=i+1;
                    this.pagesnos.push(pgno);
               }
          }else{
               this.less10pages = false;
               var middle = Math.ceil(pagesno/2);
               if(pagesno <= 20){
                    this.less20pages = true;
                    for(let i=0; i<6; i++){
                         var startpgno = i+1;
                         this.startpagenos.push(startpgno);
                    }
                    for(let j=pagesno-6; j<pagesno; j++){
                         var endpageno = j+1;
                         this.endpagenos.push(endpageno);
                    }
               }else{
                    this.less20pages = false;
                    for(let i=0; i<5; i++){
                         var startpgno = i+1;
                         this.startpagenos.push(startpgno);
                    }
                    for(let j=pagesno-5; j<pagesno; j++){
                         var endpageno = j+1;
                         this.endpagenos.push(endpageno);
                    }
                    for(let t=middle-3; t<middle; t++){
                         var middlelowpageno = t+1;
                         this.middlelowpagenos.push(middlelowpageno);
                    }
                    for(let q=middle; q<middle+3; q++){
                         var middlehighpageno = q+1;
                         this.middlehighpagenos.push(middlehighpageno);
                    }

               }
          }
     }

     finishpart(){
          this.router.navigate(['admin']);
     }

}
