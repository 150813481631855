import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppConfig } from '../app.config';
import { PictureCollection, PictureGallery, PictureData} from '../_models/picturecollection';


@Injectable()
export class PictureService {
  picture: PictureData;


  constructor(private http: HttpClient, private config: AppConfig) { }


  upload(picturedata: string, picturemeta: PictureData){

    var filedata=this.decodeBase64Image(picturedata, picturemeta);
    console.log(filedata);
    return this.http.post<any>(this.config.uploadUrl + '/upload', filedata, this.jwt());
  }

  decodeBase64Image(dataString, pictureMeta){
    let date = Number(new Date());
    var matches = dataString.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/),
    response = {} as any;

    if (matches.length !== 3) {
      return new Error('Invalid input string');
    }else{
         console.log(pictureMeta)
      response.file = matches[2];
      response.path = pictureMeta.path;
      response.parent =pictureMeta.parent;
      response.category = pictureMeta.category;
      response.ext = pictureMeta.filename.substring(pictureMeta.filename.lastIndexOf(".")+1);
      response.filename = pictureMeta.parent;
      console.log(response)
      return response;
    }

  }


  private jwt(){
       let currentUser = JSON.parse(localStorage.getItem('currentUser'));
       if (currentUser && currentUser.token){
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
            .append('Access-Control-Allow-Origin', '*')
            .append('Authorization', 'Bearer '+ currentUser.token)
            .append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS');

            return { headers: headers };
       }
 }
}
