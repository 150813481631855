import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';

import { PasswordValidation } from 'app/_validators/password-validation';
import { SuperUser } from 'app/_models/superuser';

import { SuperUserService } from 'app/_services/superuser.service';

@Component({
  selector: 'app-new-admin',
  templateUrl: './new-admin.component.html',
  styleUrls: ['./new-admin.component.css']
})
export class NewAdminComponent implements OnInit {

  appuser: SuperUser;
  model: any={};
  loading: any;

  registerForm = new FormGroup({
    username: new FormControl(),
    password: new FormControl(),
    verpassword: new FormControl(),
    firstname: new FormControl(),
    lastname: new FormControl(),
  });

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private userService: SuperUserService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      username: ['',[Validators.required, Validators.minLength(5)]],
      password: ['',[Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      firstname: ['',[Validators.required, Validators.minLength(3)]],
      lastname: ['',[Validators.required, Validators.minLength(3)]],
      telefon: ['',[Validators.required, Validators.minLength(10),Validators.maxLength(10)]],
    },{
      validator: PasswordValidation.MatchPassword
    });
  }

  onSubmit({ value, valid }: { value: any, valid: boolean }) {

  this.appuser.username = value.username;
  this.appuser.password = value.password;
  this.appuser.firstName = value.firstname;
  this.appuser.lastName = value.lastname;
  this.appuser.role="superuser";

  this.userService.create(this.appuser)
      .subscribe(
          data => {
            this.router.navigate(['/super-admin/list']);
          },
          error => {
          });

  }

  gohome(){
    this.router.navigate(['/super-admin']);
  }

}
