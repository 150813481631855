import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import 'rxjs/add/observable/combineLatest';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { ObservableMedia, MediaChange } from '@angular/flex-layout';
import { trigger, keyframes, animate, state, style, transition } from '@angular/animations';


import * as kf from '../_keyframes/keyframes';


@Component({
     selector: 'app-mainview',
     templateUrl: './mainview.component.html',
     styleUrls: ['./mainview.component.css'],
     animations: [
          trigger('cardAnimator', [
               transition('* => wobble', animate(1000, keyframes(kf.wobble))),
               transition('* => swing', animate(1000, keyframes(kf.swing))),
               transition('* => jello', animate(1000, keyframes(kf.jello))),
               transition('* => zoomOutRight', animate(1000, keyframes(kf.zoomOutRight))),
               transition('* => slideOutLeft', animate(200, keyframes(kf.slideOutLeft))),
               transition('* => slideInLeft', animate(200, keyframes(kf.slideInLeft))),
               transition('* => rotateOutUpRight', animate(1000, keyframes(kf.rotateOutUpRight))),
               transition('* => flipOutY', animate(1000, keyframes(kf.flipOutY))),
          ]),
          trigger('viewportdarker', [
               state('hide',style({
                    opacity: 0
               })),
               state('show',style({
                    opacity: 1
               })),
               transition('hide => show', animate('200ms ease-in')),
               transition('show => hide', animate('200ms ease-in'))
          ])
     ]
})
export class MainviewComponent implements OnInit, OnDestroy {

     mediawatcher: Subscription;
     activeMedia:string;
     mediaports: {
          mobile: boolean;
          tablet: boolean;
          desktop: boolean;
     }

     animationState: string;
     darkState:string;

     routeSub: any;
     routestring: any;

     menutoggled: boolean;
     menuitemselected :string;

     mainmenuitems: {
          vehiclesselected: boolean,
          partsselected: boolean,
          homeselected: boolean,
          aboutselected: boolean,
          contactselected: boolean,
          searchselected: boolean,
     }

     startAnimation(state) {
          console.log(state)
          if (!this.animationState) {
               this.animationState = state;
               if(state == "slideInLeft"){
                    this.darkState="show";
               }else if(state == "slideOutLeft"){
                    this.darkState="hide";
               }
          }
     }

     resetAnimationState() {
          console.log(this.animationState);
          if(this.animationState == "slideOutLeft")
          {this.menutoggled = false;}
          this.animationState="";
     }




     constructor(
          private router: Router,
          private activatedRoute:ActivatedRoute,
          public media: ObservableMedia
     ) {

          this.darkState = "hide";

          this.mainmenuitems = {
               vehiclesselected: false,
               partsselected: false,
               homeselected: false,
               aboutselected: false,
               contactselected: false,
               searchselected: false
          }

          this.mediaports={
               desktop:false,
               tablet: false,
               mobile: false
          }

          this.initroute()



     }

     ngOnInit() {

          this.routestring = this.router.events.subscribe((event)=>{
               this.initroute()
          });

          this.mediawatcher = this.media.subscribe((change: MediaChange)=>{
               console.log(change);
               if(change.mqAlias == "lg" || change.mqAlias=="xl"){
                    this.assignviewport('desktop');
               }else if(change.mqAlias == "md" || change.mqAlias=="sm"){
                    this.assignviewport('tablet');
               }else if(change.mqAlias == "xs"){
                    this.assignviewport("mobile")
               }
          });
     }

     assignviewport(view: string){
          for(var viewport in this.mediaports){
               if(viewport == view){
                    this.mediaports[viewport] = true;
               }else{
                    this.mediaports[viewport] = false;
               }
          }

          console.log(this.mediaports);
     }


     checkifmenu(){
          if(this.menutoggled){
               this.startAnimation('slideOutLeft');
          }
     }

     initroute(){
          for (var menuitem in this.mainmenuitems){
               this.mainmenuitems[menuitem] = false
          }

          if(this.router.url.startsWith("/main/vehicles")){
               this.mainmenuitems.vehiclesselected = true
               this.menuitemselected = "Vehicule";
          } else if(this.router.url.startsWith("/main/parts")){
               this.menuitemselected = "Piese";
               this.mainmenuitems.partsselected = true
          } else if(this.router.url.startsWith("/main/about")){
               this.menuitemselected = "Despre noi";
               this.mainmenuitems.aboutselected = true
          } else if(this.router.url.startsWith("/main/contact")){
               this.menuitemselected = "Contact";
               this.mainmenuitems.contactselected = true
          } else if(this.router.url.startsWith("/main/home")){
               this.menuitemselected = "Acasa";
               this.mainmenuitems.homeselected = true;
          } else if(this.router.url.startsWith("/main/search")){
               this.menuitemselected = "Cautare";
               this.mainmenuitems.searchselected = true;
          }
     }

     ngOnDestroy(){
          this.routestring.unsubscribe();
     }




     showmenu(){
          this.menutoggled=true;
          this.startAnimation('slideInLeft');
     }



     selectMainMenuItem( item : string ){

          for(var key in this.mainmenuitems){
               if(key == item){
                    this.mainmenuitems[key] = true;
               }else{
                    this.mainmenuitems[key] = false;
               }
          }

     }


}
